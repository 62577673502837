import {Image} from 'react-bootstrap'
import { useState,useEffect } from "react"
import axios from "axios"
import { useParams,useSearchParams } from "react-router-dom";
import logoImage from '../assets/gptauth-logo.svg'
import googleIcon from "../assets/google-logo.svg";
import {Button} from "react-bootstrap";

export default function GptAuthUserLogin() {
  const { slug } = useParams();
  const [authUrl,setAuthUrl] = useState("")
  const [user,setUser] = useState({email:'',password:''})
  let [searchParams, setSearchParams] = useSearchParams();
  useEffect(()=>{
    localStorage.setItem('gptAuthID',slug)
    if(searchParams.get("myRedirect")!=null){
      localStorage.setItem('redirectTo',searchParams.get("myRedirect"))
    }else{
      let redirect_uri = searchParams.get('redirect_uri')
      redirect_uri += "?state="+searchParams.get('state')+"&code="+searchParams.get('client_id')
      localStorage.setItem('redirectTo',redirect_uri)
    }
    axios.get("/gptauth/get_login_url",{params:{id:slug}}).then((res)=>{
      setAuthUrl(res.data)
    }).catch((err)=>{
    console.log(err)
    })
  },[])
  return (
    <main className="d-flex vh-100 w-100 flex-column gap-3 align-items-start justify-content-start p-4 py-5 bg-lightGray">
      <Image priority className="gptauth-logo" src={logoImage}/>
      <div>
        <h4 className="mb-1 gptauth-title">Welcome to <b>GPTAuth</b></h4>
        <span>Login to continue</span>
      </div>
      <hr className="border border-dark my-1 w-25"/>

      <Button className="d-flex justify-content-center align-items-center border-0 rounded-0 gptauth-btn px-3 py-2 text-white" onClick={()=>window.location.href=authUrl}>
        <Image src={googleIcon} className="opengpt-small-logo me-2 p-1 bg-slate-50 rounded-full"/>
        
        Login with Google
      </Button>

    </main>
  )
}
