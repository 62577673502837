import { Stack,Accordion } from "react-bootstrap"
import {ReactComponent as BackIcon} from '../assets/back.svg'
import { useNavigate } from "react-router-dom"

function FAQ() {
    const navigate  = useNavigate();
    return (
        <div class="chat-background d-flex justify-content-center">
            <div className="chat-container">
                <Stack className=" mx-2 mx-md-5 px-md-5 py-5 vh-100" gap={4}>
                    <Stack style={{cursor:'pointer',opacity:'0.7'}} direction="horizontal" gap={2} onClick={()=>navigate('/login')}>
                        <BackIcon className="icon"/><span>Back to Login</span>
                    </Stack>
                    <h4 className="text-center">Frequently Asked Questions</h4>
                    <Accordion >
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>How to login to SamurAI?</Accordion.Header>
                            <Accordion.Body>
                                Login via Discord login to sign-up for the service.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>How to create a Discord account?</Accordion.Header>
                            <Accordion.Body>
                                Check the help document <br/> 
                                <a target="_blank" href="https://support.discord.com/hc/en-us/articles/360033931551-Getting-Started">https://support.discord.com/hc/en-us/articles/360033931551-Getting-Started</a>

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>How many servers can I connect?</Accordion.Header>
                            <Accordion.Body>
                                Users can create/connect only one server(community) on thesamur.ai as of now.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Where do I find my OpenAI Key?</Accordion.Header>
                            <Accordion.Body>
                                Login to OpenAI and visit the url to access your key <br/>
                                <a target="_blank" href="https://platform.openai.com/account/api-keys ">https://platform.openai.com/account/api-keys </a>

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>What features are available on SamurAI?</Accordion.Header>
                            <Accordion.Body>
                            SamurAI can help:
                            <ul>
                                    <li>Create a Discord server</li>
                                    <li>Create a Discord channel</li>
                                    <li>Access the invite link </li>
                                    <li>Setup a welcome message on Discord</li>
                                    <li>Check server stats on Discord</li>
                                    <li>Answer questions on community building</li>
                                    <li>Summarise a Knowledge Base</li>
                            </ul>

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>How to ask any questions to SamurAI bot?</Accordion.Header>
                            <Accordion.Body>
                            First connect SamurAI bot to your community. Then add the openAI key here and setup a knowledgbase either by pasting your content or by sharing a url where your content is available. Once these steps are performed you can ask SamurAI bot questions in discord by mentioning it with @SamurAI . Make sure you are mentioning the bot and not the role.

                            </Accordion.Body>
                        </Accordion.Item>						
                    </Accordion>
                    
                </Stack>
            </div>
        </div>
    )
}

export default FAQ