import {Image,Stack,Button,FormControl} from 'react-bootstrap'
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import { ReactComponent as DiscordIcon } from "../assets/discord.svg";
import logoGraphic from '../assets/logo-graphic.png'
import { ReactComponent as QuestionIcon } from "../assets/question-mark.svg";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AppsumoSignup({authUrl}) {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [appsumocode, setAppsumoCode] = useState("")	
    const [name, setName] = useState("")
    const navigate  = useNavigate();
    const login = () => {
        axios.post("/appsumo_signup",{name:name,email:email,password:password,appsumocode:appsumocode}).then((res)=>{
			toast(res);
			setTimeout(function () { window.location.href = "/chat";}, 2000);
        }).catch((err)=>{
			toast(err.response.data);
            if(err.response.status == 302) {
                setTimeout(function () { window.location.href = "/chat";}, 2000);				
			}
        })
    }
    return (
        <div class="chat-background d-flex justify-content-center">
            <div className="chat-container">
                <Stack className=" mx-2 mx-md-5 px-md-5   vh-100 justify-content-center pb-5 text-center" gap={4}>
                    <Image style={{paddingTop:'15rem'}} className="logo-graphic align-self-center" src={logoGraphic} />
                    <FormControl className="login-input px-md-5 py-md-3 shadow-none" placeholder="Name" onChange={(e)=>setName(e.target.value)}/>
                    <FormControl className="login-input px-md-5 py-md-3 shadow-none" type="email" placeholder="Email" onChange={(e)=>setEmail(e.target.value)}/>
                    <FormControl className="login-input px-md-5 py-md-3 shadow-none" type="password" placeholder="Password" onChange={(e)=>setPassword(e.target.value)}/>
                    <FormControl className="login-input px-md-5 py-md-3 shadow-none" type="text" placeholder="Appsumo Code" onChange={(e)=>setAppsumoCode(e.target.value)}/>					
                    <Button className="option-buttons align-self-center" onClick={login}>{"Signup"}</Button>
                </Stack>
            </div>
			<ToastContainer />
        </div>

    )
}

export default AppsumoSignup