import React from "react";
import "../../../styles/summary.css";
import "../../../styles/home.css";
import { ReactComponent as DiamondImage } from "../../../assets/diamond.svg";
import { Dropdown, Stack, Row } from "react-bootstrap";
import { ReactComponent as DashboardIcon } from "../../../assets/dashboard.svg";
import { ReactComponent as MembersIcon } from "../../../assets/members.svg";

export default function Engagement() {
  return (
    <div className="d-flex justify-content-center">
      <Stack className="content-container mx-4 mx-lg-0" gap={4}>
        <Row className="g-0">
          <Stack direction="horizontal" gap={4}>
            <h4 className="m-0">Engagement</h4>

            <Dropdown className="ms-auto">
              <Dropdown.Toggle className="top-filters-cards" id="dropdown-basic">
                <DashboardIcon className="me-3" />
                Discord
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">
                  <DashboardIcon className="me-3" />
                  Action
                </Dropdown.Item>
                <Dropdown.Item href="#/action-2">
                  <DashboardIcon className="me-3" />
                  Another action
                </Dropdown.Item>
                <Dropdown.Item href="#/action-3">
                  <DashboardIcon className="me-3" />
                  Something else
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown>
              <Dropdown.Toggle className="top-filters-cards" id="dropdown-basic">
                Last 7 days
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <div
              className="top-filters-cards d-flex align-items-center justify-content-center"
              style={{ width: "45px" }}
            >
              <DiamondImage />
            </div>
          </Stack>
        </Row>
        <div className="p-4 p-lg-5 rounded-cards">
            <div className="summary-cards-big d-flex">
              <span className="graph-title m-0">Number of members who posted messages</span>
            </div>
        </div>
        <div className="p-4 p-lg-5 rounded-cards">
            <div className="summary-cards-big d-flex">
              <span className="graph-title m-0">Number of members who posted reactions</span>
            </div>
        </div>
        <div className="p-4 p-lg-5 rounded-cards">
            <div className="summary-cards-big d-flex">
              <span className="graph-title m-0">Number of messages posted</span>
            </div>
        </div>
        <div className="p-4 p-lg-5 rounded-cards">
            <div className="summary-cards-big d-flex">
              <span className="graph-title m-0">Number of reactions posted</span>
            </div>
        </div>
      
      </Stack>
    </div>
  );
}
