import React from "react";
import {
  Stack,
  Col,
  Form,
  OverlayTrigger,
  ListGroup,
  Dropdown,
} from "react-bootstrap";
import { useState, useEffect } from "react";
import { addFilter } from "../../../redux/slice/MembersSlice";
import { useSelector, useDispatch } from "react-redux";

export default function Filters({ filter, setDeletedFilter }) {
  const [getFilterConditions, setFilterConditions] = useState({
    Name: ["contains"],
    Email: ["contains"],
    Tag: ["is"],

    Level: ["is", "is not"],
    "Joined Date": ["in"],
    "Active": ["in"],
    "Inactive": ["in"],
    Platform: ["is"],
  });

  const fieldType = {
    Name: "text",
    Email: "text",
    Tag: "select",
    "Joined Date": "list",
    Platform: "select",
    Active: "list",
    Inactive: "list",
  };
  const dropdownOptions = { Platform: ["Slack", "Discord"], Tag: ["Admin"],"Joined Date":["Today","Last 7 Days","Last 15 Days","Last 30 Days","Last 60 Days","Last 90 Days"],"Active":["Today","Last 7 Days","Last 15 Days","Last 30 Days","Last 60 Days","Last 90 Days"],"Inactive":["Today","Last 7 Days","Last 15 Days","Last 30 Days","Last 60 Days","Last 90 Days"] };

  const dispatch = useDispatch();
  const addedFilters = useSelector((state) => state.membersReducer.filters);

  const setFilterConditionName = (e) => {
    document.getElementById(filter.id + "-condition").innerHTML = e.target.text;
    const filterData = [...addedFilters];
    const index = filterData.findIndex((f) => f.id === filter.id);
    const array_data = {
      id: filterData[index].id,
      name: filterData[index].name,
      condition: e.target.text,
      value: filterData[index].value,
    };
    filterData[index] = array_data;
    dispatch(addFilter(filterData));
  };

  const setFilterConditionValue = (e,fieldType) => {
    console.log(e)
    var value = ""
    switch(fieldType){
      case "text":
        value = e.target.value;
        break;
      case "select":
        if(e.target.checked){
          value = e.target.labels[0].innerHTML;
        }else{
          value=""
        }
        break;
      case "list":
        value = e.target.textContent;
        break;
      default: 
        value= ""
    }

    if(value===""){
    document.getElementById(filter.id + "-value").innerHTML = "select value";
    }else{
      document.getElementById(filter.id + "-value").innerHTML = value;

    }
    const filterData = [...addedFilters];
    const index = filterData.findIndex((f) => f.id === filter.id);
    const array_data = {
      id: filterData[index].id,
      name: filterData[index].name,
      condition: filterData[index].condition,
      value:value,
    };
    filterData[index] = array_data;
    dispatch(addFilter(filterData));
  };

  const removeFilter = () => {
    // const newFilterData = addedFilters.filter((f)=>f.id!==filter.id)
    // setFilters(newFilterData)
    // dispatch(addedFilters(newFilterData))
    // console.log(addedFilters)
    setDeletedFilter(filter);
  };

  const renderDropdownItems = () => {
    switch (fieldType[filter.name]) {
      case "text":
        return (
          <div className="mx-2" style={{ width: "150px" }}>
            {" "}
            <Form.Control
              id={filter.id + `-textInput`}
              style={{ fontSize: "12px" }}
              onChange={(e) => {
                setFilterConditionValue(e,fieldType[filter.name]);
              }}
            />
          </div>
        );
      case "select":
        return (
          <div>
            <ListGroup as="ol" style={{ fontSize: "12px" }}>
            {dropdownOptions[filter.name]!=null && dropdownOptions[filter.name].map((item,index)=>(
              <ListGroup.Item className="d-flex justify-content-start border-0">
              <div>
              <Form.Check 
            type="checkbox"
            id={`${filter.id}-checkbox-${index}`}
            key={`${filter.id}-checkbox-${index}`}
            label={item}
            onClick={(e)=>setFilterConditionValue(e,fieldType[filter.name])}
          />
              </div>
            </ListGroup.Item>
            ))}
            </ListGroup>
          </div>
        );
        case "list":
        return (
          <div>
            <ListGroup as="ol" style={{ fontSize: "12px" }}>
            {dropdownOptions[filter.name]!=null && dropdownOptions[filter.name].map((item,index)=>(
              <ListGroup.Item className="d-flex justify-content-start border-0" as="button" id={`${filter.id}-checkbox-${index}`}
              key={`${filter.id}-checkbox-${index}`} onClick={(e)=> setFilterConditionValue(e,fieldType[filter.name])}>
              {item}
            </ListGroup.Item>
            ))}
            </ListGroup>
          </div>
        );
      default:
        return "";
    }
  };

  return (
    <Col className="mb-3">
      <div className="options-bg">
        <Stack direction="horizontal" gap={2} className="ms-1">
          <div
            style={{
              paddingLeft: "6px",
              paddingTop: "6px",
              paddingBottom: "6px",
            }}
          >
            {filter.name}
          </div>
          <div className="vr"></div>
          <Dropdown className="filter-drop" id={filter.id}>
            <Dropdown.Toggle id={filter.id + `-condition`}>
              {getFilterConditions[filter.name][0]}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {getFilterConditions[filter.name].map((condition) => (
                <Dropdown.Item
                  onClick={(e) => {
                    setFilterConditionName(e);
                  }}
                >
                  {" "}
                  {condition}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <div className="vr"></div>
          <Dropdown className="filter-drop">
            <Dropdown.Toggle id={filter.id + `-value`}>
              select value
            </Dropdown.Toggle>

            <Dropdown.Menu>{renderDropdownItems()}</Dropdown.Menu>
          </Dropdown>
          <div className="vr" />
          <div
            style={{
              paddingRight: "10px",
              paddingTop: "8px",
              paddingBottom: "6px",
              fontSize: "10px",
              fontWeight: "800",
              cursor: "pointer",
            }}
            onClick={(e) => removeFilter()}
          >
            X
          </div>
        </Stack>
      </div>
    </Col>
  );
}
