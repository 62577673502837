import {Image,Button,FormControl,Row,Col,Tab,Tabs,Nav,Table} from 'react-bootstrap'
import { useState,useEffect } from "react"
import axios from "axios"
import logoImage from '../assets/gptauth-logo.svg'
import copyImage from '../assets/copy-white.svg'
import {ReactComponent as GoogleIcon} from '../assets/google.svg'

function GptAuthDashboard() {
    const [created,setCreated] = useState(true)
    const [key, setKey] = useState('track');
    const [authKey, setAuthKey] = useState("");
    const [users, setUsers] = useState([]);
    const [credentials,setCredentials] = useState({adminId:'',id:'',secret:''})
    const [fullUrl, setFullUrl] = useState('');
    const [URLs,setURLs] = useState({auth:'',token:''})
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const [authUrl, setAuthUrl] = useState(true);
    const instructions = `Start with authenticating the user as the first step
The authentication flow begins with the system prompting the user for their email address.
After the email is submitted, the system makes an API call to 'GetCode', sending a unique verification code to the user's email.
Then the system follow the instruction provided by the API Responses.`

/*
    const instructions = `Start with authenticating the user as the first step
The authentication flow begins with the system prompting the user for their email address. 
After the email is submitted, the system makes an API call to 'GetCode', sending a unique verification code to the user's email. 
Then the system follow the instruction provided by the API Responses.
Once verification is complete, whenever user makes a query send the query and user email to trackStats and follow instructions from the api response. Never answer the query directly always invoke the api` 

    const spec = 
    {
        "openapi": "3.1.0",
        "info": {
          "title": "User Authentication and tracking",
          "version": "1.0.0",
          "description": "User Authentication and tracking"
        },
        "servers": [
          {
            "url": "https://app.gpt-auth.com"
          }
        ],
        "paths": {
          "/gptauth/get_code": {
            "post": {
              "description": "Generate Code",
              "operationId": "GetCode",
              "parameters": [],
              "requestBody": {
                "description": "User Email",
                "required": true,
                "content": {
                  "application/json": {
                    "schema": {
                      "type": "object",
                      "properties": {
                        "email": {
                          "type": "string"
                        }
                      },
                      "required": ["email"]
                    }
                  }
                }
              },
              "deprecated": false,
              "security": [
                {
                  "BearerAuth": []
                }
              ]
            }
          },
          "/gptauth/verify_code": {
            "post": {
              "description": "Verify Auth Code",
              "operationId": "VerifyCode",
              "parameters": [],
              "requestBody": {
                "description": "User Email and Code",
                "required": true,
                "content": {
                  "application/json": {
                    "schema": {
                      "type": "object",
                      "properties": {
                        "email": {
                          "type": "string"
                        },
                        "code": {
                          "type": "string"
                        }
                      },
                      "required": ["email", "code"]
                    }
                  }
                }
              },
              "deprecated": false,
              "security": [
                {
                  "BearerAuth": []
                }
              ]
            }
          },
          "/gptauth/track_stats": {
            "post": {
              "description": "Track user stats",
              "operationId": "trackStats",
              "parameters": [],
              "requestBody": {
                "description": "User Email and Query",
                "required": true,
                "content": {
                  "application/json": {
                    "schema": {
                      "type": "object",
                      "properties": {
                        "query": {
                          "type": "string"
                        },
                        "email": {
                          "type": "string"
                        },
                      },
                      "required": ["query","email"]
                    }
                  }
                }
              },
            }
          },
        },
        "components": {
          "schemas": {
            "Error": {
              "type": "object",
              "properties": {
                "code": {
                  "type": "integer",
                  "format": "int32"
                },
                "message": {
                  "type": "string"
                }
              }
            }
          },
          "securitySchemes": {
            "basicAuth": {
              "type": "http",
              "scheme": "basic"
            }
          }
        },
        "security": [
          {
            "basicAuth": []
          }
        ]
      }
    */

  const spec = {
    "openapi": "3.1.0",
    "info": {
        "title": "User Authentication and tracking",
        "version": "1.0.0",
        "description": "User Authentication and tracking"
    },
    "servers": [
        {
            "url": "https://app.gpt-auth.com"
        }
    ],
    "paths": {
        "/gptauth/get_code": {
            "post": {
                "description": "Generate Code",
                "operationId": "GetCode",
                "parameters": [],
                "requestBody": {
                    "description": "User Email",
                    "required": true,
                    "content": {
                        "application/json": {
                            "schema": {
                                "type": "object",
                                "properties": {
                                    "email": {
                                        "type": "string"
                                    }
                                },
                                "required": ["email"]
                            }
                        }
                    }
                },
                "deprecated": false,
                "security": [
                    {
                        "BearerAuth": []
                    }
                ]
            }
        },
        "/gptauth/verify_code": {
            "post": {
                "description": "Verify Auth Code",
                "operationId": "VerifyCode",
                "parameters": [],
                "requestBody": {
                    "description": "User Email and Code",
                    "required": true,
                    "content": {
                        "application/json": {
                            "schema": {
                                "type": "object",
                                "properties": {
                                    "email": {
                                        "type": "string"
                                    },
                                    "code": {
                                        "type": "string"
                                    }
                                },
                                "required": ["email", "code"]
                            }
                        }
                    }
                },
                "deprecated": false,
                "security": [
                    {
                        "BearerAuth": []
                    }
                ]
            }
        }
    },
    "components": {
        "schemas": {
            "Error": {
                "type": "object",
                "properties": {
                    "code": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "message": {
                        "type": "string"
                    }
                }
            }
        },
        "securitySchemes": {
            "basicAuth": {
                "type": "http",
                "scheme": "basic"
            }
        }
    },
    "security": [
        {
            "basicAuth": []
        }
    ]
    }
    
    
    const createCredentials = () => {
       
        axios.post("/gptauth/get_credentials",{id:0}).then((res)=>{
        setCredentials({adminId:res.data.adminId,id:res.data.id,secret:res.data.secret})
        setCreated(true)
        localStorage.setItem('gptAuthID',res.data.adminId)
        }).catch((err)=>{
            console.log(err)
            if (err.response && err.response.status === 302) {
                window.location.href = err.response.data;
            }
        })
    }
    const copyCredential = (cred) => {
        navigator.clipboard.writeText(cred)
    }

    useEffect(() => {
        // This code runs on the client-side
        const url = window.location.protocol + "//" + window.location.host + "/login";
        setFullUrl(url);
    }, []);


    useEffect(() => {
        axios.get("/gptauth/isLoggedIn").then((res)=>{
            setIsLoggedIn(res.data.isLoggedIn)
            if(res.data.isLoggedIn == false){
                setAuthUrl(res.data.auth_url);
            }else{
                setIsLoggedIn(true);
                axios.get("/gptauth/get_users").then((res)=>{
                    setUsers(res.data.users)
                    setAuthKey(res.data.authKey)
                }).catch((err)=>{
                    console.log(err)
                })
            }
          }).catch((err)=>{
            console.log(err)
        })
      }, [])

    const handleButtonClick = () => {
        // Ensure this function also safely accesses `window`
        if (typeof window !== 'undefined') {
            copyCredential(window.location.host + "/login");
        }
    };

    useEffect(()=>{
        if(credentials.adminId!=""){
            setURLs({auth:window.location.protocol + "//" + window.location.host + "/gptauth/oauth/"+credentials.adminId,token:window.location.protocol + "//" + window.location.host + "/gptauth/verify_token/"+credentials.adminId})
        }
    },[credentials])

    return (
        <main style={{minHeight:'100vh'}} className="d-flex h-100 w-full flex-column gap-3 align-items-start justify-content-start p-4 p-md-5 bg-lightGray">
            <Image priority className="gptauth-logo" src={logoImage}/>
            <div>
                <h4 className="mb-1 gptauth-title">Welcome to <b>GPTAuth</b></h4>
              
                <span>Add authentication to your GPT</span>
            </div>
         
            <hr className="border border-dark my-1 w-25"/>
            {isLoggedIn ? (
            <>
            {created==false?
                <>
               
                    <Button className="d-flex items-center justify-center gptauth-btn border-0 rounded-0 px-3 py-2 text-white gap-2" onClick={createCredentials}>
                        <b>+</b>
                        Create credentials
                    </Button>
                </>
            :
              <Tab.Container defaultActiveKey="track">
                <Row className="w-100">
                  <Col sm={2}>
                    <Nav variant="pills" className="flex-column mb-3">
                      <Nav.Item>
                        <Nav.Link className="gptauth-tab" eventKey="track">Instructions</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link className="gptauth-tab" eventKey="users">Users</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={10}>
                    <Tab.Content>
                      <Tab.Pane eventKey="track">

                        <p>To keep track your users and their queries, follow these steps:</p>
                        <p>1. Add this at the end of your instructions, you can modify behavior after API response as needed</p>
                        <div className="position-relative">
                            <pre className="text-wrap pe-5">{instructions}</pre>
                            <Button  onClick={()=>copyCredential(instructions)} className="d-flex position-absolute end-0 bottom-0 mb-3 me-3 justify-content-center align-items-center border-0 rounded-0 gptauth-btn px-3 py-2 text-white">
                                    <Image className="gptauth-small-logo" src={copyImage} />
                            </Button>
                        </div>
                        <p>2. Add this schema to a new action by clicking "Create new action"</p>
                        <div className="position-relative">
                            <pre className=" spec-container pe-5"><code>{JSON.stringify(spec, null, 4)}</code></pre>
                            <Button  onClick={()=>copyCredential(JSON.stringify(spec))} className="d-flex position-absolute end-0 bottom-0 mb-3 me-3 justify-content-center align-items-center border-0 rounded-0 gptauth-btn px-3 py-2 text-white">
                                    <Image className="gptauth-small-logo" src={copyImage} />
                            </Button>
                        </div>
                        <p>3. Select "Authentication" in the action and choose API Key, and enter this key to make sure your unique account is linked</p>
                        <div className="position-relative">
                            <pre className="pe-5">{authKey}</pre>
                            <Button  onClick={()=>copyCredential(authKey)} className="d-flex position-absolute end-0 bottom-0 me-3 my-2 justify-content-center align-items-center border-0 rounded-0 gptauth-btn px-3 py-2 text-white">
                                    <Image className="gptauth-small-logo" src={copyImage} />
                            </Button>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="users">
                      {users.length>0?<Table hover>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Email</th>
                          </tr>
                        </thead>
                        <tbody>
                          {users.map((user,index)=><tr>
                            <td>{index+1}</td>
                            <td className="d-flex justify-content-between">
                              {user}
                              <Button  onClick={()=>copyCredential(user)} className="d-flex  justify-content-center align-items-center border-0 rounded-0 gptauth-btn px-3 py-2 text-white">
                                <Image className="gptauth-small-logo" src={copyImage} />
                              </Button>
                            </td>
                          </tr>)}
                          
                        </tbody>
                      </Table>:<h6>Your signed up users will appear here</h6>}
                      </Tab.Pane>
                    </Tab.Content>
                       
                  </Col>
                </Row>
              </Tab.Container>
            }
    </>
    ) : (
        <>
        <h5 className="text-center">Login to GPT-Auth</h5>
        <Button className="option-buttons align-self-center" href={authUrl}><GoogleIcon className="icon me-2 pb-1"/> Login with Google</Button>
        </>
    )}
        </main>
    );
}

export default GptAuthDashboard;
