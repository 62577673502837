import {useState,useEffect,useRef} from 'react'
import {Stack, Button,InputGroup, FormControl,Modal, Image, Dropdown, Spinner,Row, Col, Offcanvas,Form, Accordion, ButtonGroup,Tab,Tabs} from 'react-bootstrap'
import '../styles/agi.css'
import '../styles/clone.css'
import { useNavigate } from "react-router-dom";
import {ReactComponent as SearchIcon} from '../assets/search.svg'
import {ReactComponent as GoogleIcon} from '../assets/google.svg'
import {ReactComponent as CopyIcon} from '../assets/copy.svg'
import {ReactComponent as SendIcon} from '../assets/send.svg'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logoImage from '../assets/autogpt.png'
import userImage from '../assets/user.png'
import { ReactComponent as QuestionIcon } from "../assets/question-mark.svg";
import { ReactComponent as ShareIcon } from "../assets/share.svg";
import { ReactComponent as DiscordIcon } from "../assets/discord.svg";
import { ReactComponent as TwitterIcon } from "../assets/twitter.svg";
import { ReactComponent as ReloadIcon } from "../assets/reload.svg";

import { ReactComponent as StarIcon } from "../assets/star.svg";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import heybotLogo from '../assets/heybot.png'
import { useSearchParams, useParams } from "react-router-dom";
import axios from "axios"

import { AutoGPT } from "langchain/experimental/autogpt";
import { ReadFileTool, WriteFileTool, SerpAPI, Tool } from "langchain/tools";
import { InMemoryFileStore } from "langchain/stores/file/in_memory";
import { MemoryVectorStore } from "langchain/vectorstores/memory";
import { OpenAIEmbeddings } from "langchain/embeddings/openai";
import { ChatOpenAI } from "langchain/chat_models/openai";
import { WebBrowser } from "langchain/tools/webbrowser";
import { OpenAI } from "langchain/llms/openai";

function AGI() {
    document.title = "AutoGPT"
    const [isLoggedIn,setIsLoggedIn] = useState(null)
    const [authUrl,setAuthUrl] = useState("")
    const [keyAdded,setKeyAdded] = useState(null)
    const [user,setUser] = useState({id:0,image:""})
    const [question,setQuestion] = useState("")
    const [chat,setChat] = useState([])
    const [key,setKey] = useState("")
    const [name,setName] = useState("")
    const [role,setRole] = useState("")
    const [currentOutput,setCurrentOutput] = useState("")
    const [outputs,setOutputs] = useState([])

    const [goals,setGoals] = useState([""])
    const [update,setUpdate] = useState(false)
    const [loading,setLoading] = useState(false)
    const [currentTask,setCurrentTask] = useState("")
    const [nextTasks,setNextTasks] = useState([])
    const [thoughts,setThoughts] = useState({})
    const [command,setCommand] = useState({name:"",args:""})
    const [taskResults,setTaskResults] = useState(null)
    const [iterations,setIterations] = useState(10)
    const [started,setStarted] = useState(false)
    const [stopped,setStopped] = useState(false)
    const [sessions,setSessions] = useState([])  
    const [autoGPT,setAutoGPT] = useState(null)

    const [showMenu, setShowMenu] = useState(false);
    const [showCopy, setShowCopy] = useState(0);
    const [streamAns,setStreamAns] = useState("")
    const [sessId,setSessId] = useState(0)
    const [showBanner, setShowBanner] = useState(false);
    const [showOutputModal, setShowOutputModal] = useState(false);
    const navigate = useNavigate()
    const chatRef = useRef(null)
    chatRef.current = chat
    const sessionRef = useRef(null)
    sessionRef.current = sessId
    const startRef = useRef(null)
    startRef.current = started
    const stopRef = useRef(null)
    stopRef.current = stopped
    const loadRef = useRef(null)
    loadRef.current = loading
    const streamRef = useRef(null)
    streamRef.current = streamAns
    let [searchParams, setSearchParams] = useSearchParams();
    let fetchInterval
    const store = new InMemoryFileStore()


    const addKey = () => {
        axios.post("/store_key",{key:key}).then((res)=>{
            setKeyAdded(true)
          }).catch((err)=>{
            toast("Key cannot be verified, try again");
        })
    }
    const changeModel = (model) => {
        axios.post("/mygpt/change_model",{model:model}).then((res)=>{
          }).catch((err)=>{
            toast("Model not updated, try again");
        })
    }

    let getDuckAnswer = (getInput) => {
        return new Promise((resolve, reject) => {
            axios.post('/agi/search_duckduckgo', {
                   search: getInput,
                }, )
              .then(response => {
                  setCurrentOutput(response.data)
                  resolve(response.data)
                })
                .catch(error => {
                    console.error(error);
                    reject(error)
                });
        });     
    }
    class SearchTool extends Tool {
      constructor() {
        super();
        this.name = "SearchTool";
        this.description = "Useful for when you need to answer questions about current events. You should ask targeted questions";
      }

       async _call(input: string) {
            let data = await getDuckAnswer(input)
            return data;
      }
    }      

    let getScrapeAnswer = (url,question) => {
        return new Promise((resolve, reject) => {
            axios.post('/agi/scrape_site', {
                    url: url,
                    question:question
                 })
                 .then(function (response) {
                    setCurrentOutput(response.data)
                    resolve(response.data)	
                 })
                 .catch(function (error) {
                    reject(error)
                 });
        });     
    }
    class WebBrowserTool extends Tool {
        constructor() {
          super();
          this.name = "WebBrowserTool";
          this.description = 'useful for when you need to find something on or summarize a webpage. input should be a comma seperated list of "valid URL including protocol","what you want to find on the page in a question format"';
        }
  
        async _call(input: string,) {
            let getInput = input.split(",")
            let url = getInput[0]
            let question = getInput[1]
            let data = await getScrapeAnswer(url,question)
            return data;
          }
      }

    
    
    let startAGI = async() => {
        setStarted((prev)=>true)
        setLoading((prev)=>true)
        if(currentOutput!=""){
            setOutputs([...outputs,currentOutput])
        }
        setCurrentOutput("")
        await autoGPT.run(goals).then((output)=>{
        setLoading((prev)=>false)
        if(output!=undefined){
          setStopped((prev)=>true)
        }
        })
       
    }
    let deleteChat = (sessionId) => {
        axios.post("/agi/delete_chat",{sessId:sessionId}).then((res)=>{
            if(sessionRef.current==sessionId){
                setChat([])
                setSessId(0)
            }
            const result = sessions.filter(session => session.id !== sessionId)
            setSessions(result)
           
        }).catch((err)=>{
            toast("Could not delete chat, try again");
        })
    }

    let searchChat = (search) => {
        axios.post("/agi/search_chat",{search:search}).then((res)=>{
            setSessions(res.data)
           
        }).catch((err)=>{
            toast("Could not find chat, try again");
        })
    }

    const logout = () => {
        axios.get("/heybot/logout").then((res)=>{
            window.location.reload();
           }).catch((err)=>{
            console.log(err)
           })
    }

    let downloadTxtFile = (name,content) => {
        const element = document.createElement("a");
        const file = new Blob([content], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = name;
        element.target = "_blank";
        document.body.appendChild(element); 
        element.click();
      }

    useEffect(() => {
        
        axios.get("/agi/isLoggedIn").then((res)=>{
            setIsLoggedIn(res.data.isLoggedIn)
            if(res.data.isLoggedIn == false){
                setAuthUrl(res.data.auth_url)
            }else{
                setUser({id:res.data.userId,image:res.data.image})
                if(res.data.key_added==null){
                    setKeyAdded((prev)=>false)
                }else{
                    setKeyAdded((prev)=>res.data.key_added)
                    setKey(res.data.key_added)
                    let model = new OpenAI({ openAIApiKey:res.data.key_added, temperature: 0 })
                    let embeddings = new OpenAIEmbeddings({openAIApiKey:res.data.key_added})
                    let vectorStore = new MemoryVectorStore(embeddings);
                    const tools = [
                        new ReadFileTool({ store }),
                        new WriteFileTool({ store }),
                        new SearchTool(),
                        new WebBrowserTool(),
                        ];

                    let getAutogpt = AutoGPT.fromLLMAndTools(
                        new ChatOpenAI({openAIApiKey:res.data.key_added, streaming: true, temperature: 0,callbacks: [
                            {
                            // handleLLMNewToken(token: string) {
                            //     // console.log(token)
                            //     setStreamAns(streamRef.current.concat(token))
                            // },
                            handleLLMEnd(output: string) {
                                let getOutput = JSON.parse(output.generations[0][0].text)
                                setThoughts(getOutput.thoughts)
                                // setStreamAns((prev)=>"")
                                setCommand({name:getOutput.command.name,args:getOutput.command.args})
                                let getFiles = []
                                if(store.files.size!=0){
                                    store.files.forEach((value,key) => {
                                    getFiles.push({name:key,content:value})
                                    })
                                    setTaskResults(getFiles)
                                }

                            },
                        },
                    ], }),
                        tools,
                        {
                            memory: vectorStore.asRetriever(),
                            aiName: "AutoGPT",
                            aiRole: "Assistant",
                            // maxIterations:10
                        }
                    )
                    setAutoGPT(getAutogpt)
                }
                setSessions(res.data.sessions)
               
            }
          }).catch((err)=>{
            console.log(err)
        })
        //window.setTimeout(()=>setShowBanner(true),30000)
       
        // promptSelect()
      }, [])

    useEffect(() => {
        if(document.querySelector('.end-chat')!=null){document.querySelector('.end-chat').scrollIntoView({ behavior: 'smooth', block: 'end' });}
    }, [chat])
    
    // const shareChat = () => {
    //     navigator.clipboard.writeText(window.location.host+'/agi/share?session='+sessionRef.current)
    // }

    return (
        < >
            <Row className="vh-100 overflow-hidden  g-0">
                <Col className=" agi-bg" xl={startRef.current?8:12} style={{overflowY:"scroll"}}>
                    <div className="d-flex d-xl-none  px-4 py-3 mb-3">
                        <h4 className="fw-bold">
                            <Image className="mygpt-logo me-2" src={logoImage}/>
                            AutoGPT
                        </h4>
                        <div className=" ms-auto" >
                            <Image src={userImage} onClick={()=>setShowMenu(true)} className="icon white-img"/>
                        </div>
                    </div>
                    {isLoggedIn!=null&&<Stack className="text-center  main-chat  align-items-center  " gap={4}>
                        {isLoggedIn?
                        <>
                            <Stack gap={2} className="chat-scroll msg-scroll w-100">
                                {startRef.current==true ? 
                                    <Stack gap={4} className="p-md-5 p-2">
                                        <div className="p-4 d-flex justify-content-between align-items-center flex-wrap gap-2 agi-block">
   
                                            <h3 className="m-0 fw-bold">AutoGPT</h3>

                                            {stopRef.current!=true?
                                            <>
                                                {loadRef.current==true?
                                                                                           <div class="d-flex gap-1">
                                                    <div class="ball"></div>
                                                    <div class="ball"></div>
                                                    <div class="ball"></div>
                                                    <div class="ball"></div>
                                                    <div class="ball"></div>
                                                </div>:
                                                <Button className="agi-buttons" onClick={startAGI}>Continue</Button>
                                                }
                                            </>:
                                            <div>Task Finished!</div>
                                            }

                                        </div>

                                        <div className="p-4 agi-block">
                                            {command.name!=""?<Stack className="" gap={3}>
                                                <div class="d-flex justify-content-between">
                                                    <div/>
                                                    <small>Current Command</small>
                                                    {(taskResults!=null&&taskResults.length>0)?
                                                    <Button className="agi-buttons" onClick={()=>setShowOutputModal(true)}>Get Output</Button>:<div/>}
                                                </div>
                                                <span className="text-capitalize align-self-start"><b>Task: </b>{command.name}</span>
                                                {Object.entries(command.args).map(([key,value]) =>
                                                    <div className="align-self-start text-start d-flex gap-2">
                                                        <div className="text-capitalize fw-bold">{key}: </div>
                                                        <ReactMarkdown children={value} remarkPlugins={[remarkGfm]} />
                                                    </div>
                                                    )
                                                }
                                                <div className="align-self-start text-start d-flex gap-2">
                                                    <div className="text-capitalize fw-bold">Output: </div>
                                                    <ReactMarkdown children={currentOutput} remarkPlugins={[remarkGfm]} />
                                                </div>
                                            </Stack>:<div>Thinking...</div>}
                                        </div>

                                        {outputs.length>0&&<div className="p-4 agi-block">
                                            <small>Previous Outputs</small>
                                                {outputs.map((output)=>
                                                    <div class="d-flex flex-column text-start position-relative">
                                                        <ReactMarkdown children={output} remarkPlugins={[remarkGfm]} />
                                                        <div
                                                            onClick={() =>  navigator.clipboard.writeText(output)}
                                                            className="position-absolute border rounded px-1 bottom-0 end-0 me-2 mb-2"
                                                        >
                                                            <CopyIcon
                                                            className=" small-icon"
                                                            />
                                                        </div>
                                                        <div className="border-bottom w-25 mb-2 align-self-center"></div>
                                                    </div>
                                                )}

                                        </div>}
                                      
                                        
                                    </Stack>
                                 :
                                    <Stack className="p-2 p-md-5 mt-0 m-md-5 align-items-center" gap={3}>
                                        <Image className="mygpt-logo " src={logoImage}/>
                                        <h1 className="fw-bold m-0">Welcome to AutoGPT</h1>
                                        <small>Get started with a task</small>
                                      
                                        <Form.Group className="w-100 " >
                                            <Row>
                                                <Col md={4}>
                                                    <h5><Form.Label className="fw-bold">Enter Goals for your AI Assistant</Form.Label></h5>
                                                </Col>
                                                <Col md={8}>
                                                    <Stack gap={2}>
                                                        {goals.map((goal,index)=>
                                                            <Form.Control value={goal} placeholder="Enter new goal" onChange={(e)=>{goals[index] = e.target.value;setUpdate((prev)=>!prev)}}/>
                                                        )}
                                                        <Button className="agi-buttons px-3 py-1 align-self-start" onClick={()=>{if(goals.length<5){setGoals([...goals,""])}}}><h4 className="m-0">+</h4></Button>
                                                    </Stack>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                     
                                        <Button className="agi-buttons" onClick={startAGI}>Start Task</Button>
                                        <ButtonGroup className="mt-auto flex-wrap gap-1">
                                            <Button onClick={()=>setKeyAdded(false)} className="bg-dark border-0 mt-2"><small>Add Key</small></Button>
                                            <Button className="bg-dark border-0 mt-2">
                                                <Dropdown align="start">
                                                <Dropdown.Toggle
                                                    className="p-0"
                                                    id="dropdown-basic"
                                                >
                                                    <small className="pb-2">Change Model</small>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={()=>changeModel("gpt-3.5-turbo")}>
                                                        GPT-3.5-Turbo
                                                    </Dropdown.Item>
                                                    <Dropdown.Item onClick={()=>changeModel("gpt-4")}>
                                                        GPT-4
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown></Button>
                                            <Button onClick={logout} className="bg-dark border-0 mt-2"><small >Logout</small></Button>
                                        </ButtonGroup>
                                    </Stack>
                                }
                                {/* {loading&&<Stack className="ms-3 loading" direction="horizontal" gap={2}>
                                    <Spinner size="sm" animation="grow" />
                                    <Spinner size="sm" animation="grow" />
                                    <Spinner size="sm" animation="grow" />
                                </Stack>} */}
                                {/* {sessionRef.current!=0&&<Button className="align-self-end share-btn" onClick={shareChat}><ShareIcon className="small-icon me-1"/>Share</Button>} */}

                                <div className="end-chat"></div>
                            </Stack>

                         
                        </>:
                        <>
                            <Image className="mygpt-logo" src={logoImage}/>							
                            {/* <h5 className="">Login to agi</h5> */}
                            <h5>An autonomous AI Agents in your browser</h5>
							<div className="embed-responsive embed-responsive-16by9">
                              <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/11vP_yW_DIE" style={{minHeight:'180px'}}></iframe>
                            </div>
							<Button className="option-buttons align-self-center" href={authUrl}><GoogleIcon className="icon me-2 pb-1"/> Login with Google</Button>
							<h4 className="text-center">Frequently Asked Questions</h4>
							<Accordion >
								<Accordion.Item eventKey="0">
									<Accordion.Header>Is AutoGPT free?</Accordion.Header>
									<Accordion.Body>
										Yes, Auto-GPT is a free open-source simulation tool. It is an open-source  app that leverages the next-generation GPT-4 with automations. As it uses GPT4, there is cost to using the tokens.
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="1">
									<Accordion.Header>Is AutoGPT opensource?</Accordion.Header>
									<Accordion.Body>
										Yes AutoGPT is open-source and available as a repo on Github to run on windows, Mac, mobile and the browser.
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="3">
									<Accordion.Header>How to use AutoGPT on the browser?</Accordion.Header>
									<Accordion.Body>
										You can use AutoGPT on the browser with SamurAI or AgentGPT as both allow capabilities for AutoGPT to access the browser
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="4">
									<Accordion.Header>What is AgentGPT?</Accordion.Header>
									<Accordion.Body>
									AgentGPT is an AutoGPT open-source project that allows users to create autonomous AI agents to perform various tasks on the browser by connecting to the Internet
									</Accordion.Body>
								</Accordion.Item>			
							</Accordion>
                        </>
                        }
                    </Stack>}
                </Col>
                <Col className={startRef.current?" h-100 d-none d-xl-block":"d-none"} xl={4}>
                    <Stack className="agi-side-bar h-100  py-4 px-3 text-center" gap={2}>
                       
                        
                        <div style={{maxHeight:'100%'}} className="p-3 d-flex flex-column gap-2 task-scroll agi-block-side mt-3">
                            {/* <small>Current Task</small>
                            <h5 className=" mt-3">{currentTask}</h5> */}
                             <small>Thoughts</small> 
                            {Object.keys(thoughts).length>0 ?<div className=" w-100">
                            {Object.entries(thoughts).map(([key,value]) => 
                                <div class="d-flex text-start flex-column thoughts gap-2 mb-3">
                                    <div className="text-capitalize text-start fw-bold">{key}: </div>
                                    <ReactMarkdown children={value} remarkPlugins={[remarkGfm]} /> 

                                </div>
                            )
                            }
                            </div>:<div>Thinking...</div>}
                            
                        </div>

                        <div style={{cursor:'pointer'}} onClick={()=>window.location.reload()} className="agi-side-btn mt-auto p-2 d-flex justify-content-center gap-2">
                            <ReloadIcon className="icon"/>
                            New Task
                        </div>
                        <ButtonGroup className=" flex-wrap gap-1">
                            <Button onClick={()=>setKeyAdded(false)} className="agi-side-btn mt-2"><small>Add Key</small></Button>
                            <Button className="agi-side-btn mt-2">
                                <Dropdown align="start">
                                <Dropdown.Toggle
                                    className="p-0"
                                    id="dropdown-basic"
                                >
                                    <small className="pb-2">Change Model</small>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={()=>changeModel("gpt-3.5-turbo")}>
                                        GPT-3.5-Turbo
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={()=>changeModel("gpt-4")}>
                                        GPT-4
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown></Button>
                            <Button onClick={logout} className="agi-side-btn mt-2"><small >Logout</small></Button>
                        </ButtonGroup>
						<div className="product-names mt-3">
						   <a className="me-1" href="https://thesamur.ai/">MyGPT</a> |
						   <a className="me-1 ms-1" href="https://heybot.thesamur.ai/">HeyBot</a> |
						   <a className="me-1 ms-1" href="https://ritebot.thesamur.ai/">RiteBot</a> |
						   <a className="me-1 ms-1" href="https://memegpt.thesamur.ai/">MemeJourney</a> |
						   <a className="me-1 ms-1" href="https://camelagi.thesamur.ai/">Camel AGI</a>
                       </div>

                    </Stack>
                </Col>

            </Row>
            <Offcanvas className="agi-side-bar" show={showMenu} onHide={()=>setShowMenu(false)}>
                <Offcanvas.Header closeButton>
                <Offcanvas.Title>AutoGPT</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="pb-4">
                 <Stack className="text-center" gap={2}>

                 <div style={{maxHeight:'100%'}} className="p-3 d-flex flex-column gap-2 task-scroll agi-block-side mt-3">
                    {/* <small>Current Task</small>
                    <h5 className=" mt-3">{currentTask}</h5> */}
                        <small>Thoughts</small> 
                    <div className=" w-100">
                    {Object.entries(thoughts).map(([key,value]) => 
                        <div class="d-flex text-start flex-column thoughts gap-2 mb-3">
                            <div className="text-capitalize text-start fw-bold">{key}: </div>
                            <ReactMarkdown children={value} remarkPlugins={[remarkGfm]} /> 

                        </div>
                    )
                    }
                    </div>
                    
                </div>
                       
                       
                         <div style={{cursor:'pointer'}} onClick={()=>window.location.reload()} className="agi-side-btn mt-auto p-2 d-flex justify-content-center gap-2">
                            <ReloadIcon className="icon"/>
                            New Task
                        </div>
                    </Stack>
                </Offcanvas.Body>
            </Offcanvas>
            <ToastContainer />	
            <Modal className="banner-modal" centered show={showBanner} onHide={()=>setShowBanner(false)}>
                <Modal.Body className="p-5 position-relative">
                <b style={{cursor:'pointer'}} onClick={()=>setShowBanner(false)} className="position-absolute top-0 end-0 me-4 mt-2">X</b>
                <Stack className="align-items-center" gap={3}>
                    <a href="https://www.producthunt.com/posts/autogpt-plugins?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-autogpt&#0045;plugins" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=393553&theme=light"  alt="AutoGPT&#0032;Plugins - Plugins&#0032;to&#0032;enhance&#0032;the&#0032;functionality&#0032;of&#0032;AutoGPT | Product Hunt" /></a>                    
                    <a className="discord-invite px-3 py-3 ms-3" target="_blank" href="https://discord.gg/A6EzvsKX4u"><DiscordIcon className="icon me-1"/> Join Our Discord</a>
					<a className="discord-invite px-3 py-3 ms-3" target="_blank" href="https://github.com/SamurAIGPT/AutoGPT">Check code on Github</a>
                </Stack>
                </Modal.Body>
            </Modal> 

            <Modal className="key-modal" centered show={keyAdded==false} onHide={()=>setKeyAdded(null)}>
                <Modal.Body className="p-3 p-md-5 position-relative">
                <b style={{cursor:'pointer'}} onClick={()=>setKeyAdded(null)} className="position-absolute top-0 end-0 me-4 mt-2">X</b>
                <Stack className="align-items-center" gap={3}>
                    <h6><b>Add your OpenAI Key</b></h6>
                    <p><small>Get your OpenAI Key by signing up/ logging in from the OpenAI Dashboard. </small><a target="_blank" href="https://platform.openai.com/account/api-keys">Go to Dashboard</a></p>
                    <InputGroup >
                        <FormControl className="chat-input px-md-5 py-md-2 shadow-none"
                        style={{height:'48px'}}
                        value={key}
                        onChange={(e)=>setKey(e.target.value)}
                        onKeyDown={(e)=>{e.code=="Enter"&&addKey()}}
                        />
                        <Button variant="outline-secondary" className="chat-button text-dark fw-bold ps-md-3 pe-md-5 py-md-2" onClick={addKey}>
                            <SendIcon className="icon"/>
                        </Button>
                    </InputGroup>
                    <small>Watch this video to get started</small>
                    <iframe className="key-video" src="https://www.youtube.com/embed/FaJdwbNWNkk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </Stack>
                </Modal.Body>
            </Modal> 

            <Modal className="output-modal" centered show={showOutputModal} onHide={()=>setShowOutputModal(false)}>
                <Modal.Body className="p-3 p-md-4 position-relative">
                <b style={{cursor:'pointer'}} onClick={()=>setShowOutputModal(false)} className="position-absolute top-0 end-0 me-4 mt-2">X</b>
                <h5>Output Files</h5>
                <Tabs
                    id="uncontrolled-tab-example"
                    className="my-3"
                    >
                     {taskResults!=null&&taskResults.map((result) => 
                        <Tab className="task-scroll" eventKey={result.name} title={result.name} >
                            <ReactMarkdown className="p-3 bg-light rounded" children={result.content} remarkPlugins={[remarkGfm]} /> 

                            <div class="d-flex gap-2 mt-2 align-items-center">
                                <Button className="agi-buttons-small" onClick={()=>navigator.clipboard.writeText(result.content)}>Copy</Button>
                                <small>or</small>
                                <Button className="agi-buttons-small" onClick={()=>downloadTxtFile(result.name,result.content)}>Download</Button>
                            </div>
                        </Tab>
                    )}
                   
                </Tabs>
               
                </Modal.Body>
            </Modal> 
		
        </>
    )
}

export default AGI