import React from "react";
import "../../../styles/summary.css";
import "../../../styles/home.css";
import { ReactComponent as DiamondImage } from "../../../assets/diamond.svg";
import { Dropdown, Stack,Row,Table } from "react-bootstrap";
import { ReactComponent as DashboardIcon } from "../../../assets/dashboard.svg";
import { ReactComponent as MembersIcon } from "../../../assets/members.svg";

export default function Retention() {
  return (
    <div className="d-flex justify-content-center">
    <Stack className="content-container mx-4 mx-lg-0" gap={4}>
      <Row className="g-0">
        <Stack direction="horizontal" gap={4}>
          <h4 className="m-0">Retention</h4>

          <Dropdown className="ms-auto">
            <Dropdown.Toggle className="top-filters-cards" id="dropdown-basic">
              <DashboardIcon className="me-3" />
              Discord
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1">
                <DashboardIcon className="me-3" />
                Action
              </Dropdown.Item>
              <Dropdown.Item href="#/action-2">
                <DashboardIcon className="me-3" />
                Another action
              </Dropdown.Item>
              <Dropdown.Item href="#/action-3">
                <DashboardIcon className="me-3" />
                Something else
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown>
            <Dropdown.Toggle className="top-filters-cards" id="dropdown-basic">
              Last 7 days
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
              <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
              <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <div
            className="top-filters-cards d-flex align-items-center justify-content-center"
            style={{ width: "45px" }}
          >
            <DiamondImage />
          </div>
        </Stack>
      </Row>

      <Stack className="w-75 flex-wrap" direction="horizontal" gap={4} >
        <Stack gap={2}>
          <span className="small-text">COHORT TYPE</span>
          <Dropdown>
            <Dropdown.Toggle
              className="top-filters-cards"
              id="dropdown-basic"
            >
              New member has joined
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1">
                <DashboardIcon className="me-3" />
                Action
              </Dropdown.Item>
              <Dropdown.Item href="#/action-2">
                <DashboardIcon className="me-3" />
                Another action
              </Dropdown.Item>
              <Dropdown.Item href="#/action-3">
                <DashboardIcon className="me-3" />
                Something else
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Stack>
        <Stack gap={2}>
          <span className="small-text">COHORT SIZE</span>
          <Dropdown>
            <Dropdown.Toggle
              className="top-filters-cards"
              id="dropdown-basic"
            >
              Week
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1">
                <DashboardIcon className="me-3" />
                Action
              </Dropdown.Item>
              <Dropdown.Item href="#/action-2">
                <DashboardIcon className="me-3" />
                Another action
              </Dropdown.Item>
              <Dropdown.Item href="#/action-3">
                <DashboardIcon className="me-3" />
                Something else
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Stack>
        <Stack gap={2}>
          <span className="small-text">METRIC</span>
          <Dropdown>
            <Dropdown.Toggle
              className="top-filters-cards"
              id="dropdown-basic"
            >
              Message Posted
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1">
                <DashboardIcon className="me-3" />
                Action
              </Dropdown.Item>
              <Dropdown.Item href="#/action-2">
                <DashboardIcon className="me-3" />
                Another action
              </Dropdown.Item>
              <Dropdown.Item href="#/action-3">
                <DashboardIcon className="me-3" />
                Something else
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Stack>
        <Stack gap={2}>
          <span className="small-text">DATE RANGE</span>
          <Dropdown >
            <Dropdown.Toggle
              className="top-filters-cards"
              id="dropdown-basic"
            >
              Last 6 weeks
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1">
                <DashboardIcon className="me-3" />
                Action
              </Dropdown.Item>
              <Dropdown.Item href="#/action-2">
                <DashboardIcon className="me-3" />
                Another action
              </Dropdown.Item>
              <Dropdown.Item href="#/action-3">
                <DashboardIcon className="me-3" />
                Something else
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Stack>
      </Stack>

      <div className="p-4 p-lg-5 rounded-cards">
          <div className="summary-cards-big d-flex">
            <span className="graph-title m-0">Number of members who posted messages</span>
          </div>
      </div>
      <div className="rounded-cards">
        <Table className="stats-table" responsive borderless>
        <thead >
          <tr>
            <th>Date Range</th>
            <th>Active Members</th>
            <th>Week 1</th>
            <th>Week 2</th>
            <th>Week 3</th>
            <th>Week 4</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
            <td>@mdo</td>
            <td>@mdo</td>
          </tr>
         
        </tbody>
        </Table>
      </div>
    </Stack>
    </div>
  );
}
