import {useState} from 'react'
import {Stack,Form, Button} from 'react-bootstrap'
import axios from 'axios'

function ConversationTest() {
    const [link, setLink] = useState("")
    const [name, setName] = useState("")
    const createServer = () => {
        axios.get("/discord_create_server?name="+name).then((res)=>{
          setLink(res.data)
         }).catch((err)=>{
          console.log(err)
         })
      }
    return (
        <Stack gap={4} className="p-5">
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Enter New Server Name</Form.Label>
                <Form.Control className="w-50 mb-3" onChange = {(e)=>setName(e.target.value)}/>
                <Button onClick={createServer}>Create Server</Button>
            </Form.Group>
            {link!=""&&<h5>Invite Link: <a href={link} target="_blank">{link}</a></h5>}
            {link!=""&&<h5>Add Bot: <a href="https://discord.com/api/oauth2/authorize?client_id=1074419354994741350&permissions=8&scope=bot" target="_blank">Stats Bot</a></h5>}
        </Stack>
    )
}

export default ConversationTest