import {useState,useEffect,useRef} from 'react'
import {Stack, Button,InputGroup, FormControl,Modal, Image, Dropdown, Spinner,Row, Col, Offcanvas} from 'react-bootstrap'
import ritebotLogo from '../assets/ritebot.png'
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios"
import '../styles/heybot.css'
import {ReactComponent as SendIcon} from '../assets/send.svg'
import {ReactComponent as GoogleIcon} from '../assets/google.svg'
import { useNavigate } from "react-router-dom";
import { ReactComponent as QuestionIcon } from "../assets/question-mark.svg";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { ReactComponent as DiscordIcon } from "../assets/discord.svg";
import logoImage from '../assets/memegpt.png'

function MemeJourney() {
    const [isLoggedIn,setIsLoggedIn] = useState(false)
    const [authUrl,setAuthUrl] = useState("")
    const [keyAdded,setKeyAdded] = useState(false)
    const [question,setQuestion] = useState("")
    const [chat,setChat] = useState([])
    const [keyword,setKeyword] = useState("")
    const [loading,setLoading] = useState(false)
    const [showCopy, setShowCopy] = useState(0);
    const [showBanner, setShowBanner] = useState(false);
    const [needsKeyword, setNeedsKeyword] = useState(false);
    const [listen,setListen] = useState(null)
    const [user,setUser] = useState({id:0,image:""})
    const navigate = useNavigate()
    const chatRef = useRef(null)
    chatRef.current = chat
    const listenRef = useRef(null)
    listenRef.current = listen
    document.title = "MemeJourney"
    
    
    return (
        <div class=" d-flex justify-content-center">
            <div className="bot-container">
                <Stack className="text-center mx-2 mx-md-5 px-md-5 py-4 vh-100 align-items-center" gap={4}>
                    <Stack className="p-3" direction="horizontal">
                        <h3 className="fw-bold">
                            <Image className="heybot-logo" src={logoImage}/>
                            MemeJourney
                        </h3>
                    </Stack>
                    
					<h5 className="mt-5 pt-5">MemeJourney - Create Memes with AI powered by ChatGPT</h5>
					<Button className="option-buttons align-self-center" href={"https://discord.gg/A6EzvsKX4u"}> Go to MemeJourney</Button>
					<Stack direction="horizontal" gap={2} style={{cursor:'pointer',opacity:'0.7'}} className="mt-auto align-self-center" onClick={()=>navigate('/memejourney/faq')}><QuestionIcon className="icon"/> <b>Know More</b></Stack>                    
                    
                </Stack>
            </div>
            <ToastContainer />	
            <Modal className="banner-modal" centered show={showBanner} onHide={()=>setShowBanner(false)}>
                <Modal.Body className="p-5 position-relative">
                <b style={{cursor:'pointer'}} onClick={()=>setShowBanner(false)} className="position-absolute top-0 end-0 me-4 mt-2">X</b>
                <Stack className="align-items-center" gap={3}>
                    <a href="https://www.producthunt.com/posts/memejourney?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-memejourney" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=384855&theme=light" alt="MemeJourney - Turn&#0032;text&#0032;into&#0032;memes&#0032;using&#0032;ChatGPT | Product Hunt" style="width: 250px; height: 54px;" width="250" height="54" /></a>
                </Stack>
                </Modal.Body>
            </Modal>
		
        </div>
    )
}

export default MemeJourney