import {Image,Button,FormControl} from "react-bootstrap";
import { useState,useEffect,useRef } from "react";
import OpenAI from 'openai';
import logoImage from "../assets/assistant.svg"
import sendImage from "../assets/send-icon.svg"
import { useParams } from "react-router-dom";
import axios from "axios";
import { useLocation, matchPath } from "react-router";

function OpenGPTEmbed() {
    const location = useLocation();
    const isDemoPath = matchPath({ path: "/demo/:assistantId", exact: false },location.pathname);
    const { assistantId } = useParams();
    const [question,setQuestion] = useState("")
    const [demoFields,setDemoFields] = useState({name:"",instructions:"",model:""})
    const [chat,setChat] = useState([])
    const [threadId,setThreadId] = useState(null)
    const [assistant,setAssistant] = useState(null)
    const [run,setRun] = useState(null)
    const [openai,setOpenai] = useState(null)
    const [key,setKey] = useState("")
    const [keyAdded,setKeyAdded] = useState(false)
    const [loading,setLoading] = useState(false)
    const [runInterval,setRunInterval] = useState(null)
    // const searchParams = useSearchParams()
    const intervalRef = useRef(null)
    intervalRef.current = runInterval
    const chatRef = useRef(null)
    chatRef.current = chat

    async function createThread(id) {
        try {
            const payload = {
                id: id
            };
            //const response = await axios.post('http://localhost:5000/opengpt/create_thread', payload, {
            const response = await axios.post('https://embedai.thesamur.ai/opengpt/create_thread', payload, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const threadId = response.data;
            return threadId;
        } catch (error) {
            console.error('Error creating thread:', error);
        }
    }

    const askAssistant = async() => {
        let getQuestion = question
        setQuestion("")
        let chatList = [...chatRef.current,{isBot:false,msg:getQuestion}]
        setLoading((prev)=>true)
        setChat(chatList)
        let newthreadId;
        if(threadId==null){
            newthreadId = await createThread(assistantId)
            setThreadId(newthreadId)
        }
        newthreadId = threadId || newthreadId;

        //let baseUrl = 'http://localhost:9000/api/assistant?message='+question+"&threadId="+newthreadId+"&aid="+assistantId
        let baseUrl = 'https://embedai.vercel.app/api/assistant?message='+question+"&threadId="+newthreadId+"&aid="+assistantId
        const eventSource = new EventSource(baseUrl)
        let msg = "";
        eventSource.onmessage = function (event) {
            if(event.data == "--End--") {
                setLoading((prev)=>false)
                return
            }
            msg = msg + event.data;
            if(msg == event.data) {
                setChat([...chatRef.current, { isBot: true, msg: event.data }]);
            } else {
              setChat((prevChat) => {
                const updatedChat = [...prevChat];
                updatedChat[updatedChat.length - 1].msg = msg;
                return updatedChat;
              });
            }
        }

        eventSource.onerror = () => {
            eventSource.close()
        }
    }

    let createAssistant = async() => {
        let getOpenai = new OpenAI({apiKey:key, dangerouslyAllowBrowser: true})
        setOpenai(getOpenai)
        if(demoFields.name!=""){
            let getAssistant = await getOpenai.beta.assistants.create(demoFields)
            setAssistant(getAssistant)
        }else{
            setAssistant({id:assistantId})
        }
    }

    useEffect(()=>{
        if(keyAdded==true && key !=""){
            createAssistant()
        }
    },[keyAdded])

    useEffect(()=>{
        console.log("Demo path", isDemoPath);
        if(isDemoPath!=null){
            axios.post("/opengpt/get_demo",{id:assistantId}).then(async(res)=>{
                    setDemoFields({name: res.data.name,instructions: res.data.instructions,model:"gpt-4o" })
                }).catch((err)=>{
                console.log(err)
            })
        }
    },[])

    return (
        <div className="vh-100 vw-100 p-md-4 d-flex flex-column bg-myBg gap-4">
            <div className={`d-flex justify-content-between bg-black rounded-2 p-4`}>
                <div className="d-flex align-items-center gap-2">
                    <Image className="opengpt-small-logo" src={logoImage} alt="logo"/>
                    <span className="font-semibold text-white">Custom GPT</span>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <a href="https://thesamur.ai" target="_blank" rel="noopener noreferrer" className="btn btn-primary text-black">
                  Create Custom GPT
                  </a>
                  <a href="https://gpt-auth.com" target="_blank" rel="noopener noreferrer" className="btn btn-primary text-black">
                  Monetize GPT
                  </a>
                </div>
                {/* <div className="d-flex align-align-items-center gap-2 cursor-pointer">
                    <Image height={20} width={20} onClick={refreshChat} src='/refresh.svg'  alt="refresh"/>
                    <Image height={20} width={20} onClick={closeFrame} src='/cancel.svg'/>

                </div> */}

            </div>
            <div className="d-flex flex-column gap-2 w-100 h-100 overflow-y-auto scroll">
                    
                    {chat.map((msg, index)=>
                    <div key={index} className={`${msg.isBot?'bg-darkGray text-white align-self-start ':'bg-light align-self-end border border-2'} rounded-2  px-3 py-2 `}>
                        {msg.msg}
                    </div>)}
                    {loading&&<div  className={`bg-darkGray align-self-start rounded-2  px-3 py-2 `}>
                        <div style={{height:'1.5rem'}} className="d-flex align-items-center gap-2">
                            <div className="bounce bounce1 rounded-circle bg-secondary"/>
                            <div className="bounce bounce2 rounded-circle bg-secondary"/>
                            <div className="bounce bounce3 rounded-circle bg-secondary"/>
                        </div>
                    </div>}
                
                </div>
                <div className="d-flex gap-2 mt-auto">
                  <input  id="question" className=" border border-gray-300 opengpt-small rounded  w-100 p-3 " placeholder="Ask a question" required value={question} onKeyDown={(e) => {e.code == "Enter" && !e.shiftKey && askAssistant();}} onChange={(e)=>setQuestion(e.target.value)}/>
                  <button onClick={askAssistant} style={{}} className=" bg-myBg hover:bg-blue-400 focus:ring-4 focus:outline-none focus:ring-blue-300 fw-medium rounded opengpt-small  px-4 py-3 text-center ">
                    <Image className="opengpt-small-logo" src={sendImage} alt="send"/>
                  </button>
            </div>
            
        </div>
    );
}

export default OpenGPTEmbed;
