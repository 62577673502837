import axios from 'axios';

export function isStringValid(value){
    if (value === "" || value === null || value === undefined) {
        return false
     } else {
        return true
     }
}

export function validateEmail(email) {
   const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
   return emailPattern.test(email);
 }

export function sendAnalyticsEvent(event_name){
   if(event_name==null || event_name===""){
      console.error("Event name is empty")
   }else{
      const postData = {
         event_name : event_name
           };
           
           const headers = {
             'Content-Type': 'application/json'
           }
           
           axios.post('/heybotnew/send_analytics_event', postData, {headers: headers})
             .then(response => {
               // handle success
               console.log(response);
             })
             .catch(error => {
               // handle error
               console.error(error);
             });    
   }
}