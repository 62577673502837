import {Image} from 'react-bootstrap'
import { useState,useEffect } from "react"
import axios from "axios"
import logoImage from '../assets/gptauth-logo.svg'
import { useSearchParams } from "react-router-dom";

function LoginConfirm() {
    let [searchParams, setSearchParams] = useSearchParams();

    useEffect(()=>{
        console.log("Trying to login for authID", localStorage.getItem('gptAuthID'));
        axios.get("/gptauth/login_user",{params:{id:localStorage.getItem('gptAuthID'),code:searchParams.get('code')}}).then((res)=>{
            window.location.href = localStorage.getItem('redirectTo')
        }).catch((err)=>{
        console.log(err)
        })
    },[])
    return (
        <main className="d-flex vh-100 w-full flex-column gap-3 align-items-start justify-content-start p-4 py-5  bg-lightGray">
            <Image priority className="gptauth-logo" src={logoImage}/>
            <div>
            <h4 className="mb-1 gptauth-title fw-semibold">Logging you in ...</h4>
            </div>
          
    
        </main>
    );
}

export default LoginConfirm;
