import React from "react";
import "../../styles/summary.css";
import "../../styles/home.css";
import "../../styles/automations.css";
import { ReactComponent as DiamondImage } from "../../assets/diamond.svg";
import { Button, Dropdown, Stack } from "react-bootstrap";
import { ReactComponent as DashboardIcon } from "../../assets/dashboard.svg";
import { ReactComponent as MembersIcon } from "../../assets/members.svg";

export default function Automations() {
  return (
    <>
      <div className="d-flex justify-content-between align-items-center ps-4 pe-4 mt-4 pb-4">
        <div>
          {" "}
          <h4>Automations</h4>
        </div>
        <div className="d-flex justify-content-right">
          <Stack direction="horizontal" gap={4}>
            <Button className="btn-filled">+ Create workflow</Button>
            <div
              className="top-filters-cards d-flex align-items-center justify-content-center"
              style={{ width: "45px" }}
            >
              <DiamondImage />
            </div>
          </Stack>
        </div>
      </div>

      <div className="px-3">
        <div className="rounded-cards px-3 pt-3">
          <div className="d-flex justify-content-between">
            <span>Your Recent Automations</span>
            <span>View All</span>
          </div>
          <hr></hr>
          <Stack direction="horizontal" gap={3}>
            <span className="automations-value">WORKFLOW NAME</span>
            <span className="automations-value">ACTIONS PERFORMED</span>
            <span className="automations-value">MEMBERS ENGAGEMENT</span>
            <span className="automations-value">LAST UPDATED</span>
            <span className="automations-value">CREATED BY</span>
            <span className="automations-value">STATUS</span>
          </Stack>
          <hr></hr>
          <Stack direction="horizontal" gap={3} className="p-2">
            <div className="d-flex flex-column">
              <span className="automations-value">sfdfD</span>
              <span style={{ maxWidth: "200px" }}>
                A series of messages to onboard new members and make them feel
                welcomed in your Slack......
              </span>
            </div>
            <span className="automations-value">0</span>
            <span className="automations-value">0</span>
            <span className="automations-value">16 minutes ago</span>
            <span className="automations-value">Test</span>
            <span className="automations-value">ACTIVE</span>
          </Stack>
          <hr></hr>
          <Stack direction="horizontal" gap={3} className="p-2">
            <div className="d-flex flex-column">
              <span className="automations-value">sfdfD</span>
              <span style={{ maxWidth: "200px" }}>
                A series of messages to onboard new members and make them feel
                welcomed in your Slack......
              </span>
            </div>
            <span className="automations-value">0</span>
            <span className="automations-value">0</span>
            <span className="automations-value">16 minutes ago</span>
            <span className="automations-value">Test</span>
            <span className="automations-value">ACTIVE</span>
          </Stack>
          <hr></hr>
          <Stack direction="horizontal" gap={3} className="p-2">
            <div className="d-flex flex-column">
              <span className="automations-value">sfdfD</span>
              <span style={{ maxWidth: "200px" }}>
                A series of messages to onboard new members and make them feel
                welcomed in your Slack......
              </span>
            </div>
            <span className="automations-value">0</span>
            <span className="automations-value">0</span>
            <span className="automations-value">16 minutes ago</span>
            <span className="automations-value">Test</span>
            <span className="automations-value">ACTIVE</span>
          </Stack>
        </div>
      </div>

      <div className="px-3 mt-5 mb-5">
        <div className="rounded-cards px-3 pt-3">
          <div className="d-flex justify-content-between align-items-center">
            <span>Templates</span>
            <Dropdown>
              <Dropdown.Toggle
                className="top-filters-cards"
                id="dropdown-basic"
              >
                Select
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">
                  <DashboardIcon className="me-3" />
                  Action
                </Dropdown.Item>
                <Dropdown.Item href="#/action-2">
                  <DashboardIcon className="me-3" />
                  Another action
                </Dropdown.Item>
                <Dropdown.Item href="#/action-3">
                  <DashboardIcon className="me-3" />
                  Something else
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <hr></hr>
          <div className="mt-2">Tags</div>
          <div className="mt-2">Tags</div>
        </div>
      </div>
    </>
  );
}
