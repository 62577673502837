import {Image,Button,FormControl, FormCheck} from 'react-bootstrap'
import { useState,useEffect } from "react"
import OpenAI from 'openai';
import {Link} from "react-router-dom";
import logoImage from "../assets/assistant.svg"
import homeImage from "../assets/home-white.svg"
import backImage from "../assets/back.svg"
import linkImage from "../assets/link-black.svg"
import { useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer,toast } from "react-toastify";

export default function OpenGPTCreate() {
  const [key,setKey] = useState("")
  const [name,setName] = useState("")
  const [instructions,setInstructions] = useState("")
  const [types,setTypes] = useState([])
  const [functions,setFunctions] = useState([])
  const [update,setUpdate] = useState(false)
  const [files,setFiles] = useState([])
  const [openai,setOpenai] = useState(null)
  const [assistant,setAssistant] = useState(null)
  const [showShare,setShowShare] = useState(false)
  const { assistantId } = useParams();


  const createAssistant = async() => {
    let tool_resources = {}
    if(key!=""){
      if(name!=""&&instructions!=""){
        let fileIds = []
        let fileDetails = []
        if(files.length>0){
          for await (const file of files) {
            if(file.id!=null&&file.id!=undefined){
              fileIds.push(file.id)
              fileDetails.push(file)
            }else{
              let saveFile = await openai.files.create({
              file: file,
              purpose: "assistants",
              })
              fileIds.push(saveFile.id)
              fileDetails.push({id:saveFile.id,name:file.name})
            }
          }
          let vectorStore = await openai.beta.vectorStores.create({
            name:"Financial Statement", file_ids:fileIds
          });
          tool_resources = {"file_search": {"vector_store_ids": [vectorStore.id]}}
        }
        let tools = []
        types.forEach((tool)=>
          tools.push({"type":tool})
        )
        functions.forEach((fn)=>
          tools.push({"type":"function","function":JSON.parse(fn)})
        )
        let model = "gpt-4o"
        let getAssistant
        if(assistant==null){
          try {
            getAssistant = await openai.beta.assistants.create({
              name: name,
              instructions: instructions,
              model:model,
              tools: tools,
              tool_resources: tool_resources
            })
          } catch (error) {
            toast.error("An error occurred:"+ error);
          }
        }else{
          console.log("updating")
          try {
            getAssistant = await openai.beta.assistants.update(assistant,{
              name: name,
              instructions: instructions,
              model:model,
              tools: tools,
              tool_resources: tool_resources
            })
          } catch (error) {
            toast.error("An error occurred:"+ error);
          }
        }
        setAssistant(getAssistant.id)
        setFiles(fileDetails)
        setShowShare(true)
       
        axios.post("/opengpt/save_assistant",{ id: getAssistant.id,name:name,instructions:instructions,model:model,tools:tools,files:fileDetails }).then((res)=>{

          if(assistantId=="new"){
            window.location.href = '/opengpt/create/'+getAssistant.id
          }
          }).catch((err)=>{
          console.log(err)
      })
        
        
      }else{
        alert("Add you assistant's name and instructions!")
      }
    }else{
      alert('Add your OpenAI Key!')
    }
  }

  const addType = (type) => {
    if(types.includes(type)){
      var filteredArray = types.filter(e => e !== type)
      setTypes(filteredArray)
    }else{
      setTypes([...types,type])
    }
  }
  const addFunction = (index,input) => {
    functions[index] = input
    setUpdate((prev)=>!prev)
  }
  const removeFunction = (index) => {
    if(index==0){
      setFunctions([])
    }else{
      let newFns = functions.splice(index,1)
      setFunctions(newFns)
    }
  }
  const removeFile = async(file) => {
    var filteredArray = files.filter(e => e.name !== file.name)
    setFiles(filteredArray)
    if(assistant!=null){
      const deletedAssistantFile = await openai.beta.assistants.files.del(
        assistant,
        file.id
      );
    }
  }
  const shareEmbed = (type) => {
    if(type==0){
      navigator.clipboard.writeText('<iframe src="'+window.location.host+'/embed/'+assistant+'" />')
    }else{
      navigator.clipboard.writeText(window.location.host+'/embed/'+assistant)
    }
    toast.success("Copied")
  }
  const fetchAssistant = () => {
    console.log("Id", assistantId);
    if(assistantId != "new") {
      axios.post("/opengpt/get_assistant",{id:assistantId}).then((res)=>{
        let getOpenai = new OpenAI({apiKey:res.data.openai_key, dangerouslyAllowBrowser: true})
        setOpenai(getOpenai)
        setKey(res.data.openai_key)
        if(res.data.assistant!=null){
          setAssistant(res.data.assistant.id)
          setShowShare((prev)=>true)
          setName(res.data.assistant.name)
          setInstructions(res.data.assistant.instructions)
          res.data.assistant.tools.forEach(tool => {
            if(tool.type=="function"){
              setFunctions([...functions,tool.function])
            }else{
              setTypes([...types,tool.type])
            }
          });
          setFiles(res.data.assistant.files)
        }
      }).catch((err)=>{
        console.log(err)
      })
    } else {
      axios.get("/opengpt/isLoggedIn").then((res)=>{
          if(res.data.isLoggedIn == true){
              if(res.data.openai_key !=""){
                  setKey(res.data.openai_key)
                  let getOpenai = new OpenAI({apiKey:res.data.openai_key, dangerouslyAllowBrowser: true})
                  setOpenai(getOpenai)
              }
          }
      }).catch((err)=>{
          console.log(err)
      })
    }
  }
  useEffect(()=>{
      fetchAssistant()
  },[])
  return (
    <div className="d-flex vh-100 flex-column opengpt-small  bg-myBg ">
        <ToastContainer/>
        <div id="header" className="d-flex align-items-center justify-content-between flex-wrap gap-2 bg-black text-white px-2 px-md-4 py-4  ">
            <div className="d-flex align-items-center gap-2">
                <Image src={logoImage} className="opengpt-logo" alt="logo"/>
              <h6 className="  opengpt-title fw-semibold">Custom GPT</h6>
            </div>
            <Link to="/opengpt">
              <Image src={homeImage} className="opengpt-small-logo" alt="home"/>
            </Link>
        </div>
        {showShare==false?<div className=" opengpt-container px-2 px-md-4 py-4 d-flex flex-column gap-4">
          <div className="d-flex flex-column gap-1">
            <label htmlFor="name" className="block mb-2 fw-medium ">Enter assistant name</label>
            <FormControl  id="name" className=" border border-secondary opengpt-small rounded-2 w-full px-3 py-2" placeholder="UX Designer" required value={name} onChange={(e)=>setName(e.target.value)}/>
          </div>
          <div className="d-flex flex-column gap-1">
            <label htmlFor="instructions" className="block mb-2 fw-medium ">Enter instructions</label>
            <textarea id="instructions" className=" border border-secondary opengpt-small rounded-2 w-full px-3 py-2" required placeholder="Act as a UX Designer to help with my project." value={instructions} onChange={(e)=>setInstructions(e.target.value)}/>
          </div>
          <div className="d-flex flex-column gap-1">
            <label htmlFor="type" className="block mb-2 fw-medium ">Select type of assistant</label>
            <div className="d-flex flex-column gap-3 text-sm">
              <label className="position-relative d-flex align-items-center cursor-pointer">
                <FormCheck type="switch" defaultChecked={types.includes('code_interpreter')?true:false} className="sr-only peer"  onClick={()=>addType('code_interpreter')}/>
                <span className="ms-1 fw-medium ">Code Interpreter</span>
              </label>
              <label className="position-relative d-flex align-items-center cursor-pointer">
                <FormCheck type="switch" defaultChecked={types.includes('retrieval')?true:false} className="sr-only peer"  onClick={()=>addType('file_search')}/>
                <span className="ms-1 fw-medium ">Retrieval</span>
              </label>
              <div className="d-flex align-items-center gap-2 cursor-pointer">
                <div className=" rounded-circle bg-mySecondary fs-6 fw-bold px-2 py-0 " onClick={()=>{setFunctions([...functions,''])}}>+</div>
                <span className="fw-medium ">Functions</span>

              </div>
            </div>
            {functions.map((fn,index)=><div key={index} className="position-relative">
              <textarea id="functions" className=" mt-3 border rounded-2 focus:ring-blue-500 focus:border-blue-500 block w-full p-3 w-100" required placeholder='{"name": "get_weather", "description": "Determine weather in my location"}'  value={fn} onChange={(e)=>addFunction(index,e.target.value)}/>
              <div className="position-absolute z-10 top-0 end-0 mt-3 me-2 fw-bold cursor-pointer" onClick={()=>removeFunction(index)}>x</div>
              </div>)}


          </div>
          <div className="d-flex flex-column gap-2">
            <label className=" fw-medium " htmlFor="user_avatar">Upload files</label>
            <FormControl className=" border rounded-lg  cursor-pointer  focus:outline-none" aria-describedby="user_avatar_help" id="user_avatar" type="file" onChange={(e)=>setFiles([...files,e.target.files[0]])}/>
            <div className="d-flex gap-2">
              {files.map((file,index)=><div className="text-xs w-min whitespace-nowrap border border-gray-400 py-1 px-2 rounded-xl d-flex gap-1">{file.name}  <b className=" cursor-pointer" onClick={()=>removeFile(file)}>x</b></div>)}
            </div>
          </div>

          <Button onClick={createAssistant} className="bg-mySecondary border border-secondary text-black opengpt-button fw-medium rounded-2 opengpt-small w-full px-3 py-2 ">Submit</Button>
          {assistant!=null&&<Button onClick={()=>setShowShare(true)} className="bg-mySecondary border border-secondary text-black opengpt-button fw-medium rounded-2 opengpt-small w-full px-3 py-2 ">Share created assistant</Button>}
        </div>:<div className="h-100 flex-grow-1 px-2 px-md-4 py-4 d-flex flex-column gap-4 ">
          <div className="d-flex flex-wrap gap-2 justify-content-between w-100">
            <div className="d-flex flex-column gap-1">
              <div className="d-flex align-items-center gap-2 cursor-pointer" onClick={()=>setShowShare(false)}>
                <Image src={backImage} style={{width:'1rem',height:'1rem'}} alt="back"/>
                
                <span className="">Back</span>
              </div>
              <div className="d-flex align-items-center gap-2">
                <Image src={linkImage} className="opengpt-small-logo" alt="share"/>
                <h6 className="fw-semibold fs-4 m-0">Share your assistant</h6>
              </div>
            </div>
            <div className="d-flex gap-2">
              <Button onClick={()=>shareEmbed(0)} className="bg-mySecondary border border-secondary text-black opengpt-button fw-medium rounded-2 opengpt-small w-full px-3 py-2 ">
                  Copy Embed
              </Button>
              <Button onClick={()=>shareEmbed(1)} className="bg-mySecondary border border-secondary text-black opengpt-button fw-medium rounded-2 opengpt-small w-full px-3 py-2 ">
                  Copy Link
              </Button>
            </div>
          </div>  
          <iframe src={"/embed/"+assistant} className="h-100 grow rounded-2 border"/>
        </div>}
    </div>
  )
}
