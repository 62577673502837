import { useState, useEffect } from "react";
import {
  Table,
  Stack,
  Row,
  Dropdown,
  Pagination,
  Modal,
  Button,
  Form,
  InputGroup,
  DropdownButton,
  Col,
  Popover,
  OverlayTrigger,
  ListGroup,
} from "react-bootstrap";
import { ReactComponent as DashboardIcon } from "../assets/dashboard.svg";
import { ReactComponent as MembersIcon } from "../assets/members.svg";
import { ReactComponent as DiamondImage } from "../assets/diamond.svg";
import axios from "axios";
import { ReactComponent as DownloadIcon } from "../assets/download.svg";
import { ReactComponent as OverflowMenuIcon } from "../assets/more_vert.svg";
import Filters from "./home/members/Filters";
import { addFilter } from "../redux/slice/MembersSlice";
import { useSelector, useDispatch } from "react-redux";

function Members() {
  let pages = [];
  const [members, setMembers] = useState([]);
  const [memberShow, setMemberShow] = useState({ id: 0, show: false });
  const [memberProfile, setMemberProfile] = useState(null);
  const [memberActivity, setMemberActivity] = useState([]);
  const [weekBefore, setWeekBefore] = useState(0);
  const [getFilters, setFilters] = useState([]);
  const [getDeletedFilter, setDeletedFilter] = useState({});
  const dispatch = useDispatch();
  const addedFilters = useSelector((state) => state.membersReducer.filters);
  const [getFilterConditions, setFilterConditions] = useState({
    Name: ["contains"],
    Email: ["contains"],
    Tag: ["is"],
    Level: ["is", "is not"],
    "Joined Date": ["in"],
    Platform: ["is"],
    Active: ["in"],
    Inactive: ["in"],
  });
  let memberLength = 0;
  const [currentPage, setCurrentPage] = useState(1);
  const getMemberPage = () => {
    // axios.post("/discord_get_members",{page:currentPage}).then((res)=>{
    //   memberLength = res.data.pages.length
    //   for (let number = 1; number <= memberLength; number++) {
    //     pages.push(
    //         <Pagination.Item className={(number<(currentPage-2) || (number>(currentPage+2)))?"d-none":"d-block"} key={number} eventKey={number} onClick={()=>setCurrentPage((prev)=>number)} active={number === currentPage}>
    //         {number}
    //         </Pagination.Item>,
    //     );
    //     }
    //   setMembers([...members,res.data.members])
    //  }).catch((err)=>{
    //   console.log(err)
    //  })
    const param = JSON.stringify({ data: addedFilters });

    axios
      .get("/get_members_data", { params: { filters: param } })
      .then((res) => {
        console.log(res);
       
          setMembers(res.data.members);
        
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getMemberPage();
    // if (memberShow.id === 0) {
    //   setMemberProfile(null);
    // } else {
    //   axios
    //     .post("/discord_get_member_profile", { id: memberShow.id })
    //     .then((res) => {
    //       setMemberProfile(res.data);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // }
  }, [memberShow, addedFilters]);

  const loadActivity = () => {
    axios
      .post("/discord_get_member_profile_interactions", {
        id: memberShow.id,
        weekBefore: weekBefore + 1,
      })
      .then((res) => {
        setMemberActivity([...memberActivity, res.data]);
        setWeekBefore((prev) => prev + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const filtersPopover = (
    <Popover>
      <Popover.Body>
        <ListGroup as="ol" style={{ fontSize: "12px" }}>
          <ListGroup.Item
            as="button"
            className="d-flex justify-content-start border-0"
            onClick={(e) => setFilterData(e)}
          >
            Name
          </ListGroup.Item>
          <ListGroup.Item
            as="button"
            className="d-flex justify-content-start border-0"
            onClick={(e) => setFilterData(e)}
          >
            Email
          </ListGroup.Item>
          <ListGroup.Item
            as="button"
            className="d-flex justify-content-start border-0"
            onClick={(e) => setFilterData(e)}
          >
            Tag
          </ListGroup.Item>
          {/* <ListGroup.Item
            as="button"
            className="d-flex justify-content-start border-0"
            onClick={(e) =>
              setFilterData(e)
            }
          >
            Level
          </ListGroup.Item> */}
          <ListGroup.Item
            as="button"
            className="d-flex justify-content-start border-0"
            onClick={(e) => setFilterData(e)}
          >
            Joined Date
          </ListGroup.Item>
          <ListGroup.Item
            as="button"
            className="d-flex justify-content-start border-0"
            onClick={(e) => setFilterData(e)}
          >
            Platform
          </ListGroup.Item>
          <ListGroup.Item
            as="button"
            className="d-flex justify-content-start border-0"
            onClick={(e) => setFilterData(e)}
          >
            Active
          </ListGroup.Item>
          <ListGroup.Item
            as="button"
            className="d-flex justify-content-start border-0"
            onClick={(e) => setFilterData(e)}
          >
            Inactive
          </ListGroup.Item>
        </ListGroup>
      </Popover.Body>
    </Popover>
  );

  const setFilterData = (e) => {
    setFilters((prev) => [
      ...prev,
      {
        id: e.timeStamp,
        name: e.target.textContent,
        value: "",
        condition: getFilterConditions[e.target.textContent][0],
      },
    ]);
    dispatch(
      addFilter([
        ...addedFilters,
        {
          id: e.timeStamp,
          name: e.target.textContent,
          condition: getFilterConditions[e.target.textContent][0],
          value: "",
        },
      ])
    );
  };

  useEffect(() => {
    if (getDeletedFilter != null) {
      const newFilterData = getFilters.filter(
        (f) => f.id !== getDeletedFilter.id
      );
      setFilters(newFilterData);
      dispatch(addFilter(newFilterData));
    }
  }, [getDeletedFilter]);

  return (
    <div className="d-flex justify-content-center ms-4">
      <Stack className="ms-4 mx-4 mx-lg-0" gap={4}>
        <Row className="g-0">
          <Stack direction="horizontal" gap={4}>
            <h4 className="m-0">Members</h4>
          </Stack>

          {/** Input group for search items */}
          <Stack direction="horizontal" className="mt-4">
            <div>
              <InputGroup className="mb-3 options-bg">
                <Form.Control
                  aria-label="Text input with dropdown button"
                  placeholder="Search by username,name..."
                />

                <DropdownButton
                  title=""
                  id="input-group-dropdown-2"
                  align="end"
                >
                  <Dropdown.Item href="#">Action</Dropdown.Item>
                  <Dropdown.Item href="#">Another action</Dropdown.Item>
                  <Dropdown.Item href="#">Something else here</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item href="#">Separated link</Dropdown.Item>
                </DropdownButton>
              </InputGroup>
            </div>
          </Stack>

          {/* Stack for filters, drop down menu, download options */}
          <div>
            <Row className="row-cols-auto">
              {getFilters != null && getFilters.length > 0
                ? getFilters.map((filter) => (
                    <Filters
                      filter={filter}
                      key={filter.id}
                      setDeletedFilter={setDeletedFilter}
                    ></Filters>
                  ))
                : ""}

              <div style={{ width: "50%" }}>
                <Col>
                  <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    overlay={filtersPopover}
                    rootClose
                  >
                    <Button className="options-bg">+ Add Filter</Button>
                  </OverlayTrigger>
                </Col>
              </div>

              <div className="d-flex">
                <Dropdown className="ms-auto">
                  <Dropdown.Toggle className="options-bg" id="dropdown-basic">
                    # Tag member
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">
                      <DashboardIcon className="me-3" />
                      Action
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      <DashboardIcon className="me-3" />
                      Another action
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      <DashboardIcon className="me-3" />
                      Something else
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <div
                  className="d-flex align-items-center justify-content-center options-bg ms-3"
                  style={{ height: "30px", width: "30px" }}
                >
                  <DownloadIcon style={{ height: "1rem", width: "1rem" }} />
                </div>
                <div
                  className="d-flex align-items-center justify-content-center options-bg ms-3"
                  style={{ height: "30px", width: "30px" }}
                >
                  <OverflowMenuIcon style={{ height: "1rem", width: "1rem" }} />
                </div>
              </div>
            </Row>
          </div>
        </Row>

        {/*Row for members data */}
        <Row className="mt-3">
          <div>
            <div>
              <Table className="stats-table" responsive bordered>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Profile</th>
                    <th>Level</th>
                    <th>Joined</th>
                    <th>Last Activity</th>
                    <th>Total Score</th>
                    <th>Help Score</th>
                    <th>Engage Score</th>
                    <th>Encourage Score</th>
                    <th>Tags</th>
                    <th>Reactions</th>
                    <th>Messages</th>
                  </tr>
                </thead>
                <tbody>
                  {members != null &&
                    members.length > 0 &&
                    members.map((member) => (
                      <tr>
                        <td>{member.name}</td>
                        <td>{member.profile}</td>
                        <td>{member.level}</td>
                        <td>{member.joined}</td>
                        <td>{member.last_activity}</td>
                        <td>{member.total_score}</td>
                        <td>{member.help_score}</td>
                        <td>{member.engage_score}</td>
                        <td>{member.encourage_score}</td>
                        <td>{member.tags}</td>
                        <td>{member.reactions_count}</td>
                        <td>{member.messages_count}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </div>
        </Row>
        <Pagination className="justify-content-center pt-5">
          <Pagination.First onClick={() => setCurrentPage((prev) => 1)} />
          <Pagination.Prev
            onClick={() =>
              setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev))
            }
          />
          {pages}
          <Pagination.Next
            onClick={() =>
              setCurrentPage((prev) => (prev < memberLength ? prev + 1 : prev))
            }
          />
          <Pagination.Last
            onClick={() => setCurrentPage((prev) => memberLength)}
          />
        </Pagination>
      </Stack>
      <Modal
        show={memberShow.show}
        onHide={() => setMemberShow({ id: 0, show: false })}
      >
        {memberProfile != null && (
          <>
            <Modal.Header closeButton>
              <Modal.Title>{memberProfile.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {memberActivity.map((page) =>
                page.map((activity) => (
                  <div>
                    <small>
                      {memberProfile.name} {activity.type}
                    </small>
                    <br />
                    <span>{activity.content}</span>
                  </div>
                ))
              )}
              <Button onClick={loadActivity}>Load Activity</Button>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </>
        )}
      </Modal>
    </div>
  );
}

export default Members;
