import React, { useEffect, useState, useRef } from "react";
import { Container, Stack, FormControl, Button } from "react-bootstrap";
import { ReactComponent as SendIcon } from "../assets/send-icon.svg";
import { loadQARefineChain } from "langchain/chains";
import { ChatOpenAI } from "langchain/chat_models/openai";
import { MemoryVectorStore } from "langchain/vectorstores/memory";
import { OpenAIEmbeddings } from "langchain/embeddings/openai";
import { Document } from "langchain/document";
import axios from 'axios'
import '../styles/csvagent.css'

const ChatPDF = () => {
    document.title= "PDF Agent"
    const [question, setQuestion] = useState("");
    const [chat,setChat] = useState([])
    const [PDFData,setPDFData] = useState([])
    const [key, setKey] = useState("");
    const [file, setFile] = useState(null);
    const [keyAdded, setKeyAdded] = useState(false);
    const [fileAdded, setFileAdded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [streamAns,setStreamAns] = useState("")
    const loadRef = useRef(null)
    loadRef.current = loading
    const chatRef = useRef(null)
    chatRef.current = chat
    const streamRef = useRef(null)
    streamRef.current = streamAns
    const saveKey = () => {
        localStorage.setItem("openaikey", key);
        setKeyAdded(true);
    }

    const saveFile = () => {
        setFileAdded(true)
        axios.post("/load_pdf", {file:file}, {
            headers: {
            'Content-Type': 'multipart/form-data'
            }
        }).then((res)=>{
            let docs = []
            res.data.forEach(content => {
                docs.push(new Document({ pageContent: content }))
            });
            setPDFData(docs)

          }).catch((err)=>{
            console.log("Error in PDF");
        })
    }
    let askPDF = async() => {
        setChat([...chatRef.current,{isBot:false,msg:question}])
        setLoading((prev)=>true)
        let getQuestion = question
        setQuestion("")
        const embeddings = new OpenAIEmbeddings({openAIApiKey:key});
        const model = new ChatOpenAI({ openAIApiKey:key,temperature: 0,streaming: true,callbacks: [
            {
                handleLLMNewToken(token: string) {
                    // console.log(token)
                    setStreamAns(streamRef.current.concat(token))
                },
            }],
         });
        const chain = loadQARefineChain(model);

        
        const store = await MemoryVectorStore.fromDocuments(PDFData, embeddings);

        const relevantDocs = await store.similaritySearch(getQuestion);
        setLoading((prev)=>false)
        setChat([...chatRef.current,{isBot:true,msg:""}])
        await chain.call({
            input_documents: relevantDocs,
            question:getQuestion,
        }).then((output)=>{chatRef.current.at(-1).msg=output.output_text;setStreamAns((prev)=>"")})
        

    }

    useEffect(() => {
        if (localStorage.getItem("openaikey") !== null && localStorage.getItem("openaikey") !== undefined) {
            setKey(localStorage.getItem("openaikey"));
            setKeyAdded(true);
        }
    },[])

    useEffect(() => {
        if (document.querySelector(".end-chat") != null) {
          document
            .querySelector(".end-chat")
            .scrollIntoView({ behavior: "smooth", block: "end" });
        }
      }, [chat]);
    return (
        <div className="csv-agent-bg vh-100  p-md-5">
            <div className="csv-agent-container text-light h-100 p-3 p-md-5">
                <div class="d-flex justify-content-between">
                    <div class="d-flex flex-column">
                        <h1>// <b style={{color:'#F40F02'}}>Chat</b><i>PDF</i></h1>
                        <span>---  Chat with your PDF file  ---</span>
                    </div>
                    <div class="d-flex flex-column text-end">
                        {fileAdded&&<div style={{cursor:'pointer'}} className="fw-bold" onClick={()=>setFileAdded(false)}>[ {file.name} ]</div>}
                        <small style={{cursor:'pointer'}} className="fw-bold" onClick={()=>setKeyAdded(false)}>Change Key</small>
                    </div>
                </div>
                {keyAdded===false?
                    <Stack gap={3} className="my-5">
                        <h4 className="m-0">{'>'} Add your OpenAI Key</h4>
                        <div class="d-flex align-items-center justify-content-between gap-4 csv-agent-key">
                            <FormControl onChange={(e) => setKey(e.target.value)} className="helper-input pdf-input" onKeyDown={(e) => {
                            e.code == "Enter" && !e.shiftKey && saveKey();
                        }}/>

                            <SendIcon onClick={saveKey} style={{cursor:"pointer",color:key===""?"#EEE5E9":"#F40F02"}} className="mygpt-icon" />
                        </div>
                        <p className="m-0"><small>Get your OpenAI Key by signing up/ logging in from the OpenAI Dashboard. </small><a target="_blank" href="https://platform.openai.com/account/api-keys">Go to Dashboard</a></p>
                        <small>--- Watch this video to get started ---</small>
                        <iframe className="csv-agent-video " src="https://www.youtube.com/embed/FaJdwbNWNkk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </Stack>:
                    <>
                        {fileAdded===false?
                        <Stack gap={3} className="my-5">
                            <h4 className="m-0">{'>'} Add your PDF file</h4>
                            <div class="d-flex align-items-center justify-content-between gap-4 csv-agent-key">
                                <FormControl className="helper-input pdf-input" type="file" accept=".pdf" onChange={(e) => setFile(e.target.files[0])}/>

                                <SendIcon onClick={saveFile} style={{cursor:"pointer",color:key===""?"#EEE5E9":"#F40F02"}} className="mygpt-icon" />
                            </div>
                        </Stack>:
                        <Stack gap={3} style={{height:'85%'}} className="pt-5">
                            <Stack className="h-100 csv-agent-scroll" gap={3}>
                                <div className="px-3 py-2 helper-msg pdf-msg align-self-start">
                                    Hi!
                                </div>
                                <div className="px-3 py-2 helper-msg pdf-msg align-self-start">
                                    I can help with your file, ask me anything!
                                </div>
                                {chatRef.current.length>0&&chatRef.current.map((message)=>
                                    <div className={message.isBot?"px-3 py-2 helper-msg pdf-msg align-self-start":"px-3 py-2 csv-user-msg align-self-end"}>
                                        {message.msg==""?<span>{streamRef.current}<span class="blinking-cursor"> █</span></span>:<span>{message.msg}</span>}
                                    </div>
                                )}
                                {loadRef.current&&<small className="csv-agent-loading">Thinking </small>}
                                <div className="end-chat"></div>

                            </Stack>
                            <div class="mt-auto d-flex align-items-center justify-content-between gap-4">
                                <FormControl value={question} onChange={(e) => setQuestion(e.target.value)} className="helper-input pdf-input" onKeyDown={(e) => {
                                e.code == "Enter" && !e.shiftKey && askPDF();
                                }}/>
                                <SendIcon onClick={askPDF} style={{cursor:"pointer",color:question===""?"#EEE5E9":"#F40F02"}} className="mygpt-icon" />
                            </div>
                        </Stack>
                        }
                    </>
                
                }
                
            </div>
        </div>
    );
};

export default ChatPDF;
