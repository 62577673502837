import {useState,useEffect,useRef} from 'react'
import {Stack, Button,InputGroup, FormControl,Modal, Image, Dropdown, Spinner,Row, Col, Offcanvas, Form} from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios"
import '../styles/heybot.css'
import {ReactComponent as SendIcon} from '../assets/send.svg'
import {ReactComponent as GoogleIcon} from '../assets/google.svg'
import { useNavigate } from "react-router-dom";
import { ReactComponent as QuestionIcon } from "../assets/question-mark.svg";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { ReactComponent as DiscordIcon } from "../assets/discord.svg";
import logoImage from '../assets/privategpt.png'

function Langchain() {
    const [showBanner, setShowBanner] = useState(false);
    const navigate = useNavigate()
    document.title = "Langchain course"
	function gotoLangchain() {
		window.open("https://github.com/SamurAIGPT/langchain-course", "_blank")
	}
	useEffect(() => {
		//window.setTimeout(()=>setShowBanner(true),30000)
    }, [])		
    
    return (
        <div class=" d-flex justify-content-center">
            <div className="bot-container">
                <Stack className="text-center mx-2 mx-md-5 px-md-5 py-4 vh-100 align-items-center" gap={4}>
                    <Stack className="p-3" direction="horizontal">
                        <h3 className="fw-bold">
						<Image className="heybot-logo" src={logoImage}/>
                            Langchain Course & Langchain Tutorials
                        </h3>
                    </Stack>
                    
					<h5 className="mt-5 pt-5">Langchain Course - Introduction to Langchain 101 course & Langchain Tutorials</h5>
					<Button className="option-buttons align-self-center" onClick={()=>gotoLangchain()}> Go to Langchain</Button>
                    
                </Stack>
            </div>
            <ToastContainer />	
            <Modal className="banner-modal" centered show={showBanner} onHide={()=>setShowBanner(false)}>
                <Modal.Body className="p-5 position-relative">
                <b style={{cursor:'pointer'}} onClick={()=>setShowBanner(false)} className="position-absolute top-0 end-0 me-4 mt-2">X</b>
                <Stack className="align-items-center" gap={3}>
                  <a href="https://www.producthunt.com/posts/langchain?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-langchain" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=398461&theme=light" alt="Langchain - Introduction&#0032;to&#0032;Langchain&#0032;101&#0032;course | Product Hunt" style="width: 250px; height: 54px;" width="250" height="54" /></a>
                </Stack>
                </Modal.Body>
            </Modal>
		
        </div>
    )
}

export default Langchain