import { useState, useEffect, useRef, useCallback } from "react";
import {
  Stack,
  Button,
  InputGroup,
  FormControl,
  Modal,
  Image,
  Dropdown,
  Spinner,
  Row,
  Col,
  Offcanvas,
  Form,
  Container,
  Accordion,
} from "react-bootstrap";
import "../styles/clone.css";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SearchIcon } from "../assets/search.svg";
import { ReactComponent as GoogleIcon } from "../assets/google-logo.svg";
import { ReactComponent as CopyIcon } from "../assets/copy.svg";
import { ReactComponent as SendIcon } from "../assets/send-icon.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logoImage from "../assets/mygpt-new-logo4.png";
import userImage from "../assets/user.png";
import { ReactComponent as QuestionIcon } from "../assets/question-mark.svg";
import { ReactComponent as ShareIcon } from "../assets/share.svg";
import { ReactComponent as ShareWhiteIcon } from "../assets/share-white.svg";
import { ReactComponent as DiscordIcon } from "../assets/discord.svg";
import { ReactComponent as TwitterIcon } from "../assets/twitter.svg";
import { ReactComponent as StarIcon } from "../assets/star.svg";
import { ReactComponent as AddIcon } from "../assets/add-icon.svg";
import { ReactComponent as DeleteIcon } from "../assets/delete-icon.svg";
import { ReactComponent as ChatIcon } from "../assets/chat-icon.svg";
import { ReactComponent as PromptIcon } from "../assets/prompt-icon.svg";
import { ReactComponent as ModelIcon } from "../assets/model-icon.svg";
import { ReactComponent as KeyIcon } from "../assets/key-mygpt.svg";
import { ReactComponent as LogoutIcon } from "../assets/logout-mygpt.svg";
import { ReactComponent as LightThemeIcon } from "../assets/light-mode-icon.svg";
import { ReactComponent as DarkThemeIcon } from "../assets/dark-mode-icon.svg";
import { ReactComponent as CheckIcon } from "../assets/check-icon.svg";
import { ReactComponent as MenuIcon } from "../assets/menu.svg";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import heybotLogo from "../assets/heybot.png";
import { useSearchParams, useParams } from "react-router-dom";
import axios from "axios";
import PromptModal from "../components/gptclone/PromptModal";
import { ChatOpenAI } from "langchain/chat_models/openai";
import {
  SystemMessagePromptTemplate,
  HumanMessagePromptTemplate,
  ChatPromptTemplate,
  MessagesPlaceholder,
} from "langchain/prompts";
import { BufferMemory } from "langchain/memory";
import { LLMChain } from "langchain/chains";
import {
  SerpAPI,
  RequestsGetTool,
  RequestsPostTool,
  AIPluginTool,
} from "langchain/tools";
import { ChatMessageHistory } from "langchain/memory";
import { HumanChatMessage, AIChatMessage } from "langchain/schema";
import { ChainTool, Tool } from "langchain/tools";
import {
  initializeAgentExecutorWithOptions,
  ZeroShotAgent,
  ChatAgent,
  AgentExecutor,
} from "langchain/agents";
import { sendAnalyticsEvent } from "../utils/utility";
function ChatGPTClone() {
  document.title = "MyGPT";
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [authUrl, setAuthUrl] = useState("");
  const [keyAdded, setKeyAdded] = useState(null);
  const [user, setUser] = useState({ id: 0, image: "", name: "User" });
  const [question, setQuestion] = useState("");
  const [chat, setChat] = useState([]);
  const [key, setKey] = useState("");
  const [APIkey, setAPIKey] = useState("");
  const [model, setModel] = useState("gpt-3.5-turbo");
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const [showCopy, setShowCopy] = useState(0);
  const [sessId, setSessId] = useState(0);
  const [streamAns, setStreamAns] = useState("");
  const [showBanner, setShowBanner] = useState(false);
  const [promptData, setPromptData] = useState({});
  const [selectedPrompt, setSelectedPrompt] = useState("");
  const [promptToDisplay, setPromptToDisplay] = useState("");
  const [showPromptModal, setShowPromptModal] = useState(false);
  const [imgError, setImgError] = useState(false);
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const [showPricing, setShowPricing] = useState(false);
  const [stripeTier, setStripeTier] = useState("free");
  const [email, setEmail] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [stage, setStage] = useState(1);
  const keyLinks = {
    search: "https://serper.dev",
    wolfram: "https://products.wolframalpha.com/api/",
    zapier: "https://nla.zapier.com/demo/provider/debug",
  };
  const [keyFor, setKeyFor] = useState(null);
  const navigate = useNavigate();
  const chatRef = useRef(null);
  chatRef.current = chat;
  const sessionRef = useRef(null);
  sessionRef.current = sessId;
  const streamRef = useRef(null);
  streamRef.current = streamAns;
  let [searchParams, setSearchParams] = useSearchParams();
  const [isYearly, setIsYearly] = useState(true);
  const [plans, setPlans] = useState([
    {
      id: "monthly_5",
      name: "Starter",
      price: "$5/month",
      yearlyId: "yearly_50",
      yearlyPrice: "$50/year",
      features: [
        "All Chat Features",
        "GPT-3.5 powered",
        "Email support",
      ],
      recommended: false,
    },
    {
      id: "monthly_12",
      name: "Pro",
      price: "$12/month",
      yearlyId: "yearly_120",
      yearlyPrice: "$120/year",
      features: [
        "All Chat Features",
        "GPT-4 powered",
        "Priority support",
      ],
      recommended: true,
    },
  ]);

  const addKey = () => {
    axios
      .post("/store_key", { key: key })
      .then((res) => {
        setKeyAdded(true);
      })
      .catch((err) => {
        toast("Key cannot be verified, try again");
      });
  };

  let confirmPayment = () => {
        axios.get("/mygpt/verify_payment").then((res)=>{
                searchParams.delete('stripe_success')
                setSearchParams(searchParams)
            }).catch((err)=>{
            console.log(err)
        })
  }

  let checkoutStripe = (tier) => {
        sendAnalyticsEvent("upgradeyearly"+tier+"_click")

        axios.get("/mygpt/create_checkout_session?forPrice="+tier).then((res)=>{
            window.location.href = res.data
        }).catch((err)=>{
            console.log(err)
        })
  }

  function PricingModal({ show, onHide }) {
    return (
      <Modal centered show={show} onHide={onHide} size="lg" backdrop="static" keyboard={false} >
        <Modal.Header>
          <Modal.Title>Pricing Plans</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center mb-4">
          <div className="btn-group">
            <button
              type="button"
              className={`btn ${!isYearly ? 'btn-primary' : 'btn-outline-primary text-gray'}`}
              onClick={() => setIsYearly(false)}
            >
              Monthly
            </button>
            <button
              type="button"
              className={`btn ${isYearly ? 'btn-primary' : 'btn-outline-primary text-gray'}`}
              onClick={() => setIsYearly(true)}
            >
              Yearly
            </button>
          </div>
          </div>
          <div className="d-flex flex-column flex-md-row justify-content-around">
            {plans.map((plan) => (
              <div key={plan.id} className={`card text-center ${plan.recommended ? 'border-primary' : ''}`} style={{ width: '18rem', marginBottom: '1rem' }}>
                <div className="card-header">
                  <h5 className="card-title">{plan.name}</h5>
                </div>
                <div className="card-body">
                  <h6 className="card-price text-muted">{isYearly ? plan.yearlyPrice : plan.price}</h6>
                  <ul className="list-unstyled mt-3 mb-4">
                    {plan.features.map((feature, index) => (
                      <li key={index}>{feature}</li>
                    ))}
                  </ul>
                  <button onClick={() => checkoutStripe(isYearly ? `${plan.yearlyId}` : `${plan.id}`)} className={`btn ${plan.recommended ? 'btn-primary' : 'btn-outline-secondary'}`} >
                    {'Start free'}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    );
  }


  function KeyAddedModal({ show, onHide, key, setKey, addKey, isDarkTheme }) {
  return (
    <Modal
      className="key-modal"
      centered
      show={show}
      onHide={onHide}
    >
      <Modal.Body
        className="p-3 p-md-5 position-relative"
        style={{
          backgroundColor: isDarkTheme ? "#2b2d2f " : "white",
          color: isDarkTheme ? "white" : "black",
        }}
      >
        <b
          style={{ cursor: "pointer" }}
          onClick={onHide}
          className="position-absolute top-0 end-0 me-4 mt-2"
        >
          X
        </b>
        <Stack className="align-items-center" gap={3}>
          <h4>
            <b>Add your OpenAI Key</b>
          </h4>
          <p>
            <small>
              Get your OpenAI Key by signing up/logging in from the OpenAI
              Dashboard.{" "}
            </small>
            <a
              target="_blank"
              href="https://platform.openai.com/account/api-keys"
              rel="noopener noreferrer"
            >
              Go to Dashboard
            </a>
          </p>
          <InputGroup>
            <FormControl
              className="chat-input px-md-5 py-md-2 shadow-none"
              style={{ height: "48px" }}
              value={key}
              onChange={(e) => setKey(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") addKey();
              }}
            />
            <Button
              variant="outline-secondary"
              className="chat-button text-dark fw-bold ps-md-3 pe-md-5 py-md-2"
              onClick={addKey}
            >
              <SendIcon className="icon" />
            </Button>
          </InputGroup>
          <small>Watch this video to get started</small>
          <iframe
            className="key-video"
            src="https://www.youtube.com/embed/FaJdwbNWNkk"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Stack>
      </Modal.Body>
    </Modal>
  );
  }

  function BannerModal({ show, onHide }) {
  return (
    <Modal
      className="banner-modal"
      centered
      show={show}
      onHide={onHide}
    >
      <Modal.Body className="p-5 position-relative">
        <b
          style={{ cursor: "pointer" }}
          onClick={onHide}
          className="position-absolute top-0 end-0 me-4 mt-2"
        >
          X
        </b>
        <Stack className="align-items-center" gap={3}>
          <a
            href="https://www.producthunt.com/posts/chatgpt-plugins-5?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-chatgpt&#0045;plugins&#0045;5"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=386004&theme=light"
              alt="ChatGPT Plugins - Access to chatGPT plugins without ChatGPT Plus | Product Hunt"
            />
          </a>
          <a
            className="discord-invite px-3 py-3 ms-3"
            target="_blank"
            href="https://discord.gg/KZSUEjr4TM"
            rel="noopener noreferrer"
          >
            <DiscordIcon className="icon me-1" /> Join Our Discord
          </a>
          <a
            className="discord-invite px-3 py-3 ms-3"
            target="_blank"
            href="http://twitter.com/share?text=Free and faster ChatGPT pro with prompt support, Check out MyGPT by SamurAI&url=https://mygpt.thesamur.ai&hashtags=ai,gpt,chatgpt"
            rel="noopener noreferrer"
          >
            <TwitterIcon className="icon me-1" /> Share on Twitter
          </a>
          <a
            className="heybot-button px-3 py-3 ms-3"
            target="_blank"
            href="https://heybot.thesamur.ai"
            rel="noopener noreferrer"
          >
            <Image src={heybotLogo} className="icon me-1" /> Convert your website into a chatbot
          </a>
        </Stack>
      </Modal.Body>
    </Modal>
  );
  }

  function setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  let saveConvo = (getQuestion, output) => {
    let prompt = "";
    let promptName = "";
    if (selectedPrompt != "") {
      prompt = selectedPrompt.prompt;
      promptName = selectedPrompt.act;
    }
    axios
      .post("/mygpt/save", {
        input: getQuestion,
        output: output,
        sessId: sessionRef.current,
        userPrompt: prompt,
        userPromptName: promptName,
      })
      .then((res) => {
        setSessId(res.data.sessId);
        if (res.data.isNew != null) {
          setSessions([res.data.isNew, ...sessions]);
        }
       
      })
      .catch((err) => {
        toast("Failed to respond " + err.response.data);
      });
  };

  let askClone = async () => {
    if (question === "") {
      toast.error("Please enter valid input and try again.");
    } else {
      let getQuestion = question;
      setChat((chat) => [...chat, { id: 0, isBot: false, msg: question }]);
      setQuestion("");
      var bl = getCookie("googtrans");
      if (bl == null) {
        bl = "en";
      }
      var sl = document.querySelector("html").getAttribute("lang");
      if (sl != null) {
        bl = "/en/" + sl;
      }
      let prompt = "";
      let promptName = "";
      if (selectedPrompt != "") {
        prompt = selectedPrompt.prompt;
        promptName = selectedPrompt.act;
      }

      //const baseUrl = `http://localhost:9000/api/mygpt?question=${question}&sessId=${sessionRef.current}&userPrompt=${prompt}&userPromptName=${promptName}&browsing_language=${bl}`;
      const baseUrl = `https://embedai.vercel.app/api/mygpt?question=${question}&sessId=${sessionRef.current}&userPrompt=${prompt}&userPromptName=${promptName}&browsing_language=${bl}`;
      const eventSource = new EventSource(baseUrl);
      let answer = ""
      setChat((chat)=>[...chat,{id:0,isBot:true,msg:null}])
      eventSource.onmessage = function (event) {
        var getData = JSON.parse(event.data);

        if (getData.hasOwnProperty("text")) {
          var out = getData.text.replaceAll("--*", "\n");
          if (out != "STREAMEND") {
            setStreamAns(streamRef.current.concat(out))
            answer = answer + out
          } else {
            eventSource.close();
            chatRef.current.at(-1).msg=answer;
            saveConvo(getQuestion,answer)
            setStreamAns((prev)=>"")
          }
          
          
        } else {
          console.log("No text found in stream");
        }
      };
     
     

      eventSource.onerror = () => {
        console.log("closed");
        saveConvo(getQuestion,answer)
        eventSource.close();
      };
    }
  };

  let loadChat = (sessionId) => {
    setShowMenu(false);
    setQuestion("");
    localStorage.removeItem("chat");
    if (sessionId == 0) {
      setChat([]);
      setSelectedPrompt("");
      setSessId(0);
    } else {
      setLoading((prev) => true);
      axios
        .post("/mygpt/load_chat", { sessId: sessionId })
        .then((res) => {
          setChat(res.data.msgs);
          if (res.data.promptName !== "") {
            setSelectedPrompt({
              act: res.data.promptName,
              prompt: res.data.prompt,
            });
          } else {
            setSelectedPrompt("");
          }
          setLoading((prev) => false);
          setSessId(res.data.sessId);
        })
        .catch((err) => {
          toast("Could not load chat, try again");
        });
    }
  };
  let deleteChat = (sessionId) => {
    axios
      .post("/mygpt/delete_chat", { sessId: sessionId })
      .then((res) => {
        if (sessionRef.current == sessionId) {
          setSelectedPrompt("");
          setChat([]);
          setSessId(0);
        }
        const result = sessions.filter((session) => session.id !== sessionId);
        setSessions(result);
      })
      .catch((err) => {
        toast("Could not delete chat, try again");
      });
  };

  let searchChat = (search) => {
    axios
      .post("/mygpt/search_chat", { search: search })
      .then((res) => {
        setSessions(res.data);
      })
      .catch((err) => {
        toast("Could not find chat, try again");
      });
  };
  const logout = () => {
    axios
      .get("/heybot/logout")
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const showPlan = () => {
      if(stripeTier.includes("monthly")) {
          setIsYearly(false);
      } else {
          setIsYearly(true);
      }
      setPlans(plans => plans.map(plan => {
          if (plan.id === stripeTier || plan.yearlyId === stripeTier) {
              return { ...plan, recommended: true };
          } else {
              return { ...plan, recommended: false };
          }
      }));
      setShowPricing(true);
  };

  useEffect(() => {
    if (searchParams.get("addChat") != null) {
      localStorage.setItem("chat", searchParams.get("addChat"));
    }
    // console.log("bl", searchParams.get("bl"));
    if (localStorage.getItem("isDarkTheme") != null) {

      setIsDarkTheme(
        localStorage.getItem("isDarkTheme") == "false" ? false : true
      );
    }
    if (searchParams.get("bl") != null) {
      console.log(
        "getCookie",
        getCookie("googtrans"),
        getCookie("googtrans") != searchParams.get("bl")
      );
      if (getCookie("googtrans") == null) {
        setCookie("googtrans", searchParams.get("bl"), 1);
        setTimeout(function () {
          window.location.reload();
        }, 1000);
      } else if (getCookie("googtrans") != searchParams.get("bl")) {
        setCookie("googtrans", searchParams.get("bl"), 1);
      }
    }
    axios
      .get("/mygpt/isLoggedIn")
      .then((res) => {
        setIsLoggedIn(res.data.isLoggedIn);
        if (res.data.isLoggedIn == false) {
          setAuthUrl(res.data.auth_url);
        } else {
          setUser({
            id: res.data.userId,
            image: res.data.image,
            name: res.data.name,
          });
          if(res.data.stripe_tier=="free") {
            setShowPricing(true);
          }
          setStripeTier(res.data.stripe_tier);
          if(searchParams.get("stripe_success")){
            confirmPayment()
          }
          if (res.data.key_added == null) {
            setKeyAdded((prev) => false);
          } else {
            setKeyAdded((prev) => true);
            setKey(res.data.key_added);
          }
          setSessions(res.data.sessions);
          setPromptData({
            writing: JSON.parse(res.data.prompts.writing),
            popular: JSON.parse(res.data.prompts.popular),
            custom: JSON.parse(res.data.prompts.custom),
          });
          setModel(res.data.model);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    //window.setTimeout(()=>setShowBanner(true),30000)

    // promptSelect()
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?render=6Le2-JspAAAAAGRkEpA1HU-LsSpxFPsZ3rrM2_Hg';
    document.body.appendChild(script);
  }, []);

  const handleEmailSubmit = async () => {

    window.grecaptcha.ready(async () => {
      const token = await window.grecaptcha.execute("6Le2-JspAAAAAGRkEpA1HU-LsSpxFPsZ3rrM2_Hg", { action: 'submit' });
      var email = document.getElementById('email').value;
      setEmail(email);
      axios.post('/mygpt/verify_email', { email, token })
        .then(response => {
          setStage(2);
        })
        .catch(error => {
          toast('Email verification failed ' + error.response.data);
        });
    });
  };

  const handleVerificationSubmit = async () => {
    var code = document.getElementById('code').value;
    axios.post('/mygpt/verify_email_code', { email, code })
    .then(response => {
        window.location.href = "/mygpt";
    })
    .catch(error => {
        console.error('Verification error:', error.response.data);
        toast('Verification failed ' + error.response.data);
    });
  };

  useEffect(() => {
    if (isLoggedIn == true && keyAdded == true) {
      if (localStorage.getItem("plugin") != null) {
        //setKeyFor(localStorage.getItem('plugin'))
      }
      if (localStorage.getItem("chat") != null) {
        loadChat(localStorage.getItem("chat"));
      }
    }
  }, [isLoggedIn, keyAdded]);
  useEffect(() => {
    localStorage.setItem("isDarkTheme", isDarkTheme);
  }, [isDarkTheme]);

  useEffect(() => {
    if (document.querySelector(".end-chat") != null) {
      document
        .querySelector(".end-chat")
        .scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [chat]);

  const shareChat = () => {
    navigator.clipboard.writeText(
      window.location.host + "/mygpt/share?session=" + sessionRef.current
    );
    window.open("/mygpt/share?session=" + sessionRef.current, "_blank");
  };

  const copyMsg = (msg) => {
    console.log(`Coped Message is ${msg}`);
    navigator.clipboard.writeText(msg);
    toast("Copied");
  };

  const handleInputChanges = (e) => {
    setQuestion(e.target.value);
    e.target.style.height = "auto";
    e.target.style.height = e.target.scrollHeight + "px";
  };
  const handleImgError = () => {
    setImgError(true);
  };

  function SessionList() {
    return (
    <Stack gap={2} className="session-scroll mt-4">
      {sessions.length > 0 &&
        sessions.map((sess) => (
          <div key={sess.id} className="previous-sess w-100 d-flex justify-content-between align-items-center">
            <div>
              <ChatIcon className="mygpt-icon me-2" />
            </div>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => loadChat(sess.id)}
              className="text-truncate"
            >
              {sess.msg}
            </span>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => deleteChat(sess.id)}
              className="ms-auto"
            >
              <DeleteIcon className="mygpt-icon mygpt-icon-hover" />
            </div>
          </div>
        ))}
    </Stack>
    );
  }
   
  function UserMenu() {
    return (
        <Accordion flush className="mygpt-usermenu">
              <Accordion.Item eventKey="0">
                <Accordion.Header className="mygpt-usermenu">
                  <Image
                    src={imgError === false ? user.image : userImage}
                    onError={handleImgError}
                    className={
                      imgError == false
                        ? "mygpt-userimage me-2"
                        : "mygpt-userimage me-2 p-1"
                    }
                    style={{ filter: "brightness(0) invert(1) " }}
                  />
                  <span>{user.name}</span>
                </Accordion.Header>
                <Accordion.Body>
                  <div
                    className="d-flex theme-switch"
                    style={{ color: "white" }}
                  >
                    <LightThemeIcon className="mygpt-icon" />
                    <Form.Check
                      type="switch"
                      id="theme-switch"
                      className="ms-2 "
                      checked={isDarkTheme}
                      onChange={() => {
                        setIsDarkTheme(!isDarkTheme);
                      }}
                    />
                    <DarkThemeIcon className="mygpt-icon"></DarkThemeIcon>
                  </div>

                  <div style={{ cursor: "pointer", color: "white" }}>
                    <LogoutIcon className="mygpt-icon me-2" />
                    <span onClick={logout}>Logout</span>
                  </div>
                  <div style={{ cursor: "pointer", color: "white" }}>
                    <span onClick={() => showPlan()}>My Plan</span>
                  </div>
                  <div style={{ cursor: "pointer", color: "white" }}>
                    <span onClick={() => window.open('https://billing.stripe.com/p/login/bIY4k1apt6ksdTG3cc', '_blank')}>Manage subscription</span>
                  </div>
                  <div className="product-names mt-3">
                    <a className="me-1" href="https://gptagent.thesamur.ai/">
                      AutoGPT
                    </a>{" "}
                    |
                    <a className="me-1 ms-1" href="https://embedai.thesamur.ai/">
                      EmbedAI
                    </a>{" "}
                    |
                    <a
                      className="me-1 ms-1"
                      href="https://customgpt.thesamur.ai/"
                    >
                      CustomGPT
                    </a>{" "}
                    |
                    <a
                      className="me-1 ms-1"
                      href="https://gpt-auth.com/"
                    >
                      GPT Actions
                    </a>{" "}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
      )      
  } 

  function renderSideBar() {
    return (
    <Col className=" h-100 d-none d-xl-block side-bar-col">
          <Stack className="side-bar h-100  py-4 px-4 " gap={2}>
            <div id="google_translate_element"></div>
            <div className="d-flex justify-content-center">
              <h4 className="fw-bold ">
                <Image className="mygpt-logo p-2" src={logoImage} />
                MyGPT
              </h4>
            </div>

            <div className="mygpt-search-bg mt-3">
              <InputGroup className="">
                <InputGroup.Text className="border-0 bg-transparent">
                  <SearchIcon className="icon" />
                </InputGroup.Text>
                <FormControl
                  placeholder="Search Chats"
                  className="border-0 bg-transparent rounded-0 text-light"
                  onChange={(e) => searchChat(e.target.value)}
                  disabled={isLoggedIn ? false : true}
                />
              </InputGroup>
            </div>

            <SessionList />
            <Button onClick={() => loadChat(0)} className="mygpt-buttons ">
              <div className="d-flex justify-content-left">
                <AddIcon className="mygpt-icon ms-2"></AddIcon>{" "}
                <span className="ms-2">New Chat</span>
              </div>
            </Button>

            <UserMenu/>
          </Stack>
        </Col>
    )
  }

  function ChatHeader() {
    return (
      <div
        className="d-flex d-xl-none px-4 py-3 mb-3"
        style={{ color: isDarkTheme ? "white" : "black" }}
      >
        <h4 className="fw-bold">
          <Image className="mygpt-logo me-2" src={logoImage} />
          <span>MyGPT</span>
        </h4>
        <div className="ms-auto">
          <MenuIcon
            onClick={() => setShowMenu(true)}
            className="icon"
            style={{
              filter: isDarkTheme ? "brightness(0) invert(1)" : "",
              color: "black",
            }}
          />
        </div>
      </div>
    );
  }

  function ChatMessage({msg}) {
    return (
    <>
      <div
        onMouseOver={() => setShowCopy(msg.id)}
        onClick={() => setShowCopy(msg.id)}
        className={
          msg.isBot
            ? isDarkTheme
              ? "d-flex gpt-msg-dark p-4 text-start w-100 position-relative"
              : "d-flex gpt-msg p-4 text-start w-100 position-relative"
            : isDarkTheme
            ? "d-flex p-4 rounded text-start w-100 position-relative user-msg-dark"
            : "d-flex p-4 rounded text-start w-100 position-relative user-msg"
        }
      >
        <Image
          className="profile-img rounded-circle me-3"
          src={msg.isBot ? logoImage : user.image}
        />
        <div className="w-100">
          {msg.steps &&
            msg.steps.length > 0 &&
            msg.steps.map((step) => (
              <div
                className="py-2 px-3 mb-3   steps-bg "
                gap={2}
              >
                <small className="text-dark">
                  <b>Plugin Used:</b> {step}
                </small>
              </div>
            ))}
          <div>
            {msg.isBot ? (
              <>
                {msg.msg == null ? (
                  <div>
                  <ReactMarkdown
                    children={streamAns}
                    remarkPlugins={[remarkGfm]}
                  />
                    {<span class="blinking-cursor-mygpt">█</span>}
                  </div>
                ) : (
                  <ReactMarkdown
                    children={msg.msg}
                    remarkPlugins={[remarkGfm]}
                  />
                )}
              </>
            ) : (
              msg.msg
            )}
          </div>
          {msg.citations && msg.citations.length > 0 && (
            <div className="d-flex gap-2 flex-wrap">
              {msg.citations.map((link) => (
                <span className="citations-bg py-2 px-3 text-truncate text-dark">
                  <a target="_blank" href={link}>
                    {link}
                  </a>
                </span>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="d-flex justify-content-end align-item-center">
        {showCopy == msg.id && (
          <div
            className={
              isDarkTheme
                ? "share-btn-dark justify-content-center"
                : "share-btn justify-content-center"
            }
          >
            <CopyIcon
              onClick={() => copyMsg(msg.msg)}
              className=" small-icon me-1"
            />
            Copy
          </div>
        )}
      </div>
    </>
    )
  }

  function NoMessagesPlaceHolder() {
    return (
      <Stack
        className="align-items-center ms-sm-5 ps-sm-3"
        gap={1}
        style={{ color: isDarkTheme ? "white" : "black" }}
      >
        {/*<Image className="mygpt-logo " src={logoImage}/>*/}
        <h1 className="mt-sm-5">
          <small>MyGPT</small>
        </h1>
        <h3 style={{ color: "gray" }}>
          Alternative for ChatGPT
        </h3>
        <Row className="mt-sm-3 ms-sm-4 ms-5 ps-4 ps-md-3 me-1 me-sm-0">
          <Col className="text-start d-flex flex-column gap-2 text-nowrap mb-2">
            <div>
              <CheckIcon
                className="mygpt-icon me-2"
                style={{ color: "#2BAE66" }}
              />
              Cheaper to use
            </div>
            <div>
              <CheckIcon
                className="mygpt-icon me-2"
                style={{ color: "#2BAE66" }}
              />
              Share your chats
            </div>
            <div>
              <CheckIcon
                className="mygpt-icon me-2"
                style={{ color: "#2BAE66" }}
              />
              Prompt Library
            </div>
          </Col>
          <Col className="text-start d-flex flex-column gap-2 text-nowrap ms-xl-4">
            <div>
              <CheckIcon
                className="mygpt-icon me-2 "
                style={{ color: "#2BAE66" }}
              />
              Plugin support
            </div>
            <div>
              <CheckIcon
                className="mygpt-icon me-2"
                style={{ color: "#2BAE66" }}
              />
              Manage sessions
            </div>
            <div>
              <CheckIcon
                className="mygpt-icon me-2"
                style={{ color: "#2BAE66" }}
              />
              Chat History and Search
            </div>
          </Col>
        </Row>
        <div>
          {key == null && key === "" ? (
            <Button
              className="mt-4 mt-md-5 mygpt-enter-key-btn text-center"
              style={{ width: "200px", textAlign: "center" }}
              onClick={() => {
                setKeyAdded(false);
              }}
            >
              Enter OpenAI Key
            </Button>
          ) : (
            ""
          )}
        </div>
      </Stack>
    )
  }

  function LoginPlaceholder() {
    return (
    <>
      <Container style={{ height: "100%" }}>
        <Row
          className="justify-content-center mt-5 pt-5"
          style={{ color: isDarkTheme ? "white" : "black" }}
        >
          <Col>
            <Image className="mygpt-logo p-1" src={logoImage} />
            <h1 className="mt-sm-3">
              <small>MyGPT by SamurAI</small>
            </h1>
            <h3 style={{ color: "gray" }}>
              A better UI for ChatGPT
            </h3>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col className="text-center">
          <Button variant="primary" className="mygpt-google-btn" href={authUrl}>
            <GoogleIcon className="icon me-2" /> Login with Google
          </Button>

          <h6 className="mt-3 mb-4 font-weight-bold">
            {stage === 1 ? "Login with email" : "Enter the verification code"}
          </h6>
          <div className="d-flex flex-column align-items-center">
            {stage === 1 && (
              <input
                type="email"
                placeholder="Email"
                id="email"
                className="form-control mb-4 w-50"
              />
            )}
            {stage === 2 && (
              <input
                type="text"
                placeholder="Verification Code"
                id="code"
                className="form-control mb-4 w-50"
              />
            )}
            <button
              className="btn btn-primary mb-6 px-6 py-3"
              onClick={() => {
                stage === 1 ? handleEmailSubmit() : handleVerificationSubmit();
              }}
            >
              {stage === 1 ? "Send Verification Code" : "Verify Code"}
            </button>
          </div>
        </Col>
      </Row>
      <Row className="mt-5 ">
        <Col>
           <div
             style={{
               cursor: "pointer",
               opacity: "0.8",
               color: isDarkTheme ? "white" : "black",
             }}
             className="mt-auto align-self-center pt-4"
             onClick={() => navigate("/mygpt/faq")}
           >
             <QuestionIcon
               className="icon"
               style={{
                 filter: isDarkTheme
                   ? "brightness(0) invert(1) "
                   : "",
               }}
             />{" "}
             <b>Know More</b>
           </div>
        </Col>
      </Row>
      </Container>
    </>
    )
  }

  function renderChatMessages() {
    return (
      <Col
          className="main-chat-col ms-4 ms-sm-0"
          style={{
            background: isDarkTheme ? "#232627" : "white",
            height: "95%",
            borderRadius: "15px",
            marginTop: "20px",
            marginRight: "20px",
          }}
        >
          <ChatHeader/>

          {isLoggedIn != null && (
            <Stack
              className="text-center mx-2 main-chat px-md-5 py-sm-4 align-items-center justify-content-center"
              gap={3}
            >
              {isLoggedIn ? (
                <>
                  <div
                    className="d-flex justify-content-between mb-sm--3 "
                    style={{ width: "100%" }}
                  >
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{ width: "100%" }}
                    >
                    </div>
                    <div>
                      {sessionRef.current != 0 && (
                        <div
                          className={
                            isDarkTheme
                              ? "d-flex align-self-end share-btn-dark"
                              : "d-flex align-self-end share-btn"
                          }
                          onClick={shareChat}
                        >
                          {isDarkTheme ? (
                            <ShareWhiteIcon className="small-icon me-1" />
                          ) : (
                            <ShareIcon className="small-icon me-1" />
                          )}
                          Share
                        </div>
                      )}
                    </div>
                  </div>
                  <Stack gap={2} className="chat-scroll msg-scroll">
                    {chat.length > 0 ? (
                      chat.map((msg) => (
                        <ChatMessage msg={msg}/>
                      ))
                    ) : (
                      <NoMessagesPlaceHolder/>
                    )}
                    {loading && (
                      <Stack
                        className="ms-3 loading"
                        direction="horizontal"
                        gap={2}
                      >
                        <Spinner size="sm" animation="grow" />
                        <Spinner size="sm" animation="grow" />
                        <Spinner size="sm" animation="grow" />
                      </Stack>
                    )}

                    <div className="end-chat"></div>
                  </Stack>
                  <div className="d-flex" style={{ width: "100%" }}>
                    <Stack
                      className=" pill-scroll mb-sm-3 mb-md-0"
                      direction="horizontal"
                      gap={2}
                    >
                      {selectedPrompt != "" && (
                        <div
                          className="me-3  px-md-3 "
                          style={{
                            border: "1px solid #999999",
                            borderRadius: "15px",
                            color: isDarkTheme ? "white" : "black",
                          }}
                        >
                          <small>selected Prompt: {selectedPrompt.act}</small>
                          <b
                            style={{ cursor: "pointer" }}
                            onClick={() => setSelectedPrompt("")}
                            className="ms-2"
                          >
                            x
                          </b>
                        </div>
                      )}
                      <div
                        onClick={(e) => setShowPromptModal(true)}
                        className="me-3 function-btn"
                        style={{ backgroundColor: "#2BAE66", color: "white" }}
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <PromptIcon className="mygpt-icon p-1" />
                          <small className="text-nowrap">Add Prompt</small>
                        </div>
                      </div>
                    </Stack>
                  </div>
                  <div
                    className={
                      isDarkTheme
                        ? "d-flex justify-content-center mygpt-chat-input-container-dark mb-5 mb-sm-0"
                        : "d-flex justify-content-center mygpt-chat-input-container mb-5 mb-sm-0"
                    }
                  >
                    <FormControl
                      rows={1}
                      className={
                        isDarkTheme
                          ? "mygpt-chat-input-dark p-2"
                          : "mygpt-chat-input p-2"
                      }
                      value={question}
                      onChange={(e) => handleInputChanges(e)}
                      onKeyDown={(e) => {
                        e.code == "Enter" && !e.shiftKey && askClone();
                      }}
                      as="textarea"
                    />

                    <div
                      className="d-flex flex-column mx-2 mb-4 justify-content-end"
                      style={{
                        height: "100%",
                        cursor: "pointer",
                        color: question === "" ? "#4e4e4e" : "#ffbb00",
                      }}
                      onClick={askClone}
                    >
                      <SendIcon className="mygpt-icon" />
                    </div>
                  </div>
                </>
              ) : (
                <LoginPlaceholder/>
              )}
            </Stack>
          )}
        </Col>
    )
  } 

  function renderOffCanvas() {
    return (
          <Offcanvas
        className="side-bar"
        show={showMenu}
        onHide={() => setShowMenu(false)}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>MyGPT</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Stack className="h-100  text-center" gap={2}>
            <Stack gap={3} className="session-scroll">
              <Button onClick={() => loadChat(0)} className="mygpt-buttons">
                <div className="d-flex justify-content-center">
                  <AddIcon className="mygpt-icon"></AddIcon>
                  <span className="ms-2">New Chat</span>
                </div>
              </Button>
              <div className="mygpt-search-bg mt-3">
                <InputGroup className="">
                  <InputGroup.Text className="border-0 bg-transparent">
                    <SearchIcon className="icon" />
                  </InputGroup.Text>
                  <FormControl
                    placeholder="Search Chats"
                    className="border-0 bg-transparent rounded-0 text-light"
                    onChange={(e) => searchChat(e.target.value)}
                    disabled={isLoggedIn ? false : true}
                  />
                </InputGroup>
              </div>

              {sessions.length > 0 &&
                sessions.map((sess) => (
                  <div className="previous-sess w-100  d-flex justify-content-between align-items-center">
                    <div>
                      <ChatIcon className="mygpt-icon me-2" />
                    </div>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => loadChat(sess.id)}
                      className="text-truncate"
                    >
                      {sess.msg}
                    </span>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => deleteChat(sess.id)}
                      className="ms-auto"
                    >
                      <DeleteIcon className="mygpt-icon mygpt-icon-hover" />
                    </div>
                  </div>
                ))}
            </Stack>
            <div
              className="d-flex w-100 align-item-center justify-content-center theme-switch"
              style={{ color: "white" }}
            >
              <LightThemeIcon className="mygpt-icon" />
              <Form.Check
                type="switch"
                id="theme-switch"
                className="ms-2 "
                checked={isDarkTheme}
                onChange={() => {
                  setIsDarkTheme(!isDarkTheme);
                  localStorage.setItem("isDarkTheme", isDarkTheme);
                }}
              />
              <DarkThemeIcon className="mygpt-icon"></DarkThemeIcon>
            </div>

            <span style={{ cursor: "pointer" }} onClick={logout}>
              Logout
            </span>
          </Stack>
        </Offcanvas.Body>
      </Offcanvas>
    )
  }

  return (
    <>
      <Row
        className="vh-100 overflow-hidden  g-0"
        style={{ background: "#141718" }}
      >
        
        {renderSideBar()}
        {renderChatMessages()}
      </Row>
      <renderOffCanvas/>
      <ToastContainer />

      <BannerModal
        show={showBanner}
        onHide={() => setShowBanner(false)}
      />
      {/*
      <KeyAddedModal
        show={keyAdded == false}
        onHide={() => setKeyAdded(null)}
        key={key}
        setKey={setKey}
        addKey={addKey}
        isDarkTheme={isDarkTheme}
      />
      */}
      <PricingModal show={showPricing} onHide={() => setShowPricing(false)} />
      {promptData != {} && Object.keys(promptData).length !== 0 && (
        <PromptModal
          data={promptData}
          setSelectedPrompt={setSelectedPrompt}
          setShowModal={setShowPromptModal}
          showModal={showPromptModal}
          setQuestion={setQuestion}
          darkTheme={isDarkTheme}
        ></PromptModal>
      )}
    </>
  );
}

export default ChatGPTClone;
