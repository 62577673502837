import {Image,Stack,Button} from 'react-bootstrap'
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import { ReactComponent as DiscordIcon } from "../assets/discord.svg";
import logoGraphic from '../assets/logo-graphic.png'
import { ReactComponent as QuestionIcon } from "../assets/question-mark.svg";

function LoginSignup({authUrl}) {
    const [isSignup, setIsSignup] = useState(false)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [name, setName] = useState("")
    const navigate  = useNavigate();
    // const login = () => {
    //     if(isSignup){
    //         axios.post("/signup",{name:name,email:email,password:password}).then((res)=>{
    //             setIsSignup(false)
    //        }).catch((err)=>{
    //         console.log(err)
    //        })
    //     }else{
    //     axios.post("/login",{email:email,password:password}).then((res)=>{
    //         navigate('/chat')
    //        }).catch((err)=>{
    //         console.log(err)
    //        })
    //     }
    // }
    return (
        <div class="chat-background d-flex justify-content-center">
            <div className="chat-container">
                <Stack className=" mx-2 mx-md-5 px-md-5   vh-100 justify-content-center pb-5 text-center" gap={4}>
                    <Image style={{paddingTop:'15rem'}} className="logo-graphic align-self-center" src={logoGraphic} />
                    <div>
                        <h3 className="m-0">Welcome to SamurAI</h3>
                        <h6 className="m-0">Login with your Discord to continue</h6>
                    </div>
                    {/* {isSignup&&<FormControl className="login-input px-md-5 py-md-3 shadow-none" placeholder="Name" onChange={(e)=>setName(e.target.value)}/>}
                    <FormControl className="login-input px-md-5 py-md-3 shadow-none" type="email" placeholder="Email" onChange={(e)=>setEmail(e.target.value)}/>
                    <FormControl className="login-input px-md-5 py-md-3 shadow-none" type="password" placeholder="Password" onChange={(e)=>setPassword(e.target.value)}/>
                    <Button className="option-buttons align-self-center" onClick={login}>{isSignup?"Signup":"Login"}</Button>
                    <small>Or <b className="register-link" onClick={()=>setIsSignup((prev)=>!prev)}>{!isSignup?"Signup":"Login"}</b></small> */}
                    <Button className="option-buttons align-self-center" href={authUrl}><DiscordIcon className="icon me-2 pb-1"/> Login with Discord</Button>
                    <Stack direction="horizontal" gap={2} style={{cursor:'pointer',opacity:'0.7'}} className="mt-auto align-self-center" onClick={()=>navigate('/faq')}><QuestionIcon className="icon"/> <b>Know More</b></Stack>
                </Stack>
            </div>
        </div>

    )
}

export default LoginSignup