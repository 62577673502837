import React,{useState,useEffect} from 'react'
import { Row, Col, Button, Image, Form, Modal, Stack } from "react-bootstrap"
import {ReactComponent as SearchIcon} from '../../assets/search-plugin.svg'
import {ReactComponent as CodeIcon} from '../../assets/code-plugin.svg'
import {ReactComponent as WolframIcon} from '../../assets/wolfram.svg'
import {ReactComponent as KlarnaIcon} from '../../assets/klarna.svg'
import {ReactComponent as ZapierIcon} from '../../assets/zapier.svg'
import {ReactComponent as SettingsIcon} from '../../assets/edit.svg'
import miloIcon from '../../assets/milo.png'
import speakIcon from '../../assets/speak.jpg'
import shopIcon from '../../assets/shop.jpg'
import axios from "axios"

function PluginMenu({isLoggedIn,plugins,customPlugins,handleAddPlugin,removePlugin}) {
    const [key,setKey] = useState("")
    const [keyFor,setKeyFor] = useState(null)
    const keyLinks = {search:"https://serpapi.com/dashboard",wolfram:"https://products.wolframalpha.com/api/",zapier:"https://nla.zapier.com/demo/provider/debug"}
  

  return (
    <Row className="g-0 m-auto  justify-content-center">
        {keyFor!=null?
        <Stack className="align-items-center" gap={3}>
            <Form.Label>Enter your <a target="_blank" href={keyLinks[keyFor]}>{keyFor}</a> API key</Form.Label>
            <Form.Control onChange={(e)=>setKey(e.target.value)}/>
            <Button onClick={()=>handleAddPlugin(keyFor,0)}>Save</Button>
        </Stack>:
        <>  
            {customPlugins.length>0&&customPlugins.map((plugin)=>
            <Col md={6} className="d-flex flex-column p-2 gap-3">
                <div class="d-flex flex-column justify-content-between plugin-btn  h-100">
                    <div class="d-flex w-100 gap-2 mb-1 align-items-center px-4 pt-2">
                        <h5 className='mt-2'><b>{plugin.name}</b></h5>
                       
                    </div>
                    <p className="px-4 my-3" style={{fontSize:"14px"}}>
                    Your custom plugin
                    </p>
                    <div className='d-flex  justify-content-center'>
                    <Button className='mygpt-buttons px-5 mb-2' onClick={()=>removePlugin("custom: "+plugin.name)}>Remove</Button>
                    </div>
                </div>
            </Col>
            )}
             <Col md={6} className="d-flex flex-column p-2 gap-3">
             <div class=" d-flex flex-column justify-content-between plugin-btn  h-100">
                    <div class="d-flex w-100 gap-2 mb-1 align-items-center px-4 pt-2">
                        <SettingsIcon className="plugin-icon p-1"/>
                       
                            <h5 className='mt-2'><b>Add Custom</b></h5>
                            
                       
                    </div>
                    <p className="px-4 my-3" style={{fontSize:"14px"}}>
                    Add a custom plugin url to integrate into MyGPT
                    </p>
                    <div className='d-flex  justify-content-center'>
                        <Button className='mygpt-buttons px-5 mb-2' onClick={()=>handleAddPlugin('custom',1)}>Install</Button>                    
                    </div>
                </div>
            </Col>
            <Col md={6} className="d-flex flex-column p-2 gap-3">
                <div class=" d-flex flex-column justify-content-between plugin-btn  h-100">
                    <div class="d-flex w-100 gap-2 mb-1 align-items-center px-4 pt-2">
                        <CodeIcon className="plugin-icon"/>
                       
                            <h5 className='mt-2'><b>Code Interpreter</b></h5>
                        
                    </div>
                    <p className=" px-4 my-3" style={{fontSize:"14px"}}>
                        Helps write and execute python code. Just ask any math related or python related question to use this plugin.
                    </p>
                    <div className='d-flex  justify-content-center'>
                        <Button className='mygpt-buttons px-5 mb-2' onClick={()=>(plugins!=[]&&plugins.includes('code'))?removePlugin('code'):handleAddPlugin('code',0)}>{(plugins!=[]&&plugins.includes('code'))?"Remove":"Install"}</Button>

                        </div>
                </div>
            </Col>
            <Col md={6} className="d-flex flex-column p-2 gap-3">
                <div class="d-flex flex-column justify-content-between plugin-btn h-100">
                    <div class="d-flex w-100 gap-2 mb-1 align-items-center px-4 pt-2">
                        <SearchIcon className="plugin-icon"/>
                        <div>
                        <h5 className='mt-2'><b>Google Search</b></h5>
                            
                        </div>
                    </div>
                    <p className=" px-4 my-3" style={{fontSize:"14px"}}>
                        Searches Google for questions asked that are currently relevant. Ask for current news, temperature and events to use this plugin.
                    </p>
                    <div className="d-flex justify-content-center">
                                <Button className='mygpt-buttons px-5 mb-2' onClick={()=>(plugins!=[]&&plugins.includes('search'))?removePlugin('search'):handleAddPlugin('search',0)}>{(plugins!=[]&&plugins.includes('search'))?"Remove":"Install"}</Button>
								{/*{isLoggedIn&&(plugins!=[]&&plugins.includes('search'))&&<Button className="ms-2" onClick={()=>setKeyFor('search')}>Edit Key</Button>}*/}								
                            </div>
                            </div>
            </Col>
            <Col md={6} className="d-flex flex-column p-2 gap-3">
                <div class="d-flex flex-column justify-content-between plugin-btn h-100">
                    <div class="d-flex w-100 gap-2 mb-1 align-items-center px-4 pt-2">
                        <WolframIcon className="plugin-icon"/>
                        <div>
                            <h5 className='mt-2'><b>Wolfram Alpha</b></h5>
                            
                        </div>
                    </div>
                    <p className=" px-4 my-3" style={{fontSize:"14px"}}>
                    Helps answer complex algebraic and other similar math related questions. Ask to solve any algebra problem like finding the value of x, to use this plugin.
                    </p>
                    <div className="d-flex justify-content-center">
                                <Button className='mygpt-buttons px-5 mb-2' onClick={()=>(plugins!=[]&&plugins.includes('wolfram'))?removePlugin('wolfram'):handleAddPlugin('wolfram',0)}>{(plugins!=[]&&plugins.includes('wolfram'))?"Remove":"Install"}</Button>
                                {/*isLoggedIn&&(plugins!=[]&&plugins.includes('wolfram'))&&<Button className="ms-2" onClick={()=>setKeyFor('wolfram')}>Edit Key</Button>*/}
                            </div>
                </div>
            </Col>
            <Col md={6} className="d-flex flex-column p-2 gap-3">
                <div class="d-flex flex-column justify-content-between plugin-btn h-100">
                    <div class="d-flex w-100 gap-2 mb-1 align-items-center px-4 pt-2">
                        <KlarnaIcon className="plugin-icon"/>
                        <div>
                            <h5 className='mt-2'><b>Klarna Shopping</b></h5>
                        </div>
                    </div>
                    <p className=" px-4 my-3" style={{fontSize:"14px"}}>
                        Searches Klarna for relevant products. Ask for any top products or specific products in Klarna to use this plugin.
                    </p>
                    <div className="d-flex justify-content-center">
                    <Button className='mygpt-buttons mygpt-buttons px-5 mb-2'  onClick={()=>(plugins!=[]&&plugins.includes('klarna'))?removePlugin('klarna'):handleAddPlugin('klarna',0)}>{(plugins!=[]&&plugins.includes('klarna'))?"Remove":"Install"}</Button>

                    </div>
                </div>
            </Col>
            <Col md={6} className="d-flex flex-column p-2 gap-3">
                <div class="d-flex flex-column justify-content-between plugin-btn h-100">
                    <div class="d-flex w-100 gap-2 mb-1 align-items-center px-4 pt-2">
                        <Image src={miloIcon} className="plugin-icon"/>
                        <div>
                            <h5 className='mt-2'><b>Milo Family AI</b></h5>
                        </div>
                    </div>
                    <p className="px-4 my-3" style={{fontSize:"14px"}}>
                    Giving parents superpowers to turn the manic to magic, 20 minutes each day. Ask: Hey Milo, what’s magic today?
                    </p>
                    <div className="d-flex justify-content-center">
                    <Button className='mygpt-buttons mygpt-buttons px-5 mb-2' onClick={()=>(plugins!=[]&&plugins.includes('milo'))?removePlugin('milo'):handleAddPlugin('milo',0)}>{(plugins!=[]&&plugins.includes('milo'))?"Remove":"Install"}</Button>

                    </div>
                </div>
            </Col>
            <Col md={6} className="d-flex flex-column p-2 gap-3">
                <div class="d-flex flex-column justify-content-between plugin-btn h-100">
                    <div class="d-flex w-100 gap-2 mb-1 align-items-center px-4 pt-2">
                        <Image src={shopIcon} className="plugin-icon"/>
                        <div>
                            <h5 className='mt-2'><b>Shop</b></h5>
                        </div>
                    </div>
                    <p className="px-4 my-3" style={{fontSize:"14px"}}>
                    Search for millions of products from the world’s greatest brands.
                    </p>
                    <div className="d-flex justify-content-center">
                    <Button className='mygpt-buttons mygpt-buttons px-5 mb-2' onClick={()=>(plugins!=[]&&plugins.includes('shop'))?handleAddPlugin('shop',0):removePlugin('shop')}>{(plugins!=[]&&plugins.includes('shop'))?"Remove":"Install"}</Button>

                    </div>
                </div>
            </Col>
            <Col md={6} className="d-flex flex-column p-2 gap-3">
                <div class="d-flex flex-column justify-content-between plugin-btn h-100">
                    <div class="d-flex w-100 gap-2 mb-1 align-items-center px-4 pt-2">
                        <ZapierIcon className="plugin-icon"/>
                        <div>
                            <h5 className='mt-2'><b>Zapier</b></h5>
                        </div>
                    </div>
                    <p className="px-4 my-3" style={{fontSize:"14px"}}>
                    Interact with over 5,000+ apps like Google Sheets, Trello, Gmail, HubSpot, Salesforce, and more.
                    </p>
                    <div className="d-flex justify-content-center">
                    <Button className='mygpt-buttons mygpt-buttons px-5 mb-2' onClick={()=>(plugins!=[]&&plugins.includes('zapier'))?removePlugin('zapier'):handleAddPlugin('zapier',0)}>{(plugins!=[]&&plugins.includes('zapier'))?"Remove":"Install"}</Button>

                    </div>
                </div>
            </Col>
            
            <Col md={6} className="d-flex flex-column p-2 gap-3">
                <div class="d-flex flex-column justify-content-between plugin-btn h-100">
                    <div class="d-flex w-100 gap-2 mb-1 align-items-center px-4 pt-2">
                        <Image src={speakIcon} className="plugin-icon"/>
                        <div>
                            <h5 className='mt-2'><b>Speak</b></h5>
                        </div>
                    </div>
                    <p className="px-4 my-3" style={{fontSize:"14px"}}>
                    Learn how to say anything in another language with Speak, your AI-powered language tutor.
                    </p>
                    <div className="d-flex justify-content-center">
                    <Button className='mygpt-buttons mygpt-buttons px-5 mb-2' onClick={()=>(plugins!=[]&&plugins.includes('speak'))?removePlugin('speak'):handleAddPlugin('speak',0)}>{(plugins!=[]&&plugins.includes('speak'))?"Remove":"Install"}</Button>

                    </div>
                </div>
            </Col>
            
			{/*
            <Col md={6} className="d-flex flex-column p-2 gap-3">
                <div class="plugin-btn p-4 h-100">
                    <div class="d-flex w-100 gap-4 mb-3">
                        <ZapierIcon className="plugin-icon"/>
                        <div>
                            <h5><b>Zapier</b></h5>
                            <div className="d-flex">
                                <Button onClick={()=>handleAddPlugin('zapier',1)}>{(plugins!=[]&&plugins.includes('zapier'))?"Remove":"Install"}</Button>
                                {isLoggedIn&&(plugins!=[]&&plugins.includes('zapier'))&&<Button className="ms-2" onClick={()=>setKeyFor('zapier')}>Edit Key</Button>}
                            </div>
                        </div>
                    </div>
                    <p className="m-0">
                        Access your Zapier automations. Ask to summarize mails, send messages to slack and more to use this plugin.
                    </p>
                </div>
            </Col>
			*/}
        </>}
    </Row>
  )
}

export default PluginMenu
