import { ReactComponent as GoogleIcon } from "../assets/google-logo.svg";
import { ReactComponent as GithubIcon } from "../assets/github.svg";
import logoImage from '../assets/embed-logo.png'
import {Image, Row, Col, Button, Stack} from 'react-bootstrap'
import { useState,useEffect,useRef } from "react";
import axios from "axios";
import { sendAnalyticsEvent } from "../utils/utility";
import { ToastContainer, toast } from 'react-toastify';

function EmbedAIWelcome(){
    document.title = "Welcome to EmbedAI"

    const handleGithubClick = ()=>{
        sendAnalyticsEvent("githublogin_click")
        window.location.href="https://github.com/login/oauth/authorize?client_id=3244e9eda74486fe30a3&scope=read:user,user:email"
    }

    const handleGoogleClick = ()=>{
        sendAnalyticsEvent("googlelogin_click")
        window.location.href="https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=823974908486-c3t68l3rpsmj0r2408poe4947k6glr06.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fembedai.thesamur.ai%2Fheybotnew%2Fgoogle_callback&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile+openid&state=uexJZOIUvvrcgbQKaS4BvkfGhMfUGX"
    }

    function login() {
        const email = document.querySelector('#email').value;
        const password = document.querySelector('#password').value;

        // Create an object with the credentials
        const credentials = {
            email: email,
            password: password,
        };

        // Send a POST request to the server
        axios.post('/login', credentials)
        .then((response) => {
          // Handle a successful login response (e.g., redirect or show a success message)
          console.log('Login successful', response.data);
          window.location.href = "/app";
          // You can perform actions like redirecting the user to a dashboard here.
        })
        .catch((error) => {
          // Handle login error (e.g., display an error message)
          toast.error(error.response.data);
          console.error('Login failed', error);
          // You can display an error message to the user or perform other error handling actions.
        });
    }


    return(
        <div className="heybotnew-bg  p-0 p-lg-3 ">
            
            <div className="heybotnew-box posittion-relative d-flex flex-column heybotnew-theme-light w-100 h-100 p-3 p-lg-5" >
                <div className="d-flex justify-content-end">
                    <Stack direction="horizontal" gap={3}>
                        <a href="https://www.thesamur.ai/terms-conditions" target="_blank" rel="noreferrer" className="terms-privacy-link">Terms & Conditions</a>
                        <a href="https://www.thesamur.ai/privacy" target="_blank" className="terms-privacy-link" rel="noreferrer">Privacy Policy</a>
                    </Stack>
                </div>
                <div className="d-flex flex-wrap justify-content-between">
                    <div class="d-flex align-items-center gap-2">
                        <Image className="heybotnew-welcome-logo" src={logoImage}/>
                        <h3 className="heybotnew-welcome-title">EmbedAI</h3>
                    </div>
                </div>
                <div  className="d-flex flex-column align-items-center my-5">
                    <h4 className="py-4">Create your custom chatgpt bot</h4>

                    <div style={{ "maxWidth": '500px' }} className="mb-3">
                        <input type="text" className="form-control" id="email" placeholder="Email" />
                    </div>
                    <div style={{ "maxWidth": '500px' }} className="mb-3">
                        <input type="password" className="form-control" id="password" placeholder="Password" />
                    </div>

                    <div
                        style={{ "maxWidth": '500px' }}
                        className="heybotnew-primary-btn d-flex align-items-center justify-content-center text-nowrap py-4 mb-3"
                        onClick={() => login()}
                    >
                    Login
                    </div>
                    <hr style={{"max-width": '500px'}} className=" m-2 w-100"/>
                    <div style={{"max-width": '500px'}} className="heybotnew-primary-btn d-flex align-items-center justify-content-center text-nowrap py-4" onClick={()=>handleGoogleClick()}>
                        <GoogleIcon className="icon me-2 p-1 bg-light rounded-circle" /> Login with
                        Google
                    </div>
                    <hr style={{"max-width": '500px'}} className=" m-2 w-100"/>
                    <div style={{"max-width": '500px'}} className="heybotnew-primary-btn d-flex align-items-center justify-content-center text-nowrap py-4" onClick={()=>handleGithubClick()}>
                        <GithubIcon className="icon me-2 p-1 bg-light rounded-circle" /> Login with
                        Github
                    </div>
                    <div className="mt-3">
                        <p>Don't have an account? <a href="/signup">Sign up</a></p>
                    </div>
                    <div className="mt-3">
                        <p>Forgot Password? <a href="/forgot_password">Reset</a></p>
                    </div>
                </div>
            </div>
            <ToastContainer/>
        </div>
    )
}
export default EmbedAIWelcome
