import { createSlice } from "@reduxjs/toolkit";

const state = () => {
    const initialState = {
      filters:[]
    };
  
    return initialState;
  };
  
  export const membersSlice = createSlice({
    name: "membersSlice",
    initialState: state,
    reducers: {
      addFilter: (state, action) => {
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
        state.filters = action.payload;
      }
    },
  });
  
  // Action creators are generated for each case reducer function
  export const {
    addFilter
  } = membersSlice.actions;
  
  export default membersSlice.reducer;
  