import {ListGroup,Stack,FormControl,InputGroup,Button, Image} from 'react-bootstrap'
import { ReactComponent as LogoutIcon } from "../assets/logout.svg";
import { ReactComponent as SendIcon } from "../assets/send.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import '../styles/chat.css'
import { useState,useRef, useEffect } from "react";
import logo from '../assets/logo.png'
import logoGraphic from '../assets/logo-graphic.png'
import userGraphic from '../assets/user.png'

function Chatbot({userId}) {
  const navigate = useNavigate()
  const [messages, setMessages] = useState([])
  const [suggestions, setSuggestions] = useState([])
  const [hasKey, setHasKey] = useState(false)
  const [forKnowledge, setForKnowledge] = useState(false)
  const [typing, setTyping] = useState(false)
  const [server, setServer] = useState(null)
  const [username, setUsername] = useState("")
  const [userImage, setUserImage] = useState(null)
  const [newMessage,setNewMessage] = useState("")
  const [listen,setListen] = useState(null)
  const [channelType,setChannelType] = useState(0)
  const msgRef = useRef(null)
  const keyRef = useRef(null)
  msgRef.current = messages
  keyRef.current = hasKey
  const logout = () => {
    axios.get("/logout").then((res)=>{
        navigate('/login')
       }).catch((err)=>{
        console.log(err)
       })
  }
  const checkJoin = () => {
    axios.get("/discord_has_server").then((res)=>{
      setHasKey(res.data.hasKey)
      setUsername(res.data.username)
      setUserImage(res.data.image)
      if(res.data.name!=null){
        setMessages([...msgRef.current,{isBot:true,message:<span>{res.data.name} is up and running!</span>}])
        window.setTimeout(displayPostJoin,1000)
        setServer(res.data.name)
      }
    }).catch((err)=>{
      console.log(err)
    })
  }
  const removeServer = () => {
    axios.get("/discord_remove_server").then((res)=>{
      window.location.reload()
    }).catch((err)=>{
      console.log(err)
    })
  }
  const sendMessage = () => {
    setMessages([...msgRef.current,{isBot:false,message:<span>{newMessage}</span>}])
    setNewMessage("")
  }
  const setHowStart = () => {
    setMessages([...msgRef.current,{isBot:false,message:<span>How to start a discord community?</span>}])
  }
  const setHowManage = () => {
    setMessages([...msgRef.current,{isBot:false,message:<span>How to manage a discord community?</span>}])
  }
  const checkStats = () => {
    setTyping((prev)=>true)
    setMessages([...msgRef.current,{isBot:true,message:<span>Fetching your server statistics...</span>}])
    axios.get("/discord_get_summary").then((res)=>{
      let newMessage = (
        <Stack gap={2}>
          <span>Here are the overall stats of {res.data.guildName}:</span>
          <span>You have a total of <b>{res.data.totalMembers}</b> members.</span>
          <span>Out of which <b>{res.data.newMembers}</b> are new members since last week.</span>
          <span><b>{res.data.activeMembers}</b> members are recently active, indicating {Math.round((res.data.activeMembers/res.data.totalMembers)*100)}% activity.</span>
          <span>{res.data.guildName} has a total <b>{res.data.messageCount}</b> message count.</span>
          <span>Out of which <b>{res.data.questions}</b> are questions asked recently.</span>

          {/* <Button className="option-buttons" onClick={()=>{setChannelType(0);setCreateChannel()}}>Text Channel</Button>
          <Button className="option-buttons" onClick={()=>{setChannelType(1);setCreateChannel()}}>Voice Channel</Button> */}
        </Stack>
      )
      setTyping((prev)=>false)
      setMessages([...msgRef.current,{isBot:true,message:newMessage}])
      window.setTimeout(displayStatOptions,1000)

    }).catch((err)=>{
      console.log(err)
    })
  }
  const setCreateChannel = () => {
    setMessages([...msgRef.current,{isBot:true,message:<span>What do you want to name your channel?</span>}])
    setListen("channel")
  }
  const setCreateWelcome = () => {
    setMessages([...msgRef.current,{isBot:true,message:<span>What do you want your message to say?</span>}])
    setListen("welcome")
  }
  const setAddKey = () => {
    setMessages([...msgRef.current,{isBot:true,message:<span>Please add your <a target="_blank" href="https://platform.openai.com/account/api-keys">OpenAI API Key</a></span>}])
    setListen("key")
  }
  const setRemove = () => {
    let removeOptions = (
      <Stack gap={2}>
        <span>Do you want to remove {server}'s server integration?<br/> You can add another server after.</span>
        <Button className="option-buttons" onClick={removeServer}>Yes</Button>
        <Button className="option-buttons" onClick={displayPostJoin}>No</Button>
      </Stack>)
    setMessages([...msgRef.current,{isBot:true,message:removeOptions}])
  }
  const setConnectOpenai = () => {
    if(keyRef.current==false){
      setAddKey()
    }else{
      setMessages([...msgRef.current,{isBot:true,message:<span>What would you like to know?</span>}])
      setSuggestions([{name:"How to manage a discord community?",action:setHowManage},{name:"How to start a discord community?",action:setHowStart},{name:"Replace OpenAI API Key",action:setAddKey},{name:"Back to Stats",action:displayStatOptions},{name:"Back to Main",action:displayPostJoin}])
      setListen("ask")
    }
  }
  const addDirectKnowledge = () => {
    setMessages([...msgRef.current,{isBot:true,message:<span>Please enter text the bot can refer to for answering member questions...</span>}])
    setListen("knowledge")
  }
  const addURLKnowledge = () => {
    setMessages([...msgRef.current,{isBot:true,message:<span>Please enter the URL to extract text from...</span>}])
    setListen("addURL")
  }
  const setAddKnowledgeBase = () => {
    if(keyRef.current==true){
      let knowledgeOptions = (
        <Stack gap={2}>
          <span>How do you want to add your content?</span>
          <Button className="option-buttons" onClick={addDirectKnowledge}>Direct</Button>
          <Button className="option-buttons" onClick={addURLKnowledge}>From URL</Button>
        </Stack>
      )
      setMessages([...msgRef.current,{isBot:true,message:knowledgeOptions}])

     
    }else{
      setForKnowledge((prev)=>true)
      setAddKey()
    }
  }
  const channelOptions = (
    <Stack gap={2}>
      <span>What type of channel do you want?</span>
      <Button className="option-buttons" onClick={()=>{setChannelType(0);setCreateChannel()}}>Text Channel</Button>
      <Button className="option-buttons" onClick={()=>{setChannelType(1);setCreateChannel()}}>Voice Channel</Button>
    </Stack>
  )

  const postJoinOptions = (
    <Stack gap={2}>
      <span>Here's what you can do next:</span>
      <Button className="option-buttons" onClick={checkStats}>Check Server Statistics</Button>
      <Button className="option-buttons" onClick={()=>setMessages([...msgRef.current,{isBot:true,message:channelOptions}])}>Create a Channel</Button>
      <Button className="option-buttons" onClick={setCreateWelcome}>Add a welcome message</Button>
      <Button className="option-buttons" onClick={setConnectOpenai}>Ask about Communities</Button>
      <Button className="option-buttons" onClick={setAddKnowledgeBase}>Set up a knowledgebase</Button>
      <small onClick={setRemove} style={{cursor:'pointer',opacity:'0.7'}} className="text-end fw-bold">Remove Community?</small>
    </Stack>
  )

  const botAddCheck = (
    <Stack gap={2}>
      <span>Bot Added to your Server?</span>
      <Button className="option-buttons" onClick={checkJoin}>Yes, proceed</Button>
    </Stack>
  )

  const displayPostJoin = () => {
    setListen(null)
    setMessages([...msgRef.current,{isBot:true,message:postJoinOptions}])
  }
  const displayProceed = () => {
    setListen(null)
    setMessages([...msgRef.current,{isBot:true,message:botAddCheck}])
  }
  const displayStatOptions = () => {
    setListen(null)
    const statOptions = (
      <Stack gap={2}>
        <span>Want more details?</span>
        <Button className="option-buttons" onClick={()=>getStat(0)}>Most engaging members</Button>
        <Button className="option-buttons" onClick={()=>getStat(1)}>New members</Button>
        <Button className="option-buttons" onClick={()=>getStat(2)}>Top recent chats</Button>
        <Button className="option-buttons" onClick={()=>getStat(3)}>Recent Questions</Button>
      </Stack>
    )
    setMessages([...msgRef.current,{isBot:true,message:statOptions}])
  }
  const connectCommunity = () => {
    setMessages([...msgRef.current,{isBot:true,message:<><span>Add our bot to your desired server to get started:</span><br/><a target='__blank' onClick={displayProceed} href='https://discord.com/api/oauth2/authorize?client_id=1074419354994741350&permissions=8&scope=bot'>Add SamurAI</a></>}])
    setListen(null)
  }
  const setCreateCommunity = () => {
    setMessages([...msgRef.current,{isBot:true,message:<span>What do you want to name your server?</span>}])
    setListen("server")
  }
  const getStat = (type) => {
    setTyping((prev)=>true)
    axios.get("/discord_get_stat",{params:{type:type}}).then((res)=>{
      setSuggestions([{name:"Back to Stats",action:displayStatOptions},{name:"Back to Main",action:displayPostJoin}])
      switch(type){
        case 0:
          let activeMembers = (
            <Stack gap={2}>
              <span>Your most active members are:</span>
              <ListGroup>
                {res.data.map((member,index)=><ListGroup.Item className="border-0">{index+1}. <b>{member.name}</b> with {member.points} interaction points.</ListGroup.Item>)}
              </ListGroup>
            </Stack>
          )
          setTyping((prev)=>false)
          setMessages([...msgRef.current,{isBot:true,message:activeMembers}])
          break;
        case 1:
          let newMembers = (
            <Stack gap={2}>
              <span>Your most recently joined members are:</span>
              <ListGroup>
                {res.data.map((member,index)=><ListGroup.Item className="border-0">{index+1}. <b>{member.name}</b> who joined on {member.joined_at}</ListGroup.Item>)}
              </ListGroup>
            </Stack>
          )
          setTyping((prev)=>false)
          setMessages([...msgRef.current,{isBot:true,message:newMembers}])
          break;
        case 2:
            let topChats = (
              <Stack gap={2}>
                <span>The top recent chats are:</span>
                <ListGroup>
                  {res.data.map((chat,index)=><ListGroup.Item className="border-0">{index+1}. <a className="text-decoration-none" target="_blank" href={chat.url}><b>{chat.message}</b></a> by {chat.name}</ListGroup.Item>)}
                </ListGroup>
              </Stack>
            )
            setTyping((prev)=>false)
            setMessages([...msgRef.current,{isBot:true,message:topChats}])
            break;
          case 3:
              let recentQuestions = (
                <Stack gap={2}>
                  <span>Here are some questions and mentions in your server:</span>
                  <ListGroup>
                    {res.data.map((chat,index)=><ListGroup.Item className="border-0">{index+1}. <a className="text-decoration-none" target="_blank" href={chat.url}><b>{chat.message}</b></a> by {chat.name}</ListGroup.Item>)}
                  </ListGroup>
                </Stack>
              )
              setTyping((prev)=>false)
              setMessages([...msgRef.current,{isBot:true,message:recentQuestions}])
              break;
      }
        
    }).catch((err)=>{
      console.log(err)
    })
  }
  let chatDiv = document.querySelector('.chat-scroll')
  useEffect(() => {
    if(messages.length>0&&messages[messages.length-1].isBot==false){
        switch(listen){
          case null:
            axios.post("/store_message",{msg:messages[messages.length-1].message.props.children}).then((res)=>{

            }).catch((err)=>{
              console.log(err)
            })
            break;
          case "server":
            setTyping((prev)=>true)
            axios.get("/discord_create_server",{params:{name:messages[messages.length-1].message.props.children}}).then((res)=>{
              setTyping((prev)=>false)
              setMessages([...msgRef.current,{isBot:true,message:<><span>Here's your server:</span><br/><a onClick={connectCommunity} target='__blank' href={res.data}>Invite</a></>}])
              setListen(null)
            }).catch((err)=>{
              setTyping((prev)=>false)
              setMessages([...msgRef.current,{isBot:true,message:<span>Error creating a server, please enter name again</span>}])

            })
            break;
          case "channel":
            setTyping((prev)=>true)
            axios.post("/discord_create_channel",{name:messages[messages.length-1].message.props.children,type:channelType}).then((res)=>{
              setTyping((prev)=>false)
              setMessages([...msgRef.current,{isBot:true,message:<><span>Here's your channel:</span><br/><a target='__blank' href={res.data}>New Channel</a></>}])
              window.setTimeout(displayPostJoin,1000)
              setListen(null)
            }).catch((err)=>{
              setTyping((prev)=>false)
              setMessages([...msgRef.current,{isBot:true,message:<span>Error creating a channel, please enter name again</span>}])
            })
            break;
          case "welcome":
            setTyping((prev)=>true)
            axios.post("/discord_create_welcome",{msg:messages[messages.length-1].message.props.children}).then((res)=>{
              setTyping((prev)=>false)
              setMessages([...msgRef.current,{isBot:true,message:<span>Welcome message created!</span>}])
              window.setTimeout(displayPostJoin,1000)
              setListen(null)
            }).catch((err)=>{
              setTyping((prev)=>false)
              setMessages([...msgRef.current,{isBot:true,message:<span>Error creating a welcome message, please enter message again</span>}])
            })
            break;
          case "key":
            setTyping((prev)=>true)
            axios.post("/store_key",{key:messages[messages.length-1].message.props.children}).then((res)=>{
              setTyping((prev)=>false)
              if(res.data==true){
                setHasKey((prev)=>true)
                if(forKnowledge==true){
                  setMessages([...msgRef.current,{isBot:true,message:<span>Key Added!</span>}])
                  window.setTimeout(setAddKnowledgeBase,1000)
                  setForKnowledge(false)
                }else{
                  setMessages([...msgRef.current,{isBot:true,message:<span>Key Added! What would you like to know?</span>}])
                  setSuggestions([{name:"How to manage a discord community?",action:setHowManage},{name:"How to start a discord community?",action:setHowStart},{name:"Back to Stats",action:displayStatOptions},{name:"Back to Main",action:displayPostJoin}])
                  setListen("ask")
                }
              }else{
                setMessages([...msgRef.current,{isBot:true,message:<span>Key Invalid, please enter again.</span>}])
              }
            }).catch((err)=>{
              console.log(err)
            })
            break;
          case "ask":
            setTyping((prev)=>true)
            axios.post("/ask_ai",{msg:messages[messages.length-1].message.props.children}).then((res)=>{
              setTyping((prev)=>false)
              setMessages([...msgRef.current,{isBot:true,message:<span>{res.data}</span>}])
            
            }).catch((err)=>{
              console.log(err)
            })
            break;
          case "knowledge":
            setTyping((prev)=>true)
            axios.post("/add_knowledge",{msg:messages[messages.length-1].message.props.children}).then((res)=>{
              setTyping((prev)=>false)
              if(res.data==true){
                setMessages([...msgRef.current,{isBot:true,message:<span>Knowledgebase added! Your members can now mention the bot and ask related questions.</span>}])
                setListen(null)
                window.setTimeout(displayPostJoin,1000)
              }else{
                setForKnowledge((prev)=>true)
                setAddKey()
              }

            }).catch((err)=>{
              setTyping((prev)=>false)
              setMessages([...msgRef.current,{isBot:true,message:<span>Error adding knowledgebase, please enter text again</span>}])
            })
            break;
          case "addURL":
            setTyping((prev)=>true)
            axios.post("/extract_from_url",{url:messages[messages.length-1].message.props.children}).then((res)=>{
              setTyping((prev)=>false)
              setMessages([...msgRef.current,{isBot:true,message:<span>Please check extracted text and proceed</span>}])
              setListen("knowledge")
              setNewMessage(res.data)

            }).catch((err)=>{
              setTyping((prev)=>false)
              setMessages([...msgRef.current,{isBot:true,message:<span>Error extracting from URL, please enter again</span>}])
            })
            break;
        }
    }
    if(chatDiv!=null){chatDiv.scrollTop = chatDiv.scrollHeight}
  }, [messages])

  useEffect(() => {
    if(chatDiv!=null && typing==true){chatDiv.scrollTop = chatDiv.scrollHeight}
  }, [typing])
  
  useEffect(() => {
    checkJoin()
  }, [])
  
  return (
    <div class="chat-background d-flex justify-content-center">
      <div className="chat-container ">
          <Stack className=" mx-2 mx-md-5 px-md-5 py-4 vh-100" gap={4}>
              <Stack className="rounded-cards purple-border px-5 py-3" direction="horizontal">
                  <Image className="samurai-logo" src={logo}/>
                  <LogoutIcon  className=" ms-auto icon" onClick={logout}/>
              </Stack>
              <Stack className="chat-scroll" gap={3}>
                <div class="d-flex">
                  <Image className="message-icon me-2" roundedCircle src={logoGraphic}/>
                  <div className="chat-bubble bot-message">Hi {username}!</div>
                </div>
                {server==null&&<>
                  <div class="d-flex">
                    <Image className="message-icon me-2" roundedCircle src={logoGraphic}/>
                    <div className="chat-bubble bot-message" style={{animationDelay:'0.5s'}}>Let's get started with your community</div>
                  </div>
                  <div class="d-flex">
                    <Image className="message-icon me-2" roundedCircle src={logoGraphic}/>
                    <div className="chat-bubble bot-message" style={{animationDelay:'1s'}}>
                      <Stack gap={2}>
                        <Button className="option-buttons" onClick={setCreateCommunity}>Create a Community</Button>
                        <span className="text-center">or</span>
                        <Button className="option-buttons" onClick={connectCommunity}>Connect a Community</Button>
                        <span className="text-center">or</span>
                        <Button className="option-buttons" onClick={setConnectOpenai}>Ask about Communities</Button>
                      </Stack>
                    </div>
                  </div>
                </>}
                {messages.length>0&&messages.map(((message)=>
                  <div class={message.isBot?"d-flex":"d-flex flex-row-reverse"}>
                    <Image className="message-icon" roundedCircle src={message.isBot?logoGraphic:(userImage!=null&&userImage!="")?userImage:userGraphic}/>
                    <div className={message.isBot?"chat-bubble bot-message ms-2":"chat-bubble user-message me-2"} >{message.message}</div>
                  </div>
                ))}
                {typing && 
                <div className="chat-bubble bot-message">
                  <Stack direction="horizontal" gap={2}>
                    <span class="typing"/>
                    <span class="typing"/>
                    <span class="typing"/>
                  </Stack>
                </div>}
              </Stack>
              {listen!=null&&
              <div>
                <Stack gap={3} direction="horizontal" className="px-5 mb-2 suggestions">
                  {suggestions.map((suggestion)=><small className="suggestion text-nowrap px-3 rounded-pill fw-bold" onClick={suggestion.action}>{suggestion.name}</small>)}
                </Stack>
                <InputGroup className="mt-auto">
                  <FormControl className="chat-input px-md-5 py-md-3 shadow-none"
                  value={newMessage}
                  onChange={(e)=>setNewMessage(e.target.value)}
                  onKeyDown={(e)=>{e.code=="Enter"&&sendMessage()}}
                  as={(listen=="knowledge")?"textarea":"input"}
                  />
                  <Button variant="outline-secondary" className="chat-button ps-md-3 pe-md-5 py-md-3" onClick={sendMessage} >
                    <SendIcon className="icon"/>
                  </Button>
                </InputGroup>
              </div>}
          </Stack>
      </div>
    </div>
  )
}

export default Chatbot