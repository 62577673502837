import React, { useState } from "react";
import { Nav, Accordion, Navbar, Container, Offcanvas } from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import "../styles/home.css";
import { ReactComponent as DashboardIcon } from "../assets/dashboard.svg";
import { ReactComponent as MembersIcon } from "../assets/members.svg";
import { ReactComponent as AutomationIcon } from "../assets/account_tree.svg";
import { ReactComponent as MenuIcon } from "../assets/menu.svg";

export default function SideNav() {
  const [showNavbar, setShowNavbar] = useState(false);
  const navigate = useNavigate();
  const changeTab = (tab) => {
    setShowNavbar((prev)=>false);
    navigate("/"+tab);
  }
  return (
    <>
      <Navbar expand={false} className="d-lg-none py-4">
          <Container fluid>
            <Navbar.Toggle className="p-3 border-0" onClick={()=>setShowNavbar((prev)=>true)}><MenuIcon/></Navbar.Toggle>
            <Navbar.Brand href="#">
              <DashboardIcon className="os-logo-mobile"/>

            </Navbar.Brand>
            <Navbar.Offcanvas
              id="offcanvas"
              aria-labelledby="offcanvas"
              placement="top"
              show={showNavbar}
              onHide={()=>setShowNavbar((prev)=>false)}
              className="h-100"
            >
              <Offcanvas.Header className="flex-row-reverse" closeButton>
                <Offcanvas.Title id="offcanvas">
                  <DashboardIcon className="os-logo-mobile"/>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="d-flex justify-content-center">
                <Nav
                  variant="pills"
                  className="w-100 mobile-nav p-4"
                >
                  <Nav.Item >
                    <Accordion className="side-accordion" flush alwaysOpen defaultActiveKey="dashboard">
                      <Accordion.Item eventKey="dashboard">
                        <Accordion.Header className="nav-header">
                          <DashboardIcon className="nav-icon" />
                          Dashboard
                        </Accordion.Header>
                        <Accordion.Body className="px-0">
                          <Nav.Item className="side-nav-links">
                            <Nav.Link
                              eventKey="summary"
                              onClick={() => changeTab("summary")}
                            >
                              Summary
                            </Nav.Link>
                            <Nav.Link
                              eventKey="engagement"
                              onClick={() => changeTab("engagement")}
                            >
                              Engagement
                            </Nav.Link>
                            <Nav.Link
                              eventKey="retention"
                              onClick={() => changeTab("retention")}
                            >
                              Retention
                            </Nav.Link>
                            <Nav.Link
                              eventKey="automation"
                              onClick={() => changeTab("dashboard/automation")}
                            >
                              Automation
                            </Nav.Link>
                          </Nav.Item>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Nav.Item>
                  <Nav.Item>
                    {/* <Accordion className="side-accordion" flush alwaysOpen defaultActiveKey="members">
                      <Accordion.Item eventKey="members">
                        <Accordion.Header className="nav-header" onClick={() => changeTab("members")}>
                          <MembersIcon className="nav-icon" />
                          Members
                        </Accordion.Header>
                      </Accordion.Item>
                    </Accordion> */}
                    <Nav.Link eventKey="members" onClick={() => changeTab("members")}>
                      <MembersIcon className="nav-icon" />
                          Members
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Accordion className="side-accordion" flush alwaysOpen defaultActiveKey="activities">
                      <Accordion.Item eventKey="activities">
                        <Accordion.Header className="nav-header">
                          <MembersIcon className="nav-icon" />
                          Activities
                        </Accordion.Header>
                        
                      </Accordion.Item>
                    </Accordion>
                  </Nav.Item>
                  <Nav.Item>
                    <Accordion className="side-accordion" flush alwaysOpen defaultActiveKey="activities">
                      <Accordion.Item eventKey="activities">
                        <Accordion.Header className="nav-header">
                          <AutomationIcon className="nav-icon"/> 
                          Automations
                        </Accordion.Header>
                        <Accordion.Body className="px-0">
                          <Nav.Item className="side-nav-links">
                            <Nav.Link
                              eventKey="automations"
                              onClick={() => changeTab("automations")}
                            >
                              Overview
                            </Nav.Link>
                          </Nav.Item>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Nav.Item>

                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      <div className="side-nav d-none d-lg-block">
        <div className="d-flex flex-column align-items-center">
          <DashboardIcon className="os-logo"/>
          <Nav
            variant="pills"
            className="nav-width"
          >
            <Nav.Item >
              <Accordion className="side-accordion" flush alwaysOpen defaultActiveKey="dashboard">
                <Accordion.Item eventKey="dashboard">
                  <Accordion.Header className="nav-header">
                    <DashboardIcon className="nav-icon" />
                    Dashboard
                  </Accordion.Header>
                  <Accordion.Body className="px-0">
                    <Nav.Item className="side-nav-links">
                      <Nav.Link
                        eventKey="summary"
                        onClick={() => changeTab("summary")}
                      >
                        Summary
                      </Nav.Link>
                      <Nav.Link
                        eventKey="engagement"
                        onClick={() => changeTab("engagement")}
                      >
                        Engagement
                      </Nav.Link>
                      <Nav.Link
                        eventKey="retention"
                        onClick={() => changeTab("retention")}
                      >
                        Retention
                      </Nav.Link>
                      <Nav.Link
                        eventKey="automation"
                        onClick={() => changeTab("dashboard/automation")}
                      >
                        Automation
                      </Nav.Link>
                    </Nav.Item>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Nav.Item>
            <Nav.Item>
              <Accordion className="side-accordion" flush alwaysOpen defaultActiveKey="members">
                <Accordion.Item eventKey="members">
                  <Accordion.Header className="nav-header" onClick={() => changeTab("members")}>
                    <MembersIcon className="nav-icon" />
                    Members
                  </Accordion.Header>
                  <Accordion.Body> 
                    <Nav.Item className="side-nav-links">
                      <Nav.Link
                        eventKey="members"
                        onClick={() => changeTab("members")}
                      >
                        Members
                      </Nav.Link>
                      </Nav.Item>
                      </Accordion.Body>
                </Accordion.Item>
              </Accordion>
               {/* <Nav.Link eventKey="members" onClick={() => changeTab("members")}>
                      <MembersIcon className="nav-icon" />
                          Members
                    </Nav.Link> */}
            </Nav.Item>
            <Nav.Item>
              <Accordion className="side-accordion" flush alwaysOpen defaultActiveKey="activities">
                <Accordion.Item eventKey="activities">
                  <Accordion.Header className="nav-header">
                    <MembersIcon className="nav-icon" />
                    Activities
                  </Accordion.Header>
                  <Accordion.Body> 
                    <Nav.Item className="side-nav-links">
                      <Nav.Link
                        eventKey="activities"
                        onClick={() => changeTab("activities")}
                      >
                        Activities
                      </Nav.Link>
                      </Nav.Item>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Nav.Item>
            <Nav.Item>
              <Accordion className="side-accordion" flush alwaysOpen defaultActiveKey="activities">
                <Accordion.Item eventKey="activities">
                  <Accordion.Header className="nav-header">
                    <AutomationIcon className="nav-icon"/> 
                    Automations
                  </Accordion.Header>
                  <Accordion.Body className="px-0">
                    <Nav.Item className="side-nav-links">
                      <Nav.Link
                        eventKey="automations"
                        onClick={() => changeTab("automations")}
                      >
                        Overview
                      </Nav.Link>
                    </Nav.Item>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Nav.Item>

          </Nav>
        </div>
      </div>
    </>
  );
}
