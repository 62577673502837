import { ReactComponent as GoogleIcon } from "../assets/google-logo.svg";
import { ReactComponent as GithubIcon } from "../assets/github.svg";
import logoImage from '../assets/embed-logo.png'
import {Image, Row, Col, Button} from 'react-bootstrap'
import { useState,useEffect,useRef } from "react";
import axios from "axios";

function WordpressAIChatbot(){
    document.title = "Welcome to EmbedAI"

    return(
        <div className="heybotnew-bg  p-0 p-lg-3 ">
            
            <div className="heybotnew-box posittion-relative d-flex flex-column heybotnew-theme-light w-100 h-100 p-3 p-lg-5" >
                <div className="d-flex flex-wrap justify-content-between">
                    <div class="d-flex align-items-center gap-2">
                        <Image className="heybotnew-welcome-logo" src={logoImage}/>
                        <h3 className="heybotnew-welcome-title">Wordpress AI Chatbot</h3>
                    </div>
                </div>
                <div  className="d-flex flex-column align-items-center my-5">
                    <h4 className="py-4"></h4>
                    <div style={{"max-width": '500px'}} className="heybotnew-primary-btn d-flex align-items-center justify-content-center text-nowrap py-4" onClick={()=>window.open("https://drive.google.com/file/d/1rLrpynTPAjD7qPfvmiaevEs80W-PZkPM/view?usp=sharing", "_blank")}>
                        Download wordpress plugin
                    </div>
                    <hr style={{"max-width": '500px'}} className=" m-2 w-100"/>
                    <div style={{"max-width": '500px'}} className="heybotnew-primary-btn d-flex align-items-center justify-content-center text-nowrap py-4" onClick={()=>window.open("https://embedai.thesamur.ai", "_blank")}>
                        Login to EmbedAI
                    </div>
                    <div class='embed-container mt-4'>
                    <iframe src="https://www.youtube.com/embed/gBUORMP226U?si=7eudHwsnaog_4vn_" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default WordpressAIChatbot
