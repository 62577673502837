import React from "react";
import "../../../styles/summary.css";
import "../../../styles/home.css";
import { ReactComponent as DiamondImage } from "../../../assets/diamond.svg";
import { Dropdown, Stack, Row, Col } from "react-bootstrap";
import { ReactComponent as DashboardIcon } from "../../../assets/dashboard.svg";
import { ReactComponent as MembersIcon } from "../../../assets/members.svg";
import { ReactComponent as ActiveMembersIcon } from "../../../assets/active_members_purple.svg";
import { ReactComponent as NewMembersIcon } from "../../../assets/new_members_purple.svg";
import { ReactComponent as TagsIcon } from "../../../assets/tag_purple.svg";
import { ReactComponent as MessageIcon } from "../../../assets/message_posted_purple.svg";
import { ReactComponent as QuestionIcon } from "../../../assets/questions_purple.svg";
import { ReactComponent as ShareIcon } from "../../../assets/share.svg";
import { ReactComponent as TotalMembersIcon } from "../../../assets/total_members_purple.svg";
import { useEffect, useState } from 'react';
import axios from "axios";

export default function Summary() {
  const [data,setData]=useState(null)
  useEffect(() => {
   axios.get("/get_slack_users").then((res)=>{
    console.log(res)
    setData(res.data)
   }).catch((err)=>{
    console.log(err)
   })
}, [])

const getDiscordSummary = () => {
  axios.get("/discord_initialize_members").then((res)=>{
    console.log(res.data)
    // setData(res.data)
   }).catch((err)=>{
    console.log(err)
   })
}

  return (
    <div className="d-flex justify-content-center">
      <Stack className="content-container mx-4 mx-lg-0" gap={4}>
        <Row className="g-0">
          <Stack direction="horizontal" gap={4}>
            <h4 className="m-0">Summary</h4>
            <Dropdown className="ms-auto">
              <Dropdown.Toggle className="top-filters-cards" id="dropdown-basic" onClick={getDiscordSummary}>
                <DashboardIcon className="me-3" />
                Discord
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">
                  <DashboardIcon className="me-3" />
                  Action
                </Dropdown.Item>
                <Dropdown.Item href="#/action-2">
                  <DashboardIcon className="me-3" />
                  Another action
                </Dropdown.Item>
                <Dropdown.Item href="#/action-3">
                  <DashboardIcon className="me-3" />
                  Something else
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown>
              <Dropdown.Toggle className="top-filters-cards" id="dropdown-basic">
                Last 7 days
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <div
              className="top-filters-cards d-flex align-items-center justify-content-center"
              style={{ width: "45px" }}
            >
              <DiamondImage />
            </div>
          </Stack>
        </Row>
        <Row className="g-0 gap-4 stat-row">
          <Col fluid="md">
              <Stack gap={3} direction="horizontal" className="stat-cards summary-cards">
                <div className="summary-cards-icon-bg d-flex  align-items-center justify-content-center summary-cards-icon p-2">
                  <TotalMembersIcon />
                </div>
                <div className="d-flex flex-column">
                  <span className="number-text">{data!=null?data.memberCount:0}</span>
                  <span className="small-text">Total members</span>
                </div>
              </Stack>
          </Col>
          <Col fluid="md">
              <Stack gap={3} direction="horizontal" className="stat-cards summary-cards">
                <div className="summary-cards-icon-bg d-flex  align-items-center justify-content-center summary-cards-icon p-2">
                  <ActiveMembersIcon />
                </div>
                <div className="d-flex flex-column">
                  <span className="number-text">{data!=null?data.activeMembers:0}</span>
                  <span className="small-text">Active members</span>
                </div>
                <span className="summary-helper-text-bg px-3 py-1 ms-auto small-text"> 0%</span>
              </Stack>
          </Col>
          <Col fluid="md">
              <Stack gap={3} direction="horizontal" className="stat-cards summary-cards">
                <div className="summary-cards-icon-bg d-flex  align-items-center justify-content-center summary-cards-icon p-2">
                  <NewMembersIcon />
                </div>
                <div className="d-flex flex-column">
                  <span className="number-text">{data!=null?data.newMembers:0}</span>
                  <span className="small-text">New members</span>
                </div>
                <span className="summary-helper-text-bg px-3 py-1 ms-auto small-text"> 0%</span>
              </Stack>
          </Col>
          <Col fluid="md">
              <Stack gap={3} direction="horizontal" className="stat-cards summary-cards">
                <div className="summary-cards-icon-bg d-flex  align-items-center justify-content-center summary-cards-icon p-2">
                  <TagsIcon />
                </div>
                <div className="d-flex flex-column">
                  <span className="number-text">{data!=null?data.memberCount:0}</span>
                  <span className="small-text">No Activity</span>
                </div>
              </Stack>
          </Col>
          <Col fluid="md">
              <Stack gap={3} direction="horizontal" className="stat-cards summary-cards">
                <div className="summary-cards-icon-bg d-flex  align-items-center justify-content-center summary-cards-icon p-2">
                  <MessageIcon />
                </div>
                <div className="d-flex flex-column">
                  <span className="number-text">{data!=null?data.messagesPosted:0}</span>
                  <span className="small-text">Messages posted</span>
                </div>
                <span className="summary-helper-text-bg px-3 py-1 ms-auto small-text"> 0%</span>
              </Stack>
          </Col>
          <Col fluid="md">
              <Stack gap={3} direction="horizontal" className="stat-cards summary-cards">
                <div className="summary-cards-icon-bg d-flex  align-items-center justify-content-center summary-cards-icon p-2">
                  <QuestionIcon />
                </div>
                <div className="d-flex flex-column">
                  <span className="number-text">{data!=null?data.unansweredQuestions:0}</span>
                  <span className="small-text">Unanswered questions</span>
                </div>
              </Stack>
          </Col>
        </Row>
          <Row className="g-0 gap-4">
            <Col fluid="md"className="rounded-cards p-4">
              <div className="summary-cards-big d-flex flex-column">
                <div className="d-flex justify-content-between">
                  <span style={{fontWeight:"bold",fontSize:"16px"}}>Most engaging members</span>
                  <div className="small-text"><ShareIcon className="me-1"/>Share</div>
      
                </div>
                <hr></hr>
                {data!=null && data.engagingMembers.length>0?data.engagingMembers.map((member)=>(
                    <>
                    <div className="mt-2 mb-2">{member.name}</div>
                    </>
                  )):""}
              </div>
            </Col>
            <Col fluid="md"className="rounded-cards p-4">
              <div className="summary-cards-big d-flex flex-column">
                <div className="d-flex justify-content-between">
                  <span style={{fontWeight:"bold",fontSize:"16px"}}> Top contributors</span>
                  <div className="small-text"><ShareIcon className="me-1"/>Share</div>
                </div>
                <hr></hr>
                {data!=null && data.topContributors.length>0?data.topContributors.map((member)=>(
                    <>
                    <div className="mt-2 mb-2">{member.name}</div>
                    </>
                  )):""}
              </div>
            </Col>
          </Row>
          <Row className="g-0 gap-4">
            <Col fluid="md"className="rounded-cards p-4">
              <div className="summary-cards-big d-flex flex-column">
                <div className="d-flex justify-content-between">
                  <span style={{fontWeight:"bold",fontSize:"16px"}}>Top Messages</span>
                </div>
                <hr></hr>
                {data!=null && data.topMessages.length>0?data.topMessages.map((message)=>(
                    <>
                    <div className="mt-2 mb-2">{message.message}</div>
                    </>
                  )):""}
              </div>
            </Col>
            <Col fluid="md"className="rounded-cards p-4">
              <div className="summary-cards-big d-flex flex-column">
                <div className="d-flex justify-content-between">
                  <span style={{fontWeight:"bold",fontSize:"16px"}}>Inactive new members</span>
                </div>
                <hr></hr>
              </div>
            </Col>
          </Row>
      </Stack>
    </div>
  );
}
