import {useState,useEffect} from "react";
import { Table,Stack,Row,Dropdown,Pagination,Modal,Button } from "react-bootstrap";
import { ReactComponent as DashboardIcon } from "../assets/dashboard.svg";
import { ReactComponent as MembersIcon } from "../assets/members.svg";
import { ReactComponent as DiamondImage } from "../assets/diamond.svg";
import axios from 'axios'

function Activities() {
    const [currentPage,setCurrentPage] = useState(1)
    let pages = []
    let msgLength = 0 
    const [weekBefore,setWeekBefore] = useState(0)
    const [messages,setMessages] = useState([])

    useEffect(() => {
        axios.post("/discord_get_activities",{weekBefore:currentPage}).then((res)=>{
            setMessages([...messages,res.data])
         }).catch((err)=>{
             console.log(err)
         })
    }, [currentPage])
    
    return (
        <div className="d-flex justify-content-center">
        <Stack className="content-container mx-4 mx-lg-0" gap={4}>
        <Row className="g-0">
            <Stack direction="horizontal" gap={4}>
            <h4 className="m-0">Activities</h4>

            <Dropdown className="ms-auto">
                <Dropdown.Toggle className="top-filters-cards" id="dropdown-basic" >
                <DashboardIcon className="me-3" />
                    Discord
                </Dropdown.Toggle>
                <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">
                    <DashboardIcon className="me-3" />
                    Action
                </Dropdown.Item>
                <Dropdown.Item href="#/action-2">
                    <DashboardIcon className="me-3" />
                    Another action
                </Dropdown.Item>
                <Dropdown.Item href="#/action-3">
                    <DashboardIcon className="me-3" />
                    Something else
                </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <Dropdown>
                <Dropdown.Toggle className="top-filters-cards" id="dropdown-basic">
                Last 7 days
                </Dropdown.Toggle>
                <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <div
                className="top-filters-cards d-flex align-items-center justify-content-center"
                style={{ width: "45px" }}
            >
                <DiamondImage />
            </div>
            </Stack>
        </Row>
        <Row>
        <Stack gap={3}>
        {messages.map((page)=>page.map((message)=><span className="rounded-cards">{message.content}</span>)
            
        )}
        </Stack>
        </Row>
        <Pagination className="justify-content-center pt-5">
            <Pagination.First onClick={()=>setCurrentPage((prev)=>1)}/>
                <Pagination.Prev onClick={()=>setCurrentPage((prev)=>(prev>1)?prev-1:prev)}/>
                {pages}
                <Pagination.Next onClick={()=>setCurrentPage((prev)=>(prev<msgLength)?prev+1:prev)}/>
            <Pagination.Last onClick={()=>setCurrentPage((prev)=>msgLength)}/>
        </Pagination>
        </Stack>

        </div>
    )
}

export default Activities