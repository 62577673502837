import { Stack,InputGroup,Button,FormControl } from "react-bootstrap"
import { useState,useRef } from "react"
import {ReactComponent as SendIcon} from '../assets/send.svg'
import axios from "axios"
import { useSearchParams,useParams,useLocation } from "react-router-dom";
import '../styles/heybot.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function BotEmbed() {
    const { slug } = useParams();
    const location = useLocation();
    const [quesAns, setQuesAns] = useState([])
    const quesAnsRef = useRef(null);
    quesAnsRef.current = quesAns
    const [question, setQuestion] = useState("")
    let [searchParams, setSearchParams] = useSearchParams();
    let askHeybot = () => {
        setQuestion("")
        setQuesAns([...quesAnsRef.current,{isBot:false,message:question}])
        let isShare
        let id
        if(location.pathname.includes("/share/")){
            isShare = true
            id = slug
        }
        else{
            isShare = false
            id = searchParams.get("id")
        }
        axios.post("/heybot/ask",{question:question,isShare:isShare,id:id}).then((res)=>{
            setQuesAns([...quesAnsRef.current,{isBot:true,message:res.data}])

        }).catch((err)=>{
			toast("Failed to respond " + err.response.data);
            console.log(err)
        })
    }
    return (
        <Stack className={slug==undefined?"bot-embed":"bot-embed p-5 m-5 border rounded-5"} gap={3}>
            {slug!=undefined&&<h4 className="text-center">{slug}</h4>}
            <Stack className="chat-scroll" gap={3}>
                <div className="heybot-chat-bubble bot-message"><pre>Hey! What would you like to know ?</pre></div>
               {quesAnsRef.current.length>0&&quesAnsRef.current.map((message)=>
                    <div className={message.isBot?"heybot-chat-bubble bot-message ms-2":"heybot-chat-bubble user-message me-2"} ><pre dangerouslySetInnerHTML={{__html: message.message}}/></div>
               )}
            </Stack>
            <InputGroup className="my-2">
                <FormControl className="chat-input px-md-5 py-md-2 shadow-none"
                value={question}
                onChange={(e)=>setQuestion(e.target.value)}
                onKeyDown={(e)=>{e.code=="Enter"&&askHeybot()}}
                />
                <Button variant="outline-secondary" className="chat-button text-dark fw-bold ps-md-3 pe-md-5 py-md-2" onClick={askHeybot}>
                    <SendIcon className="icon"/>

                </Button>
            </InputGroup>
            <div className="px-2  px-xl-3  py-xl-1 heybot-powered-by align-self-end">
                
            <a
              className="text-decoration-none d-flex align-items-center text-light"
              href="https://heybot.thesamur.ai"
              target="_blank"
            >
          
              <small >Powered by <b>SamurAI</b> </small>
                    
            </a>
            </div>
            <ToastContainer />
        </Stack>
    )
}

export default BotEmbed