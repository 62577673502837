import { Stack,Accordion } from "react-bootstrap"
import {ReactComponent as BackIcon} from '../assets/back.svg'
import { useNavigate } from "react-router-dom"

function HeybotFAQ() {
    const navigate  = useNavigate();
    return (
        <div class="chat-background d-flex justify-content-center">
            <div className="chat-container">
                <Stack className=" mx-2 mx-md-5 px-md-5 py-5 vh-100" gap={4}>
                    <Stack style={{cursor:'pointer',opacity:'0.7'}} direction="horizontal" gap={2} onClick={()=>navigate('/')}>
                        <BackIcon className="icon"/><span>Back to Login</span>
                    </Stack>
                    <h4 className="text-center">Frequently Asked Questions</h4>
                    <Accordion >
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>How to login to HeyBot by SamurAI?</Accordion.Header>
                            <Accordion.Body>
                                Login via Google to sign-up for the service
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>How to unlock access to Embed codes on HeyBot?</Accordion.Header>
                            <Accordion.Body>
                                Invite 5 friends via to link below
                                <br/> 
                                <a target="_blank" href="https://upvir.al/143676/lp143676">https://upvir.al/143676/lp143676</a>

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Where do I find my OpenAI Key?</Accordion.Header>
                            <Accordion.Body>
                                Login to OpenAI and visit the url to access your key <br/>
                                <a target="_blank" href="https://platform.openai.com/account/api-keys ">https://platform.openai.com/account/api-keys </a>

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>What features are available on HeyBot by SamurAI?</Accordion.Header>
                            <Accordion.Body>
                            <ul>
                                    <li>Convert one or more websites to AI chatbot</li>
                                    <li>HeyBot is powered by GPT3 API by OpenAI</li>
                                    <li>Host chatbot on HeyBot</li>
                                    <li>Get embed codes to add it your website</li>
                            </ul>

                            </Accordion.Body>
                        </Accordion.Item>			
                    </Accordion>
                    
                </Stack>
            </div>
        </div>
    )
}

export default HeybotFAQ