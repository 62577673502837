import {useState,useEffect,useRef} from 'react'
import {Stack, Button,InputGroup, FormControl,Modal, Image, Dropdown, Spinner} from 'react-bootstrap'
import ritebotLogo from '../assets/ritebot.png'
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios"
import '../styles/ritebot.css'
import {ReactComponent as SendIcon} from '../assets/send.svg'
import {ReactComponent as GoogleIcon} from '../assets/google.svg'
import { useNavigate } from "react-router-dom";
import { ReactComponent as QuestionIcon } from "../assets/question-mark.svg";
import {ReactComponent as SettingsIcon} from '../assets/settings.svg'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { ReactComponent as DiscordIcon } from "../assets/discord.svg";

function RiteBot() {
    const [isLoggedIn,setIsLoggedIn] = useState(false)
    const [authUrl,setAuthUrl] = useState("")
    const [keyAdded,setKeyAdded] = useState(false)
    const [question,setQuestion] = useState("")
    const [chat,setChat] = useState([])
    const [keyword,setKeyword] = useState("")
    const [loading,setLoading] = useState(false)
    const [showCopy, setShowCopy] = useState(0);
    const [showBanner, setShowBanner] = useState(false);
    const [needsKeyword, setNeedsKeyword] = useState(false);
    const [listen,setListen] = useState(null)
    const [user,setUser] = useState({id:0,image:""})
    const navigate = useNavigate()
    const chatRef = useRef(null)
    chatRef.current = chat
    const listenRef = useRef(null)
    listenRef.current = listen
    document.title = "RiteBot"

    let askUser = (task) => {
        setListen(task)
        switch (task) {
            case "Translate":
                setNeedsKeyword(true)
                setChat([...chatRef.current,{isBot:true,msg:<span>Please enter the language you want to translate to</span>}])
                break;
            case "Topic Ideas":
                setChat([...chatRef.current,{isBot:true,msg:<span>Please enter the topic you want to get ideas for</span>}])
                break;
            case "Content Outline":
            case "Page Titles":
            case "Meta Description":
            case "FAQs":
                setChat([...chatRef.current,{isBot:true,msg:<span>Please enter the keyword or idea you want it about</span>}])
                break;
            case "Change Emotion":
            case "Change Domain":
            case "Change Style":
            case "Change Audience":
            case "Change Intent":
            case "Change Tone":
                setNeedsKeyword(true)
                setChat([...chatRef.current,{isBot:true,msg:<span>Please enter the keyword you want to modify the content to</span>}])
                break;
            default:
                setChat([...chatRef.current,{isBot:true,msg:<span>Please add the content you want help with</span>}])
                break;
        }
    }
    const displayBasic = () => {
        setListen(null)
        const basic = (
            <Stack gap={2}>
              <span>Select any function</span>
              <div class="d-flex flex-wrap gap-2">
              <Button className="ritebot-button" onClick={()=>{askUser("Summarize")}}>Summarize</Button>
              <Button className="ritebot-button" onClick={()=>{askUser("Translate")}}>Translate</Button>
              <Button className="ritebot-button" onClick={()=>{askUser("Paraphrase")}}>Paraphrase</Button>
              <Button className="ritebot-button" onClick={()=>{askUser("Grammar Check")}}>Grammar Check</Button>
              <Button className="ritebot-button" onClick={()=>{askUser("Spell Check")}}>Spell Check</Button>
              <Button className="ritebot-button" onClick={()=>{askUser("Word Counter")}}>Word Counter</Button>
              <Button className="ritebot-button" onClick={()=>{askUser("Topic Ideas")}}>Topic Ideas</Button>


              </div>
            </Stack>
          )
          setChat([...chatRef.current,{isBot:true,msg:basic}])
    }
    const displaySEO = () => {
        setListen(null)
        const seo = (
            <Stack gap={2}>
              <span>Select any function</span>
              <div class="d-flex flex-wrap gap-2">
              <Button className="ritebot-button" onClick={()=>{askUser("Optimize for SEO")}}>Optimize for SEO</Button>
              <Button className="ritebot-button" onClick={()=>{askUser("Long Tail Keywords")}}>Long Tail Keywords</Button>
              <Button className="ritebot-button" onClick={()=>{askUser("Content Outline")}}>Content Outline</Button>
              <Button className="ritebot-button" onClick={()=>{askUser("Page Titles")}}>Page Titles</Button>
              <Button className="ritebot-button" onClick={()=>{askUser("Meta Description")}}>Meta Description</Button>
              <Button className="ritebot-button" onClick={()=>{askUser("FAQs")}}>FAQs</Button>

              </div>
            </Stack>
          )
          setChat([...chatRef.current,{isBot:true,msg:seo}])
    }
    const displayIntent = () => {
        setListen(null)
        const intent = (
            <Stack gap={2}>
              <span>Select any function</span>
              <div class="d-flex flex-wrap gap-2">
              <Button className="ritebot-button" onClick={()=>{askUser("Change Emotion")}}>Change Emotion</Button>
              <Button className="ritebot-button" onClick={()=>{askUser("Change Domain")}}>Change Domain</Button>
              <Button className="ritebot-button" onClick={()=>{askUser("Change Style")}}>Change Style</Button>
              <Button className="ritebot-button" onClick={()=>{askUser("Change Audience")}}>Change Audience</Button>
              <Button className="ritebot-button" onClick={()=>{askUser("Change Intent")}}>Change Intent</Button>
              <Button className="ritebot-button" onClick={()=>{askUser("Change Tone")}}>Change Tone</Button>

              </div>
            </Stack>
          )
          setChat([...chatRef.current,{isBot:true,msg:intent}])
    }
    const displayCategories = () => {
        setListen(null)
        const categories = (
          <Stack gap={2}>
            <span>Please select the category you want help with</span>
            <div class="d-flex flex-wrap gap-2">
                <Button className="ritebot-button" onClick={displayBasic}>Summarize & Paraphrase</Button>
                <Button className="ritebot-button" onClick={displaySEO}>SEO</Button>
                <Button className="ritebot-button" onClick={displayIntent}>Intent & Emotion</Button>
            </div>
          </Stack>
        )
        setChat([...chatRef.current,{isBot:true,msg:categories}])
      }
    let askRitebot = () => {
        setLoading((prev)=>true)
        setChat([...chatRef.current,{isBot:false,msg:<span>{question}</span>,copyContent:question}])
        setQuestion("")
        switch(listenRef.current){
            case "key":
                axios.post("/store_key",{key:question}).then((res)=>{
                    setKeyAdded(true)
                    displayCategories()
                    setListen(null)
                    setLoading((prev)=>false)
                  }).catch((err)=>{
                    toast("Key cannot be verified, try again");
                })
                break;
            default:
                if(needsKeyword==true){
                    setKeyword(question)
                    setChat([...chatRef.current,{isBot:true,msg:<span>Please add the content you want help with</span>}])
                    setNeedsKeyword(false)
                    setLoading((prev)=>false)
                }else{
                    axios.post("/ritebot/ask",{question:question,keyword:keyword,ask:listen}).then((res)=>{
                        setChat([...chatRef.current,{isBot:true,msg:<span>{res.data.output}</span>,copyContent:res.data.output}])
                        window.setTimeout(displayCategories,1000)
                        setListen(null)
                        setLoading((prev)=>false)
                      }).catch((err)=>{
                        toast("Failed to respond " + err.response.data);
                    })
                }
                break;
        }
      
       
    }
    const logout = () => {
        axios.get("/heybot/logout").then((res)=>{
            window.location.reload();
           }).catch((err)=>{
            console.log(err)
           })
    }
    useEffect(() => {
        axios.get("/mygpt/isLoggedIn",{params:{forRiteBot:true}}).then((res)=>{
            setIsLoggedIn(res.data.isLoggedIn)
            if(res.data.isLoggedIn == false){
                setAuthUrl(res.data.auth_url)
            }else{
                setUser({id:res.data.userId,image:res.data.image})
                setKeyAdded(res.data.key_added)
                if(res.data.key_added==false){
                    setChat([...chat,{isBot:true,msg:<span>Add your <a className="text-white" href="https://platform.openai.com/account/api-keys" target="_blank">OpenAI Key</a> to get started</span>}])
                    setListen("key")
                }else{
                    displayCategories()
                }
              
               
            }
          }).catch((err)=>{
            console.log(err)
        })
        window.setTimeout(()=>setShowBanner(true),30000)
      }, [])
    useEffect(() => {
        if(document.querySelector('.end-chat')!=null){document.querySelector('.end-chat').scrollIntoView({ behavior: 'smooth', block: 'end' });}
    }, [chat])
    const copyMsg = (msg) => {
        navigator.clipboard.writeText(msg)
        toast("Copied!")

    }
    return (
        <div className="vh-100 ritebot-bg d-flex flex-column position-relative">
            <Stack className="justify-content-center p-3 p-md-4 pb-md-5" gap={3} direction="horizontal">
                    <Image src={ritebotLogo} className="ritebot-logo"/>
                    <h2 className="m-0 text-light "><b>Rite</b>Bot</h2>
                
            </Stack>
            {isLoggedIn&&<Dropdown className="position-absolute mt-4 mt-md-5 ms-2 ms-md-4" >
                <Dropdown.Toggle   id="dropdown-basic">
                    {(user.image!==""&&user.image!==null) ? <Image src={user.image} className="icon rounded-circle"/> : <SettingsIcon className="icon"/>}
                    
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={()=>{setChat([...chat,{isBot:true,msg:<span>Add your <a className="text-white" href="https://platform.openai.com/account/api-keys" target="_blank">OpenAI Key</a> to get started</span>}]);setListen("key")}}>Change OpenAI Key</Dropdown.Item>
                        <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>}
            <div className="chat-bg pt-md-3 p-md-5 p-3 overflow-hidden">
                <Stack className="ritebot-chat-container" gap={3}>
                    {isLoggedIn?
                    <>
                       
                        <Stack gap={3} className="ritebot-scroll rounded-5 p-2">
                            <div className="ritebot-msg align-self-start rounded-4 px-3 py-2 px-md-4 py-md-3">Hi, this is RiteBot !</div>
                            {chat!=[]&&chat.map((msg)=><div style={{cursor:'pointer'}} onClick={()=>msg.copyContent!=undefined&&copyMsg(msg.copyContent)} className={msg.isBot?"align-self-start ritebot-msg  rounded-4 px-3 py-2 px-md-4 py-md-3 text-wrap":"align-self-end ritebot-msg  rounded-4 px-3 py-2 px-md-4 py-md-3 text-wrap"} >{msg.isBot?<pre className="ritebot-response">{msg.msg}</pre>:msg.msg}</div>)}
                            {loading&&<Stack className="ms-3 loading" direction="horizontal" gap={2}>
                                <Spinner size="sm" animation="grow" />
                                <Spinner size="sm" animation="grow" />
                                <Spinner size="sm" animation="grow" />
                            </Stack>}
                            <div className="end-chat"></div>
                        </Stack>
                        <InputGroup className="pt-2 pb-4 pb-md-3 mt-auto ritebot-input">
                            <FormControl className="chat-input px-md-5 py-md-2 shadow-none"
                            value={question}
                            onChange={(e)=>setQuestion(e.target.value)}
                            onKeyDown={(e)=>{e.code=="Enter"&&askRitebot()}}
                            as="textarea"
                            />
                            <Button variant="outline-secondary" className="chat-button text-dark fw-bold ps-md-3 pe-md-5 py-md-3" onClick={askRitebot}>
                                <SendIcon className="icon mb-1"/>
                        
                            </Button>
                        </InputGroup>
                    </>:
                    <>
                    <Image className="mygpt-logo mt-5 pt-5 align-self-center" src={ritebotLogo}/>
                    <h5 className="text-center">Login to RiteBot</h5>
                    {/* <h5 className="mt-5 pt-5">A better UI for ChatGPT with Prompt Library Support</h5> */}
                    <Button className="option-buttons align-self-center" href={authUrl}><GoogleIcon className="icon me-2 pb-1"/> Login with Google</Button>
                    <Stack direction="horizontal" gap={2} style={{cursor:'pointer',opacity:'0.7'}} className="mt-auto align-self-center" onClick={()=>navigate('/ritebot/faq')}><QuestionIcon className="icon"/> <b>Know More</b></Stack>
                    </>}
                </Stack>
            </div>
        <ToastContainer />			
        <Modal className="banner-modal" centered show={showBanner} onHide={()=>setShowBanner(false)}>
                <Modal.Body className="p-5 position-relative">
                <b style={{cursor:'pointer'}} onClick={()=>setShowBanner(false)} className="position-absolute top-0 end-0 me-4 mt-2">X</b>
                <Stack className="align-items-center" gap={3}>
                <a  href="https://www.producthunt.com/posts/ritebot?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-ritebot" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=383648&theme=light"  alt="RiteBot - Paraphraser&#0044;&#0032;grammar&#0032;checker&#0044;&#0032;summariser&#0032;and&#0032;translator | Product Hunt"/></a>                   
				<a className="discord-invite px-3 py-3 ms-3" target="_blank" href="https://discord.gg/A6EzvsKX4u"><DiscordIcon className="icon me-1"/> Join Our Discord</a>
                </Stack>
                </Modal.Body>
            </Modal> 
        </div>
    )
}

export default RiteBot