import { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import {Button,Stack, Form, Image,Row,Col, FloatingLabel, ProgressBar } from 'react-bootstrap'
import axios from "axios";


function CustomizeOnboarding({slug,loadPercent,trainingFinished,setStep}) {

    return(
        <Row className="g-0 flex-grow-1 flex-row-reverse flex-lg-row pb-2 h-100 w-100">
            <Col className="d-flex px-2 flex-column" lg={8}>
              
            <div className={`box flex-grow-1 d-flex flex-column theme-light mt-4 p-3 h-100 w-100`}>
                <h5 className=" fw-semibold align-self-start mt-2">Training in Progress</h5>

                <div className=" d-flex flex-column w-75 align-self-center my-3 my-lg-5">
                    {trainingFinished==true?
                        <Button className="share-button general-btn justify-self-center p-2" onClick={()=>setStep((prev)=>2)}>
                            Save & Continue
                        </Button>
                        :
                        <>
                            <small className="fw-semibold">Creating your Knowledgebase</small>
                            <ProgressBar className="w-100 mt-1 loader" now={(loadPercent.current/loadPercent.total)*100}/>
                        </>}
                </div>
            </div>

            </Col>
        </Row>
    )
}
export default CustomizeOnboarding
