import React,{useState,useEffect} from 'react'
import { Row, Col, Button, Image, Form, Modal, Stack, Accordion } from "react-bootstrap"
import {ReactComponent as SearchIcon} from '../assets/search-white.svg'
import {ReactComponent as WebIcon} from '../assets/web-white.svg'
import {ReactComponent as ReadIcon} from '../assets/read-white.svg'
import {ReactComponent as WriteIcon} from '../assets/write-white.svg'
import {ReactComponent as EmailIcon} from '../assets/email-white.svg'
import {ReactComponent as ZapierIcon} from '../assets/zapier-white.svg'
import {ReactComponent as WikipediaIcon} from '../assets/wikipedia-white.svg'
import {ReactComponent as WolframIcon} from '../assets/wolfram-white.svg'
import { ToastContainer, toast } from "react-toastify";
import {ReactComponent as HomeIcon} from '../assets/home-white.svg'
import axios from "axios"
import logoImage from '../assets/plugin-logo.png'
import '../styles/agi.css'

function AutoGPTPlugins() {
    document.title = "AutoGPT Plugins"

    return (
        <div class="agi-bg agi-plugins w-100 h-100 d-flex p-0 p-md-5  align-items-center justify-content-center">
            
            <div className="agi-side-bar rounded-3 my-3 p-0 p-md-5">
                <div className="d-flex justify-content-center justify-content-lg-between flex-wrap gap-4 p-3">
                    <h3 className=" fw-bold d-flex align-items-center">
                        {/* <Image className="plugin-logo me-2" src={logoImage}/> */}
                        AutoGPT Plugins
                    </h3>
					<Button className="agi-buttons" href="https://autogpt.thesamur.ai">Use these Plugins</Button>
                </div>
                <Row className="g-0 m-auto  justify-content-center">
        
                    <Col md={6} onMouseOver={(e)=>e.currentTarget.style.transform='scale(1.05)'} onMouseOut={(e)=>e.currentTarget.style.transform='scale(1)'} className="d-flex flex-column p-3">
                        <div class="agi-side-btn p-4 h-100">
                            <div class="d-flex  align-items-center w-100 gap-3 mb-3">
                                <SearchIcon className="plugin-icon"/>
                                <h5 className="m-0"><b>Search</b></h5>
                            </div>
                            <p className="m-0">
                                AutoGPT now uses search to inform its decisions. When necessary AutoGPT searches the internet with a relevant query extracted from its previous responses. This helps with presenting accurate information in its result.
                            </p>
                        </div>
                    </Col>
                    <Col md={6} onMouseOver={(e)=>e.currentTarget.style.transform='scale(1.05)'} onMouseOut={(e)=>e.currentTarget.style.transform='scale(1)'} className="d-flex flex-column p-3">
                        <div class="agi-side-btn p-4 h-100">
                            <div class="d-flex  align-items-center w-100 gap-3 mb-3">
                                <WebIcon className="plugin-icon"/>
                                <h5 className="m-0"><b>Web Browser</b></h5>
                            </div>
                            <p className="m-0">
                            AutoGPT now uses web browsing to inform its decisions. When necessary AutoGPT scrapes a web page for respective information with a relevant link extracted from its previous responses. This helps with get fine tuned responses and more data to use in its result.
                            </p>
                        </div>
                    </Col>
                    <Col md={6} onMouseOver={(e)=>e.currentTarget.style.transform='scale(1.05)'} onMouseOut={(e)=>e.currentTarget.style.transform='scale(1)'} className="d-flex flex-column p-3">
                        <div class="agi-side-btn p-4 h-100">
                            <div class="d-flex  align-items-center w-100 gap-3 mb-3">
                                <ReadIcon className="plugin-icon"/>
                                <h5 className="m-0"><b>Read File</b></h5>
                            </div>
                            <p className="m-0">
                            AutoGPT now uses the file reading functionality. When necessary AutoGPT reads files generated from its previous responses. 
                            </p>
                        </div>
                    </Col>
                    <Col md={6} onMouseOver={(e)=>e.currentTarget.style.transform='scale(1.05)'} onMouseOut={(e)=>e.currentTarget.style.transform='scale(1)'} className="d-flex flex-column p-3">
                        <div class="agi-side-btn p-4 h-100">
                            <div class="d-flex  align-items-center w-100 gap-3 mb-3">
                                <WriteIcon className="plugin-icon"/>
                                <h5 className="m-0"><b>Write File</b></h5>
                            </div>
                            <p className="m-0">
                            AutoGPT now uses the file writing functionality. When necessary AutoGPT generates files to store its responses and give final output. 
                            </p>
                        </div>
                    </Col>
                    <Col md={6} onMouseOver={(e)=>e.currentTarget.style.transform='scale(1.05)'} onMouseOut={(e)=>e.currentTarget.style.transform='scale(1)'} className="d-flex flex-column p-3">
                        <div class="agi-side-btn p-4 h-100">
                            <div class="d-flex flex-wrap align-items-center w-100 gap-3 mb-3">
                                <EmailIcon className="plugin-icon"/>
                                <h5 className="m-0"><b>Email</b></h5>
                                <small className="p-2 ms-auto border rounded-pill">Coming Soon!</small>
                            </div>
                            <p className="m-0">
                            Revolutionize email management with the Auto-GPT Email Plugin, leveraging AI to automate drafting and intelligent replies.                            
                            </p>
                        </div>
                    </Col>
                    <Col md={6} onMouseOver={(e)=>e.currentTarget.style.transform='scale(1.05)'} onMouseOut={(e)=>e.currentTarget.style.transform='scale(1)'} className="d-flex flex-column p-3">
                        <div class="agi-side-btn p-4 h-100">
                            <div class="d-flex flex-wrap align-items-center w-100 gap-3 mb-3">
                                <ZapierIcon className="plugin-icon"/>
                                <h5 className="m-0"><b>Zapier</b></h5>
                                <small className="p-2 ms-auto border rounded-pill">Coming Soon!</small>
                            </div>
                            <p className="m-0">
                            This plugin allows you to easily integrate Zapier connectivity	
                            </p>
                        </div>
                    </Col>
                    <Col md={6} onMouseOver={(e)=>e.currentTarget.style.transform='scale(1.05)'} onMouseOut={(e)=>e.currentTarget.style.transform='scale(1)'} className="d-flex flex-column p-3">
                        <div class="agi-side-btn p-4 h-100">
                            <div class="d-flex flex-wrap align-items-center w-100 gap-3 mb-3">
                                <WikipediaIcon className="plugin-icon"/>
                                <h5 className="m-0"><b>Wikipedia</b></h5>
                                <small className="p-2 ms-auto border rounded-pill">Coming Soon!</small>
                            </div>
                            <p className="m-0">
                            This allows AutoGPT to use Wikipedia directly.	                            
                            </p>
                        </div>
                    </Col>
                    <Col md={6} onMouseOver={(e)=>e.currentTarget.style.transform='scale(1.05)'} onMouseOut={(e)=>e.currentTarget.style.transform='scale(1)'} className="d-flex flex-column p-3">
                        <div class="agi-side-btn p-4 h-100">
                            <div class="d-flex flex-wrap align-items-center w-100 gap-3 mb-3">
                                <WolframIcon className="plugin-icon"/>
                                <h5 className="m-0"><b>Wolfram</b></h5>
                                <small className="p-2 ms-auto border rounded-pill">Coming Soon!</small>
                            </div>
                            <p className="m-0">
                            Access to WolframAlpha to do math and get accurate information	
                            </p>
                        </div>
                    </Col>
                </Row>

            </div>
         
			<ToastContainer />

        </div>
        
    ) 
}

export default AutoGPTPlugins