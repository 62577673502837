import { ReactComponent as GoogleIcon } from "../assets/google-logo.svg";
import { ReactComponent as GithubIcon } from "../assets/github.svg";
import logoImage from '../assets/embed-logo.png'
import {Image, Row, Col, Button, Stack} from 'react-bootstrap'
import { useState,useEffect,useRef } from "react";
import axios from "axios";
import { sendAnalyticsEvent } from "../utils/utility";
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer, toast } from 'react-toastify';

function EmbedAIResetPassword(){
    document.title = "Welcome to EmbedAI"

    function reset_password() {
        const password = document.querySelector('#password').value;
        const tokenString = window.location.search;
        const params = new URLSearchParams(tokenString);
        const token = params.get('token');

        const resetPasswordData = {
            password: password,
            token: token,
        };

        // Send a POST request to the server for signup
        axios.post('/reset_password', resetPasswordData)
            .then((response) => {
                window.location.href = "/app";
            })
            .catch((error) => {
                toast.error(error.response.data);
                console.error('Signup failed', error);
            });
    }

    return(
        <div style={{ overflowY: 'auto', height: '100vh' }}>
        <div className="heybotnew-bg  p-0 p-lg-3 ">
            
            <div className="heybotnew-box posittion-relative d-flex flex-column heybotnew-theme-light w-100 p-3 p-lg-5" >
                <div className="d-flex justify-content-end">
                    <Stack direction="horizontal" gap={3}>
                        <a href="https://www.thesamur.ai/terms-conditions" target="_blank" rel="noreferrer" className="terms-privacy-link">Terms & Conditions</a>
                        <a href="https://www.thesamur.ai/privacy" target="_blank" className="terms-privacy-link" rel="noreferrer">Privacy Policy</a>
                    </Stack>
                </div>
                <div className="d-flex flex-wrap justify-content-between">
                    <div class="d-flex align-items-center gap-2">
                        <Image className="heybotnew-welcome-logo" src={logoImage}/>
                        <h3 className="heybotnew-welcome-title">EmbedAI</h3>
                    </div>
                </div>
                <div  className="d-flex flex-column align-items-center my-5">
                    <h4 className="py-4">Reset Password</h4>

                    <div style={{ "maxWidth": '500px' }} className="mb-3">
                        <input type="password" className="form-control" id="password" placeholder="Password" />
                    </div>                    
                    <div
                        style={{ "maxWidth": '500px' }}
                        className="heybotnew-primary-btn d-flex align-items-center justify-content-center text-nowrap py-4 mb-5"
                        onClick={() => reset_password()}
                    >
                    Submit
                    </div>
                    <hr style={{"max-width": '500px'}} className=" m-2 w-100"/>
                    <div className="mt-3">
                        <p>Remember your password? <a href="/app/welcome">Login</a></p>
                    </div>
                </div>
            </div>
        </div>
        <ToastContainer/>
        </div>
    )
}
export default EmbedAIResetPassword
