import {useState,useEffect,useRef} from 'react'
import {Stack, Button,InputGroup, FormControl,Modal, Image, Dropdown, Spinner,Row, Col, Offcanvas,Form, Accordion, ButtonGroup,Tab,Tabs} from 'react-bootstrap'
import '../styles/agi.css'
import { useNavigate } from "react-router-dom";
import {ReactComponent as SearchIcon} from '../assets/search.svg'
import {ReactComponent as GoogleIcon} from '../assets/google.svg'
import {ReactComponent as CopyIcon} from '../assets/copy.svg'
import {ReactComponent as SendIcon} from '../assets/send.svg'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logoImage from '../assets/autogpt.png'
import userImage from '../assets/user.png'
import { ReactComponent as QuestionIcon } from "../assets/question-mark.svg";
import { ReactComponent as ShareIcon } from "../assets/share.svg";
import { ReactComponent as DiscordIcon } from "../assets/discord.svg";
import { ReactComponent as TwitterIcon } from "../assets/twitter.svg";
import { ReactComponent as ReloadIcon } from "../assets/reload.svg";

import { ReactComponent as StarIcon } from "../assets/star.svg";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import heybotLogo from '../assets/heybot.png'
import { useSearchParams, useParams } from "react-router-dom";
import axios from "axios"


import { BabyAGI } from "langchain/experimental/babyagi";
import { MemoryVectorStore } from "langchain/vectorstores/memory";
import { OpenAIEmbeddings } from "langchain/embeddings/openai";
import { OpenAI } from "langchain/llms/openai";
import { PromptTemplate } from "langchain/prompts";
import { LLMChain } from "langchain/chains";
import { ChainTool, SerpAPI, Tool } from "langchain/tools";
import { initializeAgentExecutorWithOptions } from "langchain/agents";

function BabyAGIFrontend() {
    document.title = "BabyAGI"
    const [isLoggedIn,setIsLoggedIn] = useState(null)
    const [authUrl,setAuthUrl] = useState("")
    const [keyAdded,setKeyAdded] = useState(null)
    const [user,setUser] = useState({id:0,image:""})
    const [question,setQuestion] = useState("")
    const [chat,setChat] = useState([])
    const [key,setKey] = useState("")
    const [name,setName] = useState("")
    const [role,setRole] = useState("")
    const [goals,setGoals] = useState([""])
    const [update,setUpdate] = useState(false)
    const [loading,setLoading] = useState(false)
    const [currentTask,setCurrentTask] = useState("")
    const [nextTasks,setNextTasks] = useState([])
    const [thoughts,setThoughts] = useState({})
    const [command,setCommand] = useState({name:"",args:""})
    const [taskResults,setTaskResults] = useState([])
    const [iterations,setIterations] = useState(10)
    const [started,setStarted] = useState(false)
    const [stopped,setStopped] = useState(true)
    const [sessions,setSessions] = useState([])
    const [showMenu, setShowMenu] = useState(false);
    const [showCopy, setShowCopy] = useState(0);
    const [streamAns,setStreamAns] = useState("")
    const [sessId,setSessId] = useState(0)
    const [showBanner, setShowBanner] = useState(false);
    const [showOutputModal, setShowOutputModal] = useState(false);
    const [babyAGI,setBabyAGI] = useState(null)

    const navigate = useNavigate()

    const sessionRef = useRef(null)
    sessionRef.current = sessId
    const startRef = useRef(null)
    startRef.current = started
    const resultRef = useRef(null)
    resultRef.current = taskResults
    const currentTaskRef = useRef(null)
    currentTaskRef.current = currentTask
    const loadRef = useRef(null)
    loadRef.current = loading
    const questionRef = useRef(null)
	questionRef.current = question
    const tasksRef = useRef(null)
	tasksRef.current = nextTasks	

    let [searchParams, setSearchParams] = useSearchParams();

    const addKey = () => {
        axios.post("/store_key",{key:key}).then((res)=>{
            setKeyAdded(true)
          }).catch((err)=>{
            toast("Key cannot be verified, try again");
        })
    }
    const changeModel = (model) => {
        axios.post("/mygpt/change_model",{model:model}).then((res)=>{
          }).catch((err)=>{
            toast("Model not updated, try again");
        })
    }

    class SearchTool extends Tool {
      constructor() {
        super();
        this.name = "SearchTool";
        this.description = "Useful for when you need to answer questions about current events. You should ask targeted questions";
      }

      async _call(input: string): Promise<string> {
		axios.post('/agi/search_duckduckgo', {
	       search: input,
        }, )
        .then(function (response) {
            // console.log(response.data);
            return response.data;	
        })
        .catch(function (error) {
		   return error;		
	       console.log(error);
        });
      }
    }

    class WebBrowserTool extends Tool {
        constructor() {
          super();
          this.name = "WebBrowserTool";
          this.description = 'useful for when you need to find something on or summarize a webpage. input should be a comma seperated list of "valid URL including protocol","what you want to find on the page or empty string for a summary."';
        }
  
        async _call(input: string): Promise<string> {
            let getInput = input.split(",")
            let url = getInput[0]
            let question = tasksRef.current[0].taskName
            if(question == "Tasks: "){
			    question = questionRef.current
			}			
            axios.post('/agi/scrape_site', {
                url: url,
                question:question
            })
            .then(function (response) {
                // console.log(response.data);
               return response.data;	
            })
            .catch(function (error) {
               return error;		
               console.log(error);
            });
          }
      }
    
    let startAGI = async() => {
        setStarted((prev)=>true)
        setLoading((prev)=>true)
        await babyAGI.call({ objective: question,getTaskList:nextTasks }).then((res)=>{setLoading((prev)=>false)})
       
    }

    const logout = () => {
        axios.get("/heybot/logout").then((res)=>{
            window.location.reload();
           }).catch((err)=>{
            console.log(err)
           })
    }
	
	const setQuestionInfo = (question) => {
		setQuestion(question);
	}

    let downloadTxtFile = (name,content) => {
        const element = document.createElement("a");
        const file = new Blob([content], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = name;
        element.target = "_blank";
        document.body.appendChild(element); 
        element.click();
      }

    useEffect(() => {

        axios.get("/agi/isLoggedIn").then(async(res)=>{
            setIsLoggedIn(res.data.isLoggedIn)
            if(res.data.isLoggedIn == false){
                setAuthUrl(res.data.auth_url)
            }else{
                setUser({id:res.data.userId,image:res.data.image})
                if(res.data.key_added==null){
                    setKeyAdded((prev)=>false)
                }else{
                    setKeyAdded((prev)=>res.data.key_added)
                    setKey(res.data.key_added)
                    const todoPrompt = PromptTemplate.fromTemplate(
                        "You are a planner who is an expert at coming up with a todo list for a given objective. Come up with a todo list of maximum 3 tasks for this objective: {objective}"
                      );
                    const tools = [
                        new SearchTool(),
                        new WebBrowserTool(),
                        new ChainTool({
                            name: "TODO",
                            chain: new LLMChain({
                            llm: new OpenAI({openAIApiKey:res.data.key_added, temperature: 0 }),
                            prompt: todoPrompt,
                            }),
                            description:
                            "useful for when you need to come up with todo lists. Input: an objective to create a todo list for. Output: a todo list of maximum 3 tasks for that objective. Please be very clear what the objective is!",
                        }),
                    ];
                    const agentExecutor = await initializeAgentExecutorWithOptions(
                    tools,
                    new OpenAI({openAIApiKey:res.data.key_added, temperature: 0 }),
                    {
                        agentType: "zero-shot-react-description",
                        agentArgs: {
                        prefix: `You are an AI who performs one task based on the following objective: {objective}. Take into account these previously completed tasks: {context}.`,
                        suffix: `Question: {task}
                    {agent_scratchpad}`,
                        inputVariables: ["objective", "task", "context", "agent_scratchpad"],
                        },
                    }
                    );
                    
                    const vectorStore = new MemoryVectorStore(new OpenAIEmbeddings({openAIApiKey:res.data.key_added}));
            
                    window.addEventListener('taskStorage', () => {
                        setCurrentTask(localStorage.getItem('currentTask'))
                      
                    })
            
                    window.addEventListener('resultStorage', () => {
                        setTaskResults([...resultRef.current,localStorage.getItem('result')])
                    })
                    
                    let getBabyAGI = BabyAGI.fromLLM({
                    llm: new OpenAI({ openAIApiKey:res.data.key_added, temperature: 0,callbacks:[
                        {handleLLMEnd(output: string) {
                            let getTaskList = getBabyAGI.printTaskList()
                            setNextTasks(getTaskList)
                        },}
                    ] }),
                    executionChain: agentExecutor, 
                    vectorstore: vectorStore,
                    maxIterations: 3,
                    });

                    setBabyAGI(getBabyAGI)
                }
                setSessions(res.data.sessions)
               
            }
          }).catch((err)=>{
            console.log(err)
        })
        // window.setTimeout(()=>setShowBanner(true),30000)
       
        // promptSelect()
      }, [])
    useEffect(() => {
        if(document.querySelector('.end-chat')!=null){document.querySelector('.end-chat').scrollIntoView({ behavior: 'smooth', block: 'end' });}
    }, [chat])
    
    // const shareChat = () => {
    //     navigator.clipboard.writeText(window.location.host+'/agi/share?session='+sessionRef.current)
    // }


    return (
        <div className=" babyagi-bg">
            <Row className=" g-0">
                <Col className={started?" h-100 d-none d-xl-block":"d-none"} xl={4}>
                    <Stack className=" h-100  p-5 text-center" gap={4}>
                        <div class="p-4 d-flex flex-column align-items-start h-100  agi-block">
                            <small className=" mb-3">Upcoming Tasks</small>
                            <div className="d-flex flex-column gap-2 align-items-start text-start side-task-scroll">
                                {nextTasks.map((task)=><div>{task.taskID}: {task.taskName}</div>)}
                            </div>
                        </div>
                        
                        <div style={{cursor:'pointer'}} onClick={()=>window.location.reload()} className="agi-side-btn mt-auto p-4 d-flex justify-content-center gap-2">
                            <ReloadIcon className="icon"/>
                            New Task
                        </div>
                      

                    </Stack>
                </Col>
                <Col className="" xl={started?8:12}>

                    {isLoggedIn!=null&&<Stack className="text-center w-100 py-2 py-lg-5 pe-2 pe-lg-5 ps-2 ps-lg-0  align-items-center  " gap={4}>
                        {isLoggedIn?
                        <>
                            <Stack gap={2} className="">
                                {startRef.current==true ? 
                                    <Stack gap={4} className="">
                                        <div className="agi-block d-flex justify-content-between align-items-center  p-4">
                                           <h4 className="m-0">BabyAGI</h4> 
                                           {loadRef.current==true?
                                           <div class="d-flex gap-1">
                                                <div class="ball"></div>
                                                <div class="ball"></div>
                                                <div class="ball"></div>
                                                <div class="ball"></div>
                                                <div class="ball"></div>
                                            </div>:
                                            <Button className="agi-buttons" onClick={startAGI}>Continue</Button>

                                            // <div class="d-flex gap-1">
                                            //     <div class="stationary-ball"></div>
                                            //     <div class="stationary-ball"></div>
                                            //     <div class="stationary-ball"></div>
                                            //     <div class="stationary-ball"></div>
                                            //     <div class="stationary-ball"></div>
                                            // </div>
                                            }
                                        </div>
                                        <div className="p-4 d-flex flex-column justify-content-between flex-wrap gap-2 agi-block">
                                            <div className="text-start text-wrap">
                                                <small>Objective</small>
                                                <h5 className="text-wrap">{question}</h5>
                                            </div>
                                                
                                            <div className="text-start text-nowrap">
                                                <small>Current Task</small>
                                                <h5 className="text-wrap">{currentTask}</h5>
                                            </div>

                                            <Button className="d-block d-xl-none agi-buttons" onClick={()=>setShowMenu(true)}>Check Task List</Button>
                                        </div>

                                        <div className="p-4 overflow-hidden agi-block">

                                            <div className="pb-4 text-start"><small>Task Results</small></div>
                                            <Stack gap={2} className="result-scroll">
                                                {resultRef.current.length>0 && resultRef.current.map((result)=>
                                                    <>
                                                        <div className="text-start position-relative">
                                                            <ReactMarkdown children={result} remarkPlugins={[remarkGfm]} />
                                                            <div
                                                                onClick={() =>  navigator.clipboard.writeText(result)}
                                                                className="position-absolute border rounded px-1 bottom-0 end-0 me-2 mb-2"
                                                            >
                                                                <CopyIcon
                                                                className=" small-icon"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="border-bottom w-25 mb-2 align-self-center"></div>
                                                    </>
                                                )}
                                            </Stack>
                                          

                                        </div>
                                        
                                    </Stack>
                                 :
                                    <Stack className="p-2 ms-0 ms-md-5 start-task p-md-5 agi-block align-items-center" gap={4}>
                                        <Image className="mygpt-logo " src={logoImage}/>
                                        <h1 className="fw-bold m-0">Welcome to BabyAGI</h1>
                                        <small>Get started with a task</small>

                                        <Form.Group className="w-75 my-4" >
                                            <h5><Form.Label className="fw-bold">Enter Task to execute</Form.Label></h5>
                                            <Form.Control value={question} placeholder="Enter Task" onChange={(e)=>setQuestionInfo(e.target.value)}/>
                                        </Form.Group>

                                        {/* <Form.Group className="w-100 " >
                                            <Row>
                                                <Col md={4}>
                                                    <h5><Form.Label className="fw-bold">Enter max iterations</Form.Label></h5>
                                                </Col>
                                                <Col md={8}><Form.Control value={iterations} type="number" placeholder="Enter max iterations" onChange={(e)=>setIterations(e.target.value)}/></Col>
                                            </Row>
                                        </Form.Group> */}
                                        <Button className="agi-buttons" onClick={startAGI}>Start Task</Button>
                                        <ButtonGroup className=" mt-auto flex-wrap gap-3">
                                            <Button onClick={()=>setKeyAdded(false)} className="babyagi-side-btn mt-2"><small>Add Key</small></Button>
                                            <Button className="babyagi-side-btn mt-2">
                                                <Dropdown align="start">
                                                <Dropdown.Toggle
                                                    className="p-0"
                                                    id="dropdown-basic"
                                                >
                                                    <small className="pb-2">Change Model</small>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={()=>changeModel("gpt-3.5-turbo")}>
                                                        GPT-3.5-Turbo
                                                    </Dropdown.Item>
                                                    <Dropdown.Item onClick={()=>changeModel("gpt-4")}>
                                                        GPT-4
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown></Button>
                                            <Button onClick={logout} className="babyagi-side-btn mt-2"><small >Logout</small></Button>
                                        </ButtonGroup>
                                    </Stack>
                                }


                                <div className="end-chat"></div>
                            </Stack>

                         
                        </>:
                        <Stack gap={4} className="agi-block align-items-center ms-5 p-5">
                            <Image className="mygpt-logo pt-5" src={logoImage}/>
                            {/* <h5 className="">Login to agi</h5> */}
                            <h5 className="mt-5 pt-5">Run BabyAGI on browser</h5>
                            <Button className="option-buttons align-self-center" href={authUrl}><GoogleIcon className="icon me-2 pb-1"/> Login with Google</Button>
							{/*<Stack direction="horizontal" gap={2} style={{cursor:'pointer',opacity:'0.7'}} className="mt-auto align-self-center" onClick={()=>navigate('/agi/faq')}><QuestionIcon className="icon"/> <b>Know More</b></Stack>*/}
                        </Stack>
                        }
                    </Stack>}
                </Col>


            </Row>
            <Offcanvas className="agi-side-bar" show={showMenu} onHide={()=>setShowMenu(false)}>
                <Offcanvas.Header closeButton>
                <Offcanvas.Title>BabyAGI</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="pb-4">
                    <Stack className=" h-100  py-4 text-center" gap={2}>
                            <div class="d-flex flex-column align-items-start h-100  agi-block">
                                <small className=" mb-3">Upcoming Tasks</small>
                                <div style={{maxHeight:'25rem'}} className="d-flex flex-column gap-2 align-items-start text-start side-task-scroll">
                                    {nextTasks.map((task)=><div>{task.taskID}: {task.taskName}</div>)}
                                </div>
                            </div>
                            
                            <div style={{cursor:'pointer'}} onClick={()=>window.location.reload()} className="agi-side-btn mt-auto p-4 d-flex justify-content-center gap-2">
                                <ReloadIcon className="icon"/>
                                New Task
                            </div>
                      

                    </Stack>
                </Offcanvas.Body>
            </Offcanvas>
            <ToastContainer />	
            <Modal className="banner-modal" centered show={showBanner} onHide={()=>setShowBanner(false)}>
                <Modal.Body className="p-5 position-relative">
                <b style={{cursor:'pointer'}} onClick={()=>setShowBanner(false)} className="position-absolute top-0 end-0 me-4 mt-2">X</b>
                <Stack className="align-items-center" gap={3}>
                    <a href="https://www.producthunt.com/posts/chatgpt-plugins-5?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-chatgpt&#0045;plugins&#0045;5" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=386004&theme=light"  alt="ChatGPT&#0032;Plugins - Access&#0032;to&#0032;chatGPT&#0032;plugins&#0032;without&#0032;ChatGPT&#0032;Plus | Product Hunt" /></a>                    
                    <a className="discord-invite px-3 py-3 ms-3" target="_blank" href="https://discord.gg/KZSUEjr4TM"><DiscordIcon className="icon me-1"/> Join Our Discord</a>
                    <a className="discord-invite px-3 py-3 ms-3" target="_blank" href="http://twitter.com/share?text=Free and faster ChatGPT pro with prompt support, Check out MyGPT by SamurAI&url=https://mygpt.thesamur.ai&hashtags=ai,gpt,chatgpt"><TwitterIcon className="icon me-1"/> Share on Twitter</a>
                    <a className="heybot-button px-3 py-3 ms-3" target="_blank" href="https://heybot.thesamur.ai"><Image src={heybotLogo} className="icon me-1"/> Convert your website into a chatbot</a>
                </Stack>
                </Modal.Body>
            </Modal> 

            <Modal className="key-modal" centered show={keyAdded==false} onHide={()=>setKeyAdded(null)}>
                <Modal.Body className="p-3 p-md-5 position-relative">
                <b style={{cursor:'pointer'}} onClick={()=>setKeyAdded(null)} className="position-absolute top-0 end-0 me-4 mt-2">X</b>
                <Stack className="align-items-center" gap={3}>
                    <h6><b>Add your OpenAI Key</b></h6>
                    <p><small>Get your OpenAI Key by signing up/ logging in from the OpenAI Dashboard. </small><a target="_blank" href="https://platform.openai.com/account/api-keys">Go to Dashboard</a></p>
                    <InputGroup >
                        <FormControl className="chat-input px-md-5 py-md-2 shadow-none"
                        style={{height:'48px'}}
                        value={key}
                        onChange={(e)=>setKey(e.target.value)}
                        onKeyDown={(e)=>{e.code=="Enter"&&addKey()}}
                        />
                        <Button variant="outline-secondary" className="chat-button text-dark fw-bold ps-md-3 pe-md-5 py-md-2" onClick={addKey}>
                            <SendIcon className="icon"/>
                        </Button>
                    </InputGroup>
                    <small>Watch this video to get started</small>
                    <iframe className="key-video" src="https://www.youtube.com/embed/FaJdwbNWNkk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </Stack>
                </Modal.Body>
            </Modal> 

          
        </div>
    )
}

export default BabyAGIFrontend