import { Image,Button,FormControl,Modal } from "react-bootstrap";
import { useState,useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import logoImage from '../assets/assistant.svg'
import {  sendAnalyticsEvent } from "../utils/utility";
import { ReactComponent as GoogleIcon } from "../assets/google-logo.svg";
import { useSearchParams, useParams } from "react-router-dom";

import '../styles/opengpt.css'
function OpenGPTHome() {
    const [keyAdded,setKeyAdded] = useState(false)
    const [key,setKey] = useState("")
    const [authUrl,setAuthUrl] = useState("")
    const [assistants,setAssistants] = useState([])
    const [isLoggedIn,setIsLoggedIn] = useState(false)
    let [searchParams, setSearchParams] = useSearchParams();
    const [showPricing, setShowPricing] = useState(false);

    let addID = () => {
        axios.post("/store_key",{key:key}).then((res)=>{

            setKeyAdded(true)
            }).catch((err)=>{
            console.log(err)
        })

    }
    const handleGoogleClick = ()=>{
        sendAnalyticsEvent("googlelogin_click")
    }
    const fetchData = async () => {
        axios.get("/opengpt/isLoggedIn").then((res)=>{
            console.log(res.data)
            setIsLoggedIn(res.data.isLoggedIn)
            if(res.data.isLoggedIn == false){
                setAuthUrl(res.data.auth_url)
                setIsLoggedIn((prev)=>false)
            }else{
                if(res.data.openai_key !=""){
                    setKey(res.data.openai_key)
                    setKeyAdded(true)
                }
                if(searchParams.get("stripe_success")){
                    confirmPayment()
                }
                if(res.data.stripe_tier=="free") {
                    setShowPricing(true);
                }
                setAssistants(res.data.assistants)
            }
            }).catch((err)=>{
            console.log(err)
        })
      }
    useEffect(()=>{
        fetchData()
    },[])

  let confirmPayment = () => {
        axios.get("/opengpt/verify_payment").then((res)=>{
                searchParams.delete('stripe_success')
                setSearchParams(searchParams)
            }).catch((err)=>{
            console.log(err)
        })
  }

  let checkoutStripe = (tier) => {
        sendAnalyticsEvent("upgradeyearly"+tier+"_click")

        axios.get("/opengpt/create_checkout_session?forPrice="+tier).then((res)=>{
            window.location.href = res.data
        }).catch((err)=>{
            console.log(err)
        })
  }

  const pricingPlans = [
    {
      id: "monthly_9",
      name: "Starter",
      price: "$9/month",
      yearlyId: "yearly_90",
      yearlyPrice: "$90/year",
      features: [
        "All Features",
        "Custom GPT builder",
        "Email support",
      ],
      recommended: true,
    },
  ];

  function PricingModal({ show, onHide }) {
    const [isYearly, setIsYearly] = useState(true);
    return (
      <Modal centered show={show} onHide={onHide} size="lg" backdrop="static" keyboard={false} >
        <Modal.Header>
          <Modal.Title>Pricing Plans</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center mb-4">
          <div className="btn-group">
            <button
              type="button"
              className={`btn ${!isYearly ? 'btn-primary' : 'btn-outline-primary text-gray'}`}
              onClick={() => setIsYearly(false)}
            >
              Monthly
            </button>
            <button
              type="button"
              className={`btn ${isYearly ? 'btn-primary' : 'btn-outline-primary text-gray'}`}
              onClick={() => setIsYearly(true)}
            >
              Yearly
            </button>
          </div>
          </div>
          <div className="d-flex flex-column flex-md-row justify-content-around">
            {pricingPlans.map((plan) => (
              <div key={plan.id} className={`card text-center ${plan.recommended ? 'border-primary' : ''}`} style={{ width: '18rem', marginBottom: '1rem' }}>
                <div className="card-header">
                  <h5 className="card-title">{plan.name}</h5>
                </div>
                <div className="card-body">
                  <h6 className="card-price text-muted">{isYearly ? plan.yearlyPrice : plan.price}</h6>
                  <ul className="list-unstyled mt-3 mb-4">
                    {plan.features.map((feature, index) => (
                      <li key={index}>{feature}</li>
                    ))}
                  </ul>
                  <button onClick={() => checkoutStripe(isYearly ? `${plan.yearlyId}` : `${plan.id}`)} className={`btn ${plan.recommended ? 'btn-primary' : 'btn-outline-secondary'}`} >
                    {'Start free'}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    );
  }


    return (
        <div className="d-flex vh-100 flex-column  bg-myBg ">
            <div id="header" className="d-flex align-items-center justify-content-between flex-wrap gap-2 bg-black text-white px-2 px-md-8 py-4  ">
                <div className="d-flex align-items-center gap-2">
                <Image src={logoImage} className="opengpt-logo" alt="logo"/>
                <h4 className="m-0 opengpt-title fw-semibold">Custom GPT</h4>
                <div className="flex-grow-1"></div>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <a href="https://thesamur.ai" target="_blank" rel="noopener noreferrer" className="btn btn-primary text-black">
                  Create Custom GPT
                  </a>
                  <a href="https://gpt-auth.com" target="_blank" rel="noopener noreferrer" className="btn btn-primary text-black">
                  Monetize GPT
                  </a>
                  <button className="btn btn-primary text-black" onClick={() => setKeyAdded(false)}>Change OpenAI Key</button>
                </div>
            </div>
            <div className=" opengpt-container px-2 px-md-4 py-4 d-flex flex-column gap-4 ">
                {!isLoggedIn?
                <>
                    <div>
                        <h3 className="">Welcome to <b>Open Custom GPT</b></h3>
                        <small>Login to continue</small>
                    </div>
                    <Button className="bg-mySecondary border border-secondary text-black opengpt-button fw-medium rounded-2 opengpt-small w-full px-3 py-2 " onClick={()=>handleGoogleClick()} href={authUrl}>
                        <GoogleIcon className="icon me-2 p-1 bg-light rounded-circle" /> Login with
                        Google
                    </Button>
                </>
                :<>
                    {keyAdded==false?
                    <div className="d-flex flex-column pt-4 pt-md-0 gap-2 mt-12 opengpt-small">
                        <div className=" opengpt-subtitle fw-semibold mb-4">
                            Welcome to Open Custom GPT, let's get started with your custom assistant
                        </div>
                        <label htmlFor="name" className="block mb-2 opengpt-small fw-medium ">Enter your <a className="underline italic" href="https://platform.openai.com/api-keys" target="_blank">OpenAI key</a> to continue</label>
                        <FormControl  id="name" className=" border border-secondary opengpt-small rounded-2 w-full px-3 py-2" placeholder="sk---------------" required value={key} onChange={(e)=>setKey(e.target.value)}/>
                    <Button onClick={addID} className="bg-mySecondary border border-secondary text-black opengpt-button fw-medium rounded-2 opengpt-small w-full px-3 py-2 ">Add OpenAI Key</Button>
                    </div>:
                    <div className=" d-flex flex-wrap gap-4">
                        {assistants.map((assistant)=>
                        <Link to={"/opengpt/create/"+assistant.id} className="text-decoration-none text-black">
                            <div className=" border border-2 px-4 py-3 d-flex gap-4 align-items-center rounded-2  cursor-pointer">
                                <div style={{height:'0.5rem',width:'0.5rem'}} className=" my-2 rounded-circle bg-black"/>
                                <div className=" d-flex flex-column">
                                    <div className=" fw-medium">{assistant.name}</div>
                                </div>
                            </div>
                        </Link>
                        )}
                    
                        <Link to="/opengpt/create/new" className="text-decoration-none text-black">
                            <div className=" border border-2 px-4 py-3 d-flex gap-4 align-items-center rounded-2  cursor-pointer" >
                                <div className=" fw-bold">+</div>
                                <div className=" d-flex flex-column">
                                    <div className=" fw-medium">Create a new assistant</div>
                                </div>
                            </div>
                        </Link>
                    </div>}
                </>}
            </div>
        <PricingModal show={showPricing} onHide={() => setShowPricing(false)} />
        </div>
    );
}

export default OpenGPTHome;
