import {toast} from 'react-toastify'
//import { useRouter } from "next/navigation";
import { Button } from "react-bootstrap";

function ShareOnboarding({KBase}) {
    let copyEmbed = (type) => {
        if(type==0){
            navigator.clipboard.writeText(KBase.embed)
            toast.success("Embed copied.")
        }else{
            navigator.clipboard.writeText(KBase.direct)
            toast.success("Link copied.")
        }
    }
    return (
        <div className={`box theme-light py-3 px-3 px-lg-4 d-flex flex-column align-items-center h-100`}>
            <div className="center-container w-100 d-flex flex-column gap-3">
                <h5 className="fw-medium mt-5 text-center">Your chatbot is ready!</h5>
                <h4 className="text-center fw-bold">{KBase.name}</h4>
                <div class="d-flex align-self-start align-items-center fw-medium">
                    <div class="px-2 py-1 me-2 icon-box">
                    </div>
                    Embed
                </div>
                <span>Add this script tag to your website's head tag to get the started, the chatbot will appear at the bottom.</span>
                <div style={{background:'#DCF6FF'}} onClick={()=>copyEmbed(0)} className="rounded p-3 position-relative cursor-pointer">
                    <pre className="m-0">{KBase.embed}</pre>
                    <div class="px-2 py-1 bg-light top-0 end-0 mt-2 me-3 position-absolute rounded">
                    </div>
                </div>
                <div className="divider-horizontal my-4"></div>
                <div class="d-flex align-self-start align-items-center fw-medium">
                    <div class="px-2 py-1 me-2 icon-box">
                    </div>
                    Direct Link
                </div>
                <span>Share this direct link to help with testing with your team.</span>
                <div style={{background:'#DCF6FF'}} onClick={()=>copyEmbed(1)} className="rounded p-3 position-relative cursor-pointer">
                    <pre className="m-0">{KBase.direct}</pre>
                    <div class="px-2 py-1 bg-light top-0 end-0 mt-2 me-3 position-absolute rounded">
                    </div>
                </div>
                <Button className="share-button general-btn justify-self-center p-2" onClick={()=>window.location.href='/app'}>
                            Continue to Dashboard
                </Button>
            </div>
        </div>
    );
}

export default ShareOnboarding;
