import React from 'react'
import SideNav from '../components/SideNav'
import { useParams, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { Tab,Row,Col} from 'react-bootstrap';
import Summary from '../components/home/dashboard/Summary';
import Engagement from '../components/home/dashboard/Engagement';
import Retention from '../components/home/dashboard/Retention';
import Automations from '../components/home/Automations';
import Members from "../components/Members";
import Activities from "../components/Activities";

export default function Home() {
    const [key, setKey] = useState("summary");
    const location = useLocation();

    const setPage = ()=>{
        if (location.pathname.includes("/summary")){
            setKey("summary")
            console.log("summary")
        }else if (location.pathname.includes("/engagement")){
            setKey("engagement")
            console.log("engagement")
        }else if (location.pathname.includes("/retention")){
            setKey("retention")
            console.log("retention")
        }else if (location.pathname.includes("/dashboard/automation")){
            setKey("automation")
            console.log("automation")
        }else if (location.pathname.includes("/automations")){
          setKey("automations")
          
        }else if (location.pathname.includes("/members")){
          setKey("members")
          console.log("members")
        }else if (location.pathname.includes("/activities")){
          setKey("activities")
          console.log("activities")
        }
    }
    const getWindowDimensions = ()=> {
        const { innerWidth: width, innerHeight: height } = window;
        return {
          width,
          height
        };
      }
    useEffect(() => {
         setPage();
         console.log(getWindowDimensions())
      }, [location]);
    return (
    <>
      <Tab.Container
        id="main-nav"
        activeKey={key}
        onSelect={(k) => setKey(k)}
      >
          <Row className="g-0 overflow-hidden">
            <Col className="position-fixed" lg={2} xl={2}>
              <SideNav />
            </Col>
            <Col className="main-col" lg={{span:9,offset:3}} xl={{span:10,offset:2}} xxl={{span:9,offset:2}}>
              <Tab.Content className="py-4 py-lg-5">
                  <Tab.Pane eventKey="summary">
                    <Summary/>
                  </Tab.Pane>
                  <Tab.Pane eventKey="engagement">
                    <Engagement/>
                  </Tab.Pane>
                  <Tab.Pane eventKey="retention">
                    <Retention/>
                  </Tab.Pane>
                  <Tab.Pane eventKey="automation">
                    Automation
                  </Tab.Pane>
                  <Tab.Pane eventKey="automations">
                    <Automations/>
                  </Tab.Pane>
                  <Tab.Pane eventKey="members">
                    <Members/>
                  </Tab.Pane>
                  <Tab.Pane eventKey="activities">
                    <Activities/>
                  </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
      </Tab.Container>
    </>
    

  )
}
