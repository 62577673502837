import React,{useState,useEffect} from 'react'
import { Row, Col, Button, Image, Form, Modal, Stack, Accordion } from "react-bootstrap"
import {ReactComponent as SearchIcon} from '../assets/search-plugin.svg'
import {ReactComponent as CodeIcon} from '../assets/code-plugin.svg'
import {ReactComponent as WolframIcon} from '../assets/wolfram.svg'
import {ReactComponent as KlarnaIcon} from '../assets/klarna.svg'
import {ReactComponent as ZapierIcon} from '../assets/zapier.svg'
import { ToastContainer, toast } from "react-toastify";
import {ReactComponent as HomeIcon} from '../assets/home.svg'
import axios from "axios"
import logoImage from '../assets/plugin-logo.png'
import PluginMenu from "../components/gptclone/PluginMenu"

function Plugins() {
    document.title = "ChatGPT Plugins"
    const [isLoggedIn,setIsLoggedIn] = useState(false)
    const [key,setKey] = useState("")
    const [plugins,setPlugins] = useState([])
    const [customPlugin,setCustomPlugin] = useState({url:'',name:''})
    const [customPlugins,setCustomPlugins] = useState([])

    const keyLinks = {search:"https://serpapi.com/dashboard",wolfram:"https://products.wolframalpha.com/api/",zapier:"https://nla.zapier.com/demo/provider/debug"}
    const [keyFor,setKeyFor] = useState(null)
    useEffect(() => {
        axios.get("/mygpt/isLoggedIn").then((res)=>{
            setIsLoggedIn(res.data.isLoggedIn)
            if(res.data.isLoggedIn == true){
                setPlugins(res.data.pluginsAdded)
            }
          }).catch((err)=>{
            console.log(err)
        })
        // window.setTimeout(()=>setShowBanner(true),30000)
       
        // promptSelect()
      }, [])
    let handleAddPlugin = (plugin,requireKey) => {
        if(plugins!=[]&&plugins.includes(plugin)){
            axios.post("/mygpt/remove_plugin",{plugin:plugin}).then((res)=>{
                let newPlugins = plugins.filter(item => item !== plugin)
                setPlugins(newPlugins)
            }).catch((err)=>{
                console.log(err)
            })
        }else{
            if(requireKey==0){
                if(isLoggedIn==false){
                    window.location.href = '/mygpt'
                }else{
                    axios.post("/mygpt/add_plugin",{plugin:plugin,key:key}).then((res)=>{
                        if(res.data==true){
                            setPlugins([...plugins,plugin])
                        }
                        setKeyFor(null)
                        setKey("")

                    }).catch((err)=>{
                        console.log(err)
                    })

                }
            }else{
                setKeyFor(plugin)
            }
        }
    }
	const removePlugin = (plugin) => {
        axios.post("/mygpt/remove_plugin",{plugin:plugin}).then((res)=>{
            
            if(plugin.includes('custom: ')){
                let getName = plugin.replace('custom: ','')
                let newPlugins = customPlugins.filter(item => item.name !== getName)
                setCustomPlugins(newPlugins)
            }else{
                let newPlugins = plugins.filter(item => item !== plugin)
                setPlugins(newPlugins)
            }
            toast('Plugin Removed')
        }).catch((err)=>{
            toast("Could not find chat, try again");
        })
    }
    let checkPlugin = async() => {
        /*let getLink = customPlugin.url+"/.well-known/ai-plugin.json"
        try {
            await AIPluginTool.fromPluginUrl(
                getLink
                ).then((output)=>{
                    
                    
		*/			
        if(isLoggedIn==false){
            window.location.href = '/mygpt'
        }else{
            axios.post("/mygpt/save_plugin",{url:customPlugin.url,name:customPlugin.name}).then((res)=>{
                setKeyFor(null)
                setCustomPlugins([...customPlugins,customPlugin.name])

                }).catch((err)=>{
                console.log(err)
                })
        }
		/*			   
                })   
        } catch (error) {
            let errMsg = "URL Error: ".concat(String(error))
            toast(errMsg);

        }
		*/
    }
    return (
        <div class="plugin-bg h-100 vw-100 d-flex  align-items-center justify-content-center">
            
            <div className="plugin-container my-3 p-4 p-md-5">
                <div className="d-flex mb-3">
					<small onClick={()=>window.location.href = 'https://thesamur.ai'} style={{cursor:'pointer'}} className="d-flex align-items-center fw-bold me-4 me-md-5 pe-md-4">
                        <HomeIcon className="icon-small me-2"/>
                        Home
                    </small>
                    <h3 className="ms-auto ms-sm-5 ps-sm-5 fw-bold d-flex align-items-center">
                        <Image className="plugin-logo me-2" src={logoImage}/>
                    ChatGPT Plugins</h3>
                </div>
                <PluginMenu isLoggedIn={isLoggedIn} plugins={plugins} customPlugins={customPlugins} handleAddPlugin={handleAddPlugin} removePlugin={removePlugin}/>
            </div>
            {/* <Modal className="banner-modal" centered show={keyFor!=null} onHide={()=>setKeyFor(null)}>
                <Modal.Body className="p-5 position-relative">
                    <b style={{cursor:'pointer'}} onClick={()=>setKeyFor(null)} className="position-absolute top-0 end-0 me-4 mt-2">X</b>
                    {keyFor!=null&&<Stack className="align-items-center" gap={3}>
                       <Form.Label>Enter your <a target="_blank" href={keyLinks[keyFor]}>{keyFor}</a> API key</Form.Label>
                       <Form.Control onChange={(e)=>setKey(e.target.value)}/>
                       <Button onClick={()=>handleAddPlugin(keyFor,0)}>Save</Button>
                    </Stack>}
                </Modal.Body>
            </Modal>  */}
			<ToastContainer />
            <Modal className="prompt-modal" centered show={keyFor!=null} onHide={()=>setKeyFor(null)}>
                <Modal.Body className="p-5 position-relative">
                    <b style={{cursor:'pointer'}} onClick={()=>setKeyFor(null)} className="position-absolute top-0 end-0 me-4 mt-2">X</b>
                    {(keyFor!=null&&keyFor=="custom")&&<Stack className="align-items-center" gap={3}>
                        <span>Make sure your server has a './well-known/ai-plugin.json' with the following format</span>
                        <Accordion className="w-100" >
                            <Accordion.Item className=" " eventKey="0">
                                <Accordion.Header><small>ai-plugin.json</small></Accordion.Header>
                                <Accordion.Body className="p-0 task-scroll">
                                    <pre className="overflow-hidden">
                                    {`
{   
    "schema_version": "v1",
    "name_for_human": "NAME",
    "name_for_model": "NAME",
    "description_for_human": "DESCRIPTION",
    "description_for_model": "DESCRIPTION",
    "auth": {
        "type": "none"
    },
    "api": {
        "type": "openapi",
        "url": "SERVER_ROUTE/openapi.yaml",
        "is_user_authenticated": false
    },
    "logo_url": "SERVER_ROUTE/image",
    "contact_email": "EMAIL",
    "legal_info_url": "SERVER_ROUTE/legal"     
}
                                    `}
                                    </pre>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <span>And add the yaml file to your server with the following format</span>
                        <Accordion className="w-100" >
                            <Accordion.Item className=" " eventKey="0">
                                <Accordion.Header><small>openapi.yaml</small></Accordion.Header>
                                <Accordion.Body className="p-0 task-scroll">
                                    <pre className="">
                                    {`
openapi: 3.0.1
info:
    title: TITLE
    description: DESCRIPTION
    version: 'v1'
servers:
    - url: SERVER_ROUTE
paths:
    /ROUTE:
    get: 
    # max 200 characters for each API endpoint description/summary field and each API param description field in API specification

        operationId: KEYWORD # use a meaningful name and include prompt-related keyword
        summary: SUMMARY # include keywords for the prompt as much as possible
        responses:
        '200':
            description: Successful action
            content:
            application/json:
                schema:
                type: object
                properties:  # json object schema here 
                    message:
                    type: string
                    example: 'This is an example text'
                                    `}
                                    </pre>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <div className="w-100">
                            <Form.Label>Enter name for your custom plugin</Form.Label>
                            <Form.Control onChange={(e)=>setCustomPlugin({url:customPlugin.url,name:e.target.value})}/>
                        </div>
                        <div className="w-100">
                            <Form.Label>Enter server URL</Form.Label>
                            <Form.Control onChange={(e)=>setCustomPlugin({url:e.target.value,name:customPlugin.name})}/>
                        </div>
                        <Button onClick={checkPlugin}>Save</Button>
                    </Stack>}
                </Modal.Body>
            </Modal> 
        </div>
        
    ) 
}

export default Plugins