import { Stack,Accordion } from "react-bootstrap"
import {ReactComponent as BackIcon} from '../assets/back.svg'
import { useNavigate } from "react-router-dom"

function RiteBotFAQ() {
    const navigate  = useNavigate();
    return (
        <div class="chat-background d-flex justify-content-center">
            <div className="chat-container">
                <Stack className=" mx-2 mx-md-5 px-md-5 py-5 vh-100" gap={4}>
                    <Stack style={{cursor:'pointer',opacity:'0.7'}} direction="horizontal" gap={2} onClick={()=>navigate('/')}>
                        <BackIcon className="icon"/><span>Back to Login</span>
                    </Stack>
                    <h4 className="text-center">Frequently Asked Questions</h4>
                    <Accordion >
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>How to login to Ritebot?</Accordion.Header>
                            <Accordion.Body>
                                Login via Google to sign-up for the service
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>How to unlock premium features?</Accordion.Header>
                            <Accordion.Body>
                                Invite 5 friends via to link below
                                <br/> 
                                <a target="_blank" href="https://upvir.al/143676/lp143676">https://upvir.al/143676/lp143676</a>

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Where do I find my OpenAI Key?</Accordion.Header>
                            <Accordion.Body>
                                Login to OpenAI and visit the url to access your key <br/>
                                <a target="_blank" href="https://platform.openai.com/account/api-keys ">https://platform.openai.com/account/api-keys </a>

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>What features are available on RiteBot?</Accordion.Header>
                            <Accordion.Body>
                            <ul>
                                    <li>Summarize</li>
                                    <li>Translate</li>
                                    <li>Paraphrase</li>
                                    <li>Grammar Check</li>
                                    <li>SEO and keywords</li>
                                    <li>Change Tone, Emotion and Audience</li>
                            </ul>

                            </Accordion.Body>
                        </Accordion.Item>			
                    </Accordion>
                    
                </Stack>
            </div>
        </div>
    )
}

export default RiteBotFAQ