import {useState,useEffect,useRef} from 'react'
import {Stack, Button,InputGroup, FormControl,Modal, Image, Dropdown,Form} from 'react-bootstrap'
import { useSearchParams, useParams } from "react-router-dom";
import '../styles/heybot.css'
import { useNavigate } from "react-router-dom";
import {ReactComponent as PlusIcon} from '../assets/plus.svg'
import {ReactComponent as GoogleIcon} from '../assets/google.svg'
import {ReactComponent as SendIcon} from '../assets/send.svg'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logoImage from '../assets/heybot.png'
import userImage from '../assets/user.png'
import { ReactComponent as QuestionIcon } from "../assets/question-mark.svg";
import { ReactComponent as DiscordIcon } from "../assets/discord.svg";
import { ReactComponent as TwitterIcon } from "../assets/twitter.svg";



import axios from "axios"
function HeyBot() {
    document.title = "HeyBot"
    const [embedCopied,setEmbedCopied] = useState(false)
    const [linkCopied,setLinkCopied] = useState(false)
    const [isLoggedIn,setIsLoggedIn] = useState(false)
    const [slug,setSlug] = useState("")
    const [showSlugModal,setShowSlugModal] = useState(false)
    const [authUrl,setAuthUrl] = useState("")
    const [shareUrl,setShareUrl] = useState(null)
    const [keyAdded,setKeyAdded] = useState(false)
    const [userId,setUserId] = useState("")
    const [showEmbed,setShowEmbed] = useState(false)
    const [key,setKey] = useState("")
    const [links, setLinks] = useState([{id:0,link:''}])
    const [update,setUpdate] = useState(false)
    const [showBanner, setShowBanner] = useState(false);
    const [file, setFile] = useState(null);
    const [isPDF, setIsPDF] = useState(false);
    const [showNotionPopup, setShowNotionPopup] = useState(false);
    const [notionKey, setNotionKey] = useState("");
    const [isNotionKeyAdded,setIsNotionKeyAdded] = useState(false)
    const inputFileRef = useRef(null);
    const navigate = useNavigate()
    let [searchParams, setSearchParams] = useSearchParams();

    const editLink = (index,id,value) => {
        links[index] = {id:id,link:value}
        setUpdate((prev)=>!prev)
    }
    const removeLink = (index) => {
        links.splice(index,1)
        setUpdate((prev)=>!prev)
    }
    const addLink = () => {
        setLinks([...links,{id:0,link:''}])
    }
    const addKey = () => {
        axios.post("/store_key",{key:key}).then((res)=>{
            setKeyAdded(true)
          }).catch((err)=>{
            toast("Key cannot be verified, try again");
        })
    }
    const createChatbot = () => {
        const formData = new FormData();
        formData.append('links',JSON.stringify(links))
        let adminId = ""
        if(searchParams.get("forAdmin")!=null){
          adminId = searchParams.get("forAdmin")
        }
        formData.append('adminId',adminId)

        axios.post("/heybot/create_chatbot",formData,{
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then((res)=>{
			console.log("Output", res);  
            // setKeyAdded(true)
            toast(res.data);
            setShowEmbed(true)
          }).catch((err)=>{
            toast("Failed to respond " + err.response.data);		
        })
    }
    
    let copyEmbed = () => {
		/*
        toast("Invite your friends to get access to embed code");		
		setTimeout(function() { 
            window.open("https://upvir.al/143676/lp143676", "_blank")
        }, 3000);	
        */		
        setEmbedCopied(true)
        navigator.clipboard.writeText('<iframe className="embed-container" src="https://heybot.thesamur.ai/heybot/embed?id='+userId+'"/>')
        window.setTimeout(()=>setEmbedCopied(false),1000)
    }
    let copyLink = () => {
        setLinkCopied(true)
        navigator.clipboard.writeText(shareUrl)
        window.setTimeout(()=>setLinkCopied(false),1000)
    }
    const addSlug = () => {
        axios.post("/heybot/add_slug",{slug:slug}).then((res)=>{
            setShareUrl("https://heybot.thesamur.ai/share/"+res.data)
          }).catch((err)=>{
            toast("Slug cannot be added, try again");
        })
    }
    const logout = () => {
        axios.get("/heybot/logout").then((res)=>{
            navigate('/')
           }).catch((err)=>{
            console.log(err)
           })
      }
    useEffect(() => {
        let adminId = ""
        if(searchParams.get("forAdmin")!=null){
          adminId = searchParams.get("forAdmin")
        }
        axios.get("/heybot/isLoggedIn",{params:{adminId:adminId}}).then((res)=>{
            setIsLoggedIn(res.data.isLoggedIn)
            if(res.data.isLoggedIn == false){
                setAuthUrl(res.data.auth_url)
            }else{
                setUserId(res.data.userId)
                setKeyAdded(res.data.key_added)
                setLinks(res.data.links)
                setIsNotionKeyAdded(res.data.notion_key_added)
                setNotionKey(res.data.notion_key)
                if(res.data.bot_added==true){
                    setShowEmbed(true)
                    if(res.data.slug!==""){
                        setShareUrl("https://heybot.thesamur.ai/share/"+res.data.slug)
                    }
                }
            }
          }).catch((err)=>{
            console.log(err)
        })
        window.setTimeout(()=>setShowBanner(true),30000)
      }, [])

      const  handleFileInputChange =(e) =>{
        const selectedFile = e.target.files[0];
        console.log(selectedFile)

        // Check if the selected file is a PDF file
        if (selectedFile && selectedFile.type === "application/pdf") {
          setFile(selectedFile);
          setIsPDF(true)
		  
		  const formData = new FormData();
          formData.append('file', selectedFile);

          axios.post("/heybot/add_pdf",formData,{
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then((res)=>{
			  console.log("Output", res);  
              toast(res.data);
              setShowEmbed(true)
            }).catch((err)=>{
              toast("Failed to respond " + err.response.data);		
          })
		  
		  
        } else {
          setFile(null);
          setIsPDF(false)
          alert("Please select a PDF file.");
          inputFileRef.current.value = null;
        }
      }

      const handleFileClear =(e)=>{
        e.preventDefault();
        setFile(null);
        setIsPDF(false)
        inputFileRef.current.value = null;
      }


      const addNotionKey = (e)=>{
        e.preventDefault()
        const item = document.getElementById("notion-key-input");
        const value = item.value;
        
        if(value!=null && value!==""){
            setNotionKey(value)
            setIsNotionKeyAdded(true)
            setShowNotionPopup(false)
			
            const formData = new FormData();
            formData.append('notionKey', value);
            axios.post("/heybot/integrate_notion",formData,{
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }).then((res)=>{
			    console.log("Output", res);  
                toast(res.data);
                setShowEmbed(true)
              }).catch((err)=>{
                toast("Failed to respond " + err.response.data);		
            })
			
			
        }else{
            toast.error("Enter a valid Notion Key")
        }
      }

      const handleNotionKeyClear =(e)=>{
        e.preventDefault()
        setIsNotionKeyAdded(false)
        setNotionKey("")
      }

    return (
      <div class=" d-flex justify-content-center">
        <div className="bot-container">
          <Stack
            className="text-center mx-2 mx-md-5 px-md-5 py-4 vh-100 align-items-center"
            gap={4}
          >
            <Stack className="p-3" direction="horizontal">
              <h3 className="fw-bold">
                <Image className="heybot-logo" src={logoImage} />
                HeyBot
              </h3>
              {isLoggedIn && (
                <Dropdown className="ms-auto">
                  <Dropdown.Toggle
                    className="border rounded-circle py-2 heybot-toggle"
                    id="dropdown-basic"
                  >
                    <Image src={userImage} className="icon" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setKeyAdded(false)}>
                      Change OpenAI Key
                    </Dropdown.Item>
                    <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </Stack>
            {isLoggedIn ? (
              <>
                {keyAdded == false ? (
                  <>
                    <span>
                      Add your{" "}
                      <a
                        target="_blank"
                        href="https://platform.openai.com/account/api-keys"
                      >
                        OpenAI Key
                      </a>
                    </span>
                    <div className="d-flex flex-column align-items-start align-self-center">
                      <InputGroup className="my-2">
                        <FormControl
                          className="chat-input px-md-5 py-md-2 shadow-none"
                          value={key}
                          onChange={(e) => setKey(e.target.value)}
                          onKeyDown={(e) => {
                            e.code == "Enter" && addKey();
                          }}
                        />
                        <Button
                          variant="outline-secondary"
                          className="chat-button text-dark fw-bold ps-md-3 pe-md-5 py-md-2"
                          onClick={addKey}
                        >
                          <SendIcon className="icon" />
                        </Button>
                      </InputGroup>
                    </div>
                  </>
                ) : (
                  <>
                    <span>Add links to get started</span>
                    <div className="d-flex flex-column align-items-start align-self-center">
                      {links.length > 0 &&
                        links.map((link, index) => (
                          <div className="d-flex align-items-center">
                            <InputGroup className="my-2">
                              <FormControl
                                className="chat-input px-md-5 py-md-2 shadow-none"
                                value={link.link}
                                placeholder="Enter New URL"
                                onChange={(e) =>
                                  editLink(index, link.id, e.target.value)
                                }
                                onKeyDown={(e) => {
                                  e.code == "Enter" && addLink();
                                }}
                              />
                              <Button
                                variant="outline-secondary"
                                className="chat-button text-dark fw-bold ps-md-3 pe-md-5 py-md-2"
                                onClick={() => removeLink(index)}
                              >
                                x
                              </Button>
                            </InputGroup>
                            {index == links.length - 1 && (
                              <PlusIcon
                                style={{ opacity: "0.7", cursor: "pointer" }}
                                className=" ms-3 icon"
                                onClick={addLink}
                              />
                            )}
                          </div>
                        ))}
                    </div>

                    <div className="d-flex align-items-center">
                      <Form.Control
                        type="file"
                        size="sm"
                        accept="application/pdf"
                        onChange={(e) => handleFileInputChange(e)}
                        ref={inputFileRef}
                      />
                      <Button
                        className="text-dark fw-bold"
                        style={{
                          background: "transparent",
                          color: "black",
                          border: "0px",
                        }}
                        onClick={(e) => {
                          handleFileClear(e);
                        }}
                      >
                        X
                      </Button>
                    </div>
                    { isNotionKeyAdded && notionKey!==""?<div>
                        <span  style={{border:"1px solid #ced4da",borderRadius:"22px"}} className="px-md-5 py-md-2 shadow-none">{notionKey}</span>
                        <Button
                        className="text-dark fw-bold"
                        style={{
                          background: "transparent",
                          color: "black",
                          border: "0px",
                        }}
                        onClick={(e) => {
                          handleNotionKeyClear(e);
                        }}
                      >
                        X
                      </Button>
                    </div>:
                    <Button 
					className=" heybot-small-button"
					onClick={(e) => {setShowNotionPopup(true);
                    }}
					>
                      Integrate Notion
                    </Button>}
                    
                    <Button className=" option-buttons" onClick={createChatbot}>
                      Save and Create Chatbot
                    </Button>
					<a href="https://slack.com/oauth/v2/authorize?client_id=5033277118145.5023142602404&scope=app_mentions:read,channels:join,chat:write,users:read,users:read.email&user_scope=" target="_blank"><img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" /></a>
                    {showEmbed && (
                      <>
                        <hr />
                        <Stack
                          className="align-self-center  px-3 px-md-0"
                          gap={3}
                          direction="horizontal"
                        >
                          <h6>Here's your chatbot</h6>
                          <Button
                            className="heybot-small-button ms-auto"
                            onClick={copyEmbed}
                          >
                            {embedCopied ? "Copied!" : "Copy Embed"}
                          </Button>
                          or
                          <Button
                            className="heybot-small-button"
                            onClick={() => setShowSlugModal(true)}
                          >
                            Share
                          </Button>
                        </Stack>
                        <div className="d-flex flex-column border rounded-5 p-4">
                          <iframe
                            className="embed-container"
                            src={
                              "https://heybot.thesamur.ai/heybot/embed?id=" +
                              userId
                            }
                          />
                          {/* <iframe className="embed-container" src={"http://localhost:3000/heybot/embed?id="+userId}/> */}
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                <h5 className="mt-5 pt-5">
                  Convert website to Chatbot powered by ChatGPT
                </h5>
                <Button
                  className="option-buttons align-self-center"
                  href={authUrl}
                >
                  <GoogleIcon className="icon me-2 pb-1" /> Login with Google
                </Button>
                <Stack
                  direction="horizontal"
                  gap={2}
                  style={{ cursor: "pointer", opacity: "0.7" }}
                  className="mt-auto align-self-center"
                  onClick={() => navigate("/heybot/faq")}
                >
                  <QuestionIcon className="icon" /> <b>Know More</b>
                </Stack>
              </>
            )}
          </Stack>
          <Modal
            className="banner-modal"
            centered
            show={showSlugModal}
            onHide={() => setShowSlugModal(false)}
          >
            <Modal.Body className="p-5 position-relative">
              <b
                style={{ cursor: "pointer" }}
                onClick={() => setShowSlugModal(false)}
                className="position-absolute top-0 end-0 me-4 mt-2"
              >
                X
              </b>
              <Stack className="align-items-center" gap={3}>
                {shareUrl == null ? (
                  <>
                    <span>Add a name for your bot</span>
                    <InputGroup className="my-2">
                      <FormControl
                        className="chat-input px-md-5 py-md-2 shadow-none"
                        value={slug}
                        onChange={(e) => setSlug(e.target.value)}
                        onKeyDown={(e) => {
                          e.code == "Enter" && addSlug();
                        }}
                      />
                      <Button
                        variant="outline-secondary"
                        className="chat-button text-dark fw-bold ps-md-3 pe-md-5 py-md-2"
                        onClick={addSlug}
                      >
                        <SendIcon className="icon" />
                      </Button>
                    </InputGroup>
                  </>
                ) : (
                  <>
                    <span>Link Generated</span>
                    <Button className="option-buttons" onClick={copyLink}>
                      {linkCopied ? "Copied!" : "Copy Link"}
                    </Button>
                  </>
                )}
              </Stack>
            </Modal.Body>
          </Modal>
        </div>
        <ToastContainer />
        <Modal
          className="banner-modal"
          centered
          show={showBanner}
          onHide={() => setShowBanner(false)}
        >
          <Modal.Body className="p-5 position-relative">
            <b
              style={{ cursor: "pointer" }}
              onClick={() => setShowBanner(false)}
              className="position-absolute top-0 end-0 me-4 mt-2"
            >
              X
            </b>
            <Stack className="align-items-center" gap={3}>
              <a
                href="https://www.producthunt.com/posts/heybot-2?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-heybot&#0045;2"
                target="_blank"
              >
                <img
                  src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=381326&theme=light"
                  alt="HeyBot - Website&#0032;to&#0032;Chatbot&#0032;powered&#0032;by&#0032;GPT3 | Product Hunt"
                />
              </a>
              <a
                className="discord-invite px-3 py-3 ms-3"
                target="_blank"
                href="https://discord.gg/A6EzvsKX4u"
              >
                <DiscordIcon className="icon me-1" /> Join Our Discord
              </a>
              <a
                className="discord-invite px-3 py-3 ms-3"
                target="_blank"
                href="http://twitter.com/share?text=Convert your website into a chatbot! Check out HeyBot by SamurAI&url=https://heybot.thesamur.ai&hashtags=ai,gpt,chatgpt"
              >
                <TwitterIcon className="icon me-1" /> Share on Twitter
              </a>
            </Stack>
          </Modal.Body>
        </Modal>

        <Modal show={showNotionPopup} onHide={(e)=>{setShowNotionPopup(false)}} centered>
          <Modal.Header closeButton>
            <Modal.Title>Integrate Notion</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
            <Form.Label>Enter Notion Integration key</Form.Label>
            <Form.Control placeholder="Notion Integration key" id="notion-key-input"/>
            <div className="d-flex align-items-end mt-5" >

            <Button className="heybot-small-button ms-auto" onClick={(e)=>addNotionKey(e)}>
              Submit
            </Button>
            </div>
            </div>
          </Modal.Body>
          
        </Modal>
      </div>
    );
}

export default HeyBot