import { FormControl,Button, Image } from "react-bootstrap";
import { ReactComponent as BackIcon } from "../assets/back.svg";
import { useEffect,useState } from "react";
import {ReactComponent as CopyIcon} from '../assets/copy.svg'
import profileImage from '../assets/userProfile.png'
import axios from "axios";
function HeyBotNewProfile() {
    const [name,setName] = useState("")
    const [apiKey,setApiKey] = useState("")
    const [tier,setTier] = useState("Free")
    useEffect(()=>{

        axios.get("/heybotnew/get_user_info").then((res)=>{
            setName(res.data.name)
            setApiKey(res.data.api_key)
            setTier(res.data.tier)
           
        }).catch((err)=>{
        })
    },[])
    const changeAPI = () => {
        axios.get("/heybotnew/regenerate_api_key").then((res)=>{
           setApiKey(res.data)
        }).catch((err)=>{
            console.log("Could not regenerate, try again");
        })
    }
    return (
        <div className="heybotnew-bg d-flex justify-content-center align-items-center p-0 p-lg-3 ">
            <div className={`heybotnew-box heybotnew-profile-box h-100 heybotnew-theme-light d-flex flex-column p-3`}>
                <div class="d-flex align-items-center mb-3 gap-2 align-self-start" onClick={()=>window.location.href="/app"}>
                    <div class={`rounded-circle heybotnew-box heybotnew-theme-light p-2`}>
                        <BackIcon className="heybotnew-image" style={{cursor:"pointer"}}/>
                    </div>
                    <h4 className="m-0 fw-bold">Back</h4>
                </div>
                <div class="d-flex flex-column gap-3 py-5 px-2 px-md-5">
                    <div class="d-flex gap-3 pb-4">
                        <Image src={profileImage} className="heybotnew-login-image"/>
                        <div class="d-flex flex-column gap-2">
                            <h5 className="m-0">{name}</h5>
                            <div class="d-flex align-items-center gap-2">
                              <small>{tier} Tier</small>
                              <button className="btn btn-outline-primary btn-sm" onClick={() => window.open('https://billing.stripe.com/p/login/bIY4k1apt6ksdTG3cc', '_blank')}>Manage</button>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column text-start position-relative">
                        <FormControl value={apiKey}/>
                        <div
                            onClick={() =>  navigator.clipboard.writeText(apiKey)}
                            className="position-absolute border rounded px-1 bottom-0 end-0 me-2 mb-2 cursor-pointer"
                        >
                            <CopyIcon
                            className=" small-icon"
                            />
                        </div>
                    </div>
                    <Button  className="share-button text-black align-self-start px-3 p-2" onClick={changeAPI}>
                        Regenerate API Key
                    </Button>
                </div>

            </div>
        </div>

    );
}

export default HeyBotNewProfile;
