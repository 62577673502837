import React ,{ useState,useEffect } from "react";
import { Modal, Button, ListGroup, FormControl, Row, Col,Tab, Tabs } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import { ReactComponent as CustomPromptIcon } from "../../assets/custom-prompt-icon.svg";


export default function PromptModal({setSelectedPrompt,setShowModal,showModal,data,setQuestion,darkTheme}) {
  const [selectedItem, setSelectedItem] = useState("");
  const [isCustom, setIsCustom] = useState(false);
  const [promptData, setPromptData] =useState({});
  const [isSearch, setIsSearch] = useState(false);

  useEffect(() => {
    setPromptData(data)
   }, [])

  const handleSelectItem = (item) => {
    setSelectedItem(item);
    setIsCustom(false)
  };
  const handleCancel = () => {
    setSelectedItem("");
    setShowModal(false)
  };
  const handleSelect = () => {
    if(selectedItem.prompt.includes("'keyword'")){
      toast("Change keyword to desired input!")
    }else{
      setSelectedPrompt(selectedItem);
      setSelectedItem("")
      setShowModal(false)
    }

  };

  const handleFilter = (value)=>{
    if(value==""){
      setIsSearch(false)
      setPromptData(data)
    }else{
      setIsSearch(true)
      const filteredData = []
      Object.keys(data).forEach(part => {
        let result = data[part].filter((prompt) =>
        prompt.act.toLowerCase().includes(value.toLowerCase())
        );
        if(result!=[]){filteredData.push(result)}
      });
      setPromptData(filteredData)
    }
    }
  return (
    <Modal show={showModal}  onHide={handleCancel} className="prompt-modal"  centered>
      <Modal.Header className="p-4" closeButton style={{backgroundColor:darkTheme?"#2b2d2f ":"white",color:darkTheme?"white":"black"}}>
        <Modal.Title>Select a prompt</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4" style={{backgroundColor:darkTheme?"#2b2d2f ":"white",color:darkTheme?"white":"black"}}>
        <Row className="prompt-modal-content">
          <Col md={6} >
          <FormControl  className="mb-2 shadow-none" placeholder="Search" onChange={(e)=>handleFilter(e.target.value)} style={{borderRadius:"15px",background:darkTheme?"#1d1d1d":"white",border:darkTheme?"none":"",color:darkTheme?"white":"black"}}/>
          {isSearch==false ?  
          <Tabs
            defaultActiveKey="popular"
            id="uncontrolled-tab-example"
            className={darkTheme?"prompt-tabs-dark border-0 mb-2":"prompt-tabs border-0"}
          >
            <Tab  eventKey="popular" title="Popular">
              <ListGroup className="prompt-list rounded">
                {Object.keys(promptData).length !== 0 && promptData.popular.map((item) => (
                  <ListGroup.Item
                    key={item}
                    active={selectedItem === item}
                    onClick={() => handleSelectItem(item)}
                  >
                    {item.act}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Tab>
            <Tab  eventKey="writing" title="Writing">
            <ListGroup className="prompt-list rounded">
                {Object.keys(promptData).length !== 0 && promptData.writing.map((item) => (
                  <ListGroup.Item
                    key={item}
                    active={selectedItem === item}
                    onClick={() => handleSelectItem(item)}
                  >
                    {item.act}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Tab>
            <Tab  eventKey="custom" title="Custom">
            <ListGroup className="prompt-list rounded">
                {Object.keys(promptData).length !== 0 && promptData.custom.map((item) => (
                  <ListGroup.Item
                    key={item}
                    active={selectedItem === item}
                    onClick={() => handleSelectItem(item)}
                  >
                    {item.act}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Tab>
          </Tabs> :
           <ListGroup className="prompt-list rounded">
           {promptData.length > 0 && promptData.map((part) => 
            part.length > 0 && part.map((item) => 
             <ListGroup.Item
               key={item}
               active={selectedItem === item}
               onClick={() => handleSelectItem(item)}
             >
               {item.act}
             </ListGroup.Item>
           ))}
         </ListGroup>
          }
          
          </Col>
          <Col md={6} className="mt-3 mt-md-0" >
            {isCustom&&<FormControl onChange={(e) => setSelectedItem({act:e.target.value,prompt:selectedItem.prompt})} className="mb-2" placeholder="Enter Prompt Title" style={{borderRadius:"15px",background:darkTheme?"#1d1d1d":"white",border:darkTheme?"none":"",color:darkTheme?"white":"black"}}/>}
            <textarea
              className={darkTheme?"prompt-textarea-dark w-100 rounded px-3 py-2":"prompt-textarea w-100 rounded px-3 py-2"}
              placeholder="Enter Prompt"
              value={selectedItem.prompt}
              onChange={(e) => setSelectedItem({act:selectedItem.act,prompt:e.target.value})}
             
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="justify-content-between" style={{backgroundColor:darkTheme?"#2b2d2f ":"white",color:darkTheme?"white":"black"}}>
        <Button className="mygpt-buttons" onClick={()=>setIsCustom(true)}>
           <CustomPromptIcon className="mygpt-icon "/> Create Custom
        </Button>
        <div className="prompt-buttons">
          <Button  onClick={handleCancel}>
            Cancel
          </Button>
          <Button  onClick={handleSelect}>
            Select
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}