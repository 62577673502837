import { ReactComponent as GoogleIcon } from "../assets/google-logo.svg";
import { ReactComponent as GithubIcon } from "../assets/github.svg";
import logoImage from '../assets/embed-logo.png'
import {Image, Row, Col, Button} from 'react-bootstrap'
import { useState,useEffect,useRef } from "react";
import axios from "axios";

function ShopifytoChatbot(){
    document.title = "Welcome to EmbedAI"

    return(
        <div className="heybotnew-bg  p-0 p-lg-3 ">
            
            <div className="heybotnew-box posittion-relative d-flex flex-column heybotnew-theme-light w-100 h-100 p-3 p-lg-5" >
                <div className="d-flex flex-wrap justify-content-between">
                    <div class="d-flex align-items-center gap-2">
                        <Image className="heybotnew-welcome-logo" src={logoImage}/>
                        <h3 className="heybotnew-welcome-title">Shopify to Chatbot</h3>
                    </div>
                </div>
                <div  className="d-flex flex-column align-items-center my-5">
                    <h4 className="py-4">Train a chatbot on Shopify store powered by ChatGPT</h4>
                    <div style={{"max-width": '500px'}} className="heybotnew-primary-btn d-flex align-items-center justify-content-center text-nowrap py-4" onClick={()=>window.location.href="https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=823974908486-c3t68l3rpsmj0r2408poe4947k6glr06.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fembedai.thesamur.ai%2Fheybotnew%2Fgoogle_callback&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile+openid&state=uexJZOIUvvrcgbQKaS4BvkfGhMfUGX"}>
                        <GoogleIcon className="icon me-2 p-1 bg-light rounded-circle" /> Login with
                        Google
                    </div>
                    <hr style={{"max-width": '500px'}} className=" m-2 w-100"/>
                    <div style={{"max-width": '500px'}} className="heybotnew-primary-btn d-flex align-items-center justify-content-center text-nowrap py-4" onClick={()=>window.location.href="https://github.com/login/oauth/authorize?client_id=3244e9eda74486fe30a3&scope=read:user,user:email"}>
                        <GithubIcon className="icon me-2 p-1 bg-light rounded-circle" /> Login with
                        Github
                    </div>
                    <div class='embed-container mt-4'>
                    <iframe src="https://www.youtube.com/embed/v4hg3j3Kens?si=mPzFZK0vZfUtINIE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ShopifytoChatbot
