import { ReactComponent as GoogleIcon } from "../assets/google-logo.svg";
import { ReactComponent as GithubIcon } from "../assets/github.svg";
import logoImage from '../assets/embed-logo.png'
import {Image, Row, Col, Button, Stack} from 'react-bootstrap'
import { useState,useEffect,useRef } from "react";
import axios from "axios";
import { sendAnalyticsEvent } from "../utils/utility";
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'js-cookie';

function EmbedAISignup(){
    document.title = "Welcome to EmbedAI"
    const [captchaValue, setCaptchaValue] = useState("");

    const handleGithubClick = ()=>{
        sendAnalyticsEvent("githublogin_click")
        window.location.href="https://github.com/login/oauth/authorize?client_id=3244e9eda74486fe30a3&scope=read:user,user:email"
    }

    const handleGoogleClick = ()=>{
        sendAnalyticsEvent("googlelogin_click")
        window.location.href="https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=823974908486-c3t68l3rpsmj0r2408poe4947k6glr06.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fembedai.thesamur.ai%2Fheybotnew%2Fgoogle_callback&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile+openid&state=uexJZOIUvvrcgbQKaS4BvkfGhMfUGX"
    }

    function signup() {
        const username = document.querySelector('#username').value;
        const email = document.querySelector('#email').value;        
        const password = document.querySelector('#password').value;

        // Create an object with the signup data
        const signupData = {
            username: username,
            password: password,
            email: email,
            captcha: captchaValue,
        };

        // Send a POST request to the server for signup
        axios.post('/signup', signupData)
            .then((response) => {
                // Handle a successful signup response (e.g., redirect or show a success message)
                console.log('Signup successful', response.data);
                // You can perform actions like redirecting the user to a dashboard here.
            })
            .catch((error) => {
                // Handle signup error (e.g., display an error message)
                toast.error(error.response.data);
                if(error.response.status == 302) {
                    setTimeout(function () {
                        window.location.href = '/app/welcome'; // Replace with your desired URL
                    }, 3000);
                }
                console.error('Signup failed', error);
                // You can display an error message to the user or perform other error handling actions.
            });
    }

    function onCaptchaChange(value) {
        setCaptchaValue(value);
    }

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('botSlug');

        Cookies.remove('botSlug')
        if(myParam!=null){
            Cookies.set('botSlug', myParam);
        }
    }, [])

    return(
        <div style={{ overflowY: 'auto', height: '100vh' }}>
        <div className="heybotnew-bg  p-0 p-lg-3 ">
            
            <div className="heybotnew-box posittion-relative d-flex flex-column heybotnew-theme-light w-100 p-3 p-lg-5" >
                <div className="d-flex justify-content-end">
                    <Stack direction="horizontal" gap={3}>
                        <a href="https://www.thesamur.ai/terms-conditions" target="_blank" rel="noreferrer" className="terms-privacy-link">Terms & Conditions</a>
                        <a href="https://www.thesamur.ai/privacy" target="_blank" className="terms-privacy-link" rel="noreferrer">Privacy Policy</a>
                    </Stack>
                </div>
                <div className="d-flex flex-wrap justify-content-between">
                    <div class="d-flex align-items-center gap-2">
                        <Image className="heybotnew-welcome-logo" src={logoImage}/>
                        <h3 className="heybotnew-welcome-title">EmbedAI</h3>
                    </div>
                </div>
                <div  className="d-flex flex-column align-items-center my-5">
                    <h4 className="py-4">Create your custom chatgpt bot</h4>

                    <div style={{ "maxWidth": '500px' }} className="mb-3">
                        <input type="text" className="form-control" id="username" placeholder="Username" />
                    </div>
                    <div style={{ "maxWidth": '500px' }} className="mb-3">
                        <input type="text" className="form-control" id="email" placeholder="Email" />
                    </div>                    
                    <div style={{ "maxWidth": '500px' }} className="mb-3">
                        <input type="password" className="form-control" id="password" placeholder="Password" />
                    </div>
                    <ReCAPTCHA
                    sitekey="6LeyS3MoAAAAAFznq4VGx39ilNfJbrxNpzIn_TXr"
                    onChange={onCaptchaChange}
                    />
                    <div className="form-check mb-3">
                        <input type="checkbox" className="form-check-input" id="termsCheckbox" />
                        <label className="form-check-label" htmlFor="termsCheckbox">
                            I agree to the <a href="https://www.thesamur.ai/terms-conditions" target="_blank" rel="noreferrer">Terms of Service</a>
                        </label>
                    </div>
                    <div
                        style={{ "maxWidth": '500px' }}
                        className="heybotnew-primary-btn d-flex align-items-center justify-content-center text-nowrap py-4 mb-5"
                        onClick={() => signup()}
                    >
                    Signup
                    </div>
                    <hr style={{"max-width": '500px'}} className=" m-2 w-100"/>
                    <div style={{"max-width": '500px'}} className="heybotnew-primary-btn d-flex align-items-center justify-content-center text-nowrap py-4" onClick={()=>handleGoogleClick()}>
                        <GoogleIcon className="icon me-2 p-1 bg-light rounded-circle" /> Signup with
                        Google
                    </div>
                    <hr style={{"max-width": '500px'}} className=" m-2 w-100"/>
                    <div style={{"max-width": '500px'}} className="heybotnew-primary-btn d-flex align-items-center justify-content-center text-nowrap py-4" onClick={()=>handleGithubClick()}>
                        <GithubIcon className="icon me-2 p-1 bg-light rounded-circle" /> Signup with
                        Github
                    </div>
                    <div className="mt-3">
                        <p>Already have an account? <a href="/app/welcome">Login</a></p>
                    </div>
                </div>
            </div>
        </div>
        <ToastContainer/>
        </div>
    )
}
export default EmbedAISignup
