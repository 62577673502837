import {useState,useEffect,useRef} from 'react'
import {Stack, Button,InputGroup, FormControl,Modal, Image, Dropdown, Spinner,Row, Col, Offcanvas, Form} from 'react-bootstrap'
import ritebotLogo from '../assets/ritebot.png'
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios"
import '../styles/heybot.css'
import {ReactComponent as SendIcon} from '../assets/send.svg'
import {ReactComponent as GoogleIcon} from '../assets/google.svg'
import { useNavigate } from "react-router-dom";
import { ReactComponent as QuestionIcon } from "../assets/question-mark.svg";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { ReactComponent as DiscordIcon } from "../assets/discord.svg";
import logoImage from '../assets/languages.png'

function LanguageGPT() {
    const [language,setLanguage] = useState("en")
    const [showBanner, setShowBanner] = useState(false);
    const navigate = useNavigate()
    document.title = "LangGPT"
    function setCookie(key, value, expiry) {
      var expires = new Date();
      expires.setTime(expires.getTime() + (expiry * 24 * 60 * 60 * 1000));
      document.cookie = key + '=' + value + ';expires=' + expires.toUTCString();
    }
	function gotoMyGPT() {
		if(language!="") {
			setCookie("googtrans", language);
		}
		window.open("https://thesamur.ai?bl="+language, "_blank")
	}
    
    return (
        <div class=" d-flex justify-content-center">
            <div className="bot-container">
                <Stack className="text-center mx-2 mx-md-5 px-md-5 py-4 vh-100 align-items-center" gap={4}>
                    <Stack className="p-3" direction="horizontal">
                        <h3 className="fw-bold">
						<Image className="heybot-logo" src={logoImage}/>
                            LangGPT
                        </h3>
                    </Stack>
                    
					<h5 className="mt-5 pt-5">LangGPT - Use ChatGPT in your language</h5>
					<Form.Group controlId="formBasicSelect">
					<Form.Label>Select Language</Form.Label>
					<Form.Control
					  as="select"
					  value={language}
					  onChange={e => {
						console.log("e.target.value", e.target.value);
						setLanguage(e.target.value);
					  }}
					>
					  <option value="/en">English</option>
					  <option value="/en/it">Italian</option>	
					  <option value="/en/ru">Russian</option>
					  <option value="/en/es">Spanish</option>					  
					  <option value="/en/de">German</option>
					  <option value="/en/fr">French</option>					  
					  <option value="/en/hi">Hindi</option>
					  <option value="/en/pt">Portuguese</option>
					  <option value="/en/zh-CN">Chinese Simplified</option>
					  <option value="/en/zh-TW">Chinese Traditional</option>					  
					  <option value="/en/cs">Czech</option>
					</Form.Control>
				    </Form.Group>
					<Button className="option-buttons align-self-center" onClick={()=>gotoMyGPT()}> Go to LangGPT</Button>
                    
                </Stack>
            </div>
            <ToastContainer />	
            <Modal className="banner-modal" centered show={showBanner} onHide={()=>setShowBanner(false)}>
                <Modal.Body className="p-5 position-relative">
                <b style={{cursor:'pointer'}} onClick={()=>setShowBanner(false)} className="position-absolute top-0 end-0 me-4 mt-2">X</b>
                <Stack className="align-items-center" gap={3}>
                    <a href="https://www.producthunt.com/posts/memejourney?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-memejourney" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=384855&theme=light" alt="MemeJourney - Turn&#0032;text&#0032;into&#0032;memes&#0032;using&#0032;ChatGPT | Product Hunt" style="width: 250px; height: 54px;" width="250" height="54" /></a>
                </Stack>
                </Modal.Body>
            </Modal>
		
        </div>
    )
}

export default LanguageGPT