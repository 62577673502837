import axios from "axios";
import React, { useState } from "react";
import { Button, Container, Form, Stack, Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function GptCrawler() {
  const [inputURL, setInputURL] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  //const CRAWLER_URL = "http://localhost:9000/api/crawler";
  const CRAWLER_URL = "https://gptcrawlervercel.vercel.app/api/crawler"

  const getData = () => {
    setIsLoading(true);

    if (inputURL && inputURL !== "") {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(`${CRAWLER_URL}?url=${inputURL}`, requestOptions)
      .then(response => {
          console.log("Response", response);
          if (!response.ok) {
              if (response.status === 500) {
                  getData()
              } else {
              }
              return "Not ok"
          }
          return response.text();
      })
      .then(result => {
          console.log("Rx", result);
          if(result != "Not ok") { 
              downloadFile(result);
              setInputURL("");
              setIsLoading(false);
          }
      })
      .catch(error => {
          console.log("error", error);
          setInputURL("");
          setIsLoading(false);
      });

    } else {
      setIsLoading(false);
      toast.error("Invalid URL. Please enter a valid URL");
    }
  };

  const downloadFile = (result) => {
    const outputFilename = `${Date.now()}.json`;
    // If you want to download file automatically using link attribute.
    const url = URL.createObjectURL(new Blob([result]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", outputFilename);
    link.click();
  };

  return (
    <>
      <Container>
        <div className="d-flex flex-column justify-content-center align-items-center mt-5 pt-5">
          <h1>GptCrawler</h1>
          <div className=" mt-5 d-flex">
            <Form.Control
              placeholder="Enter your website here"
              
              onChange={(e) => setInputURL(e.target.value)}
            />
            <Button
              className="ms-3 "
              onClick={() => getData()}
              disabled={isLoading}
            >
              {" "}
              Submit
            </Button>
          </div>
          {isLoading ? (
            <div className="justify-content-center mt-5">
              <Stack
                className="justify-content-center p-2 loading"
                direction="horizontal"
                gap={2}
              >
                <Spinner size="sm" animation="grow" />
                <Spinner size="sm" animation="grow" />
                <Spinner size="sm" animation="grow" />
              </Stack>
              Please wait while file is being prepared for the download
            </div>
          ) : (
            ""
          )}
        <div class='embed-container mt-4'>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/sgOXTCvqpow?si=xw-8G4JsoCNZt1vi" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        </div>
        <ToastContainer />
      </Container>
    </>
  );
}
