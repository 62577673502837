import { Button ,Stack,Image} from "react-bootstrap";
import {useEffect,useState} from 'react'
import { useSearchParams, useParams } from "react-router-dom";
import axios from "axios";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import logoImage from '../assets/mygpt-logo.png'
import userImage from '../assets/user.png'
import {ReactComponent as CopyIcon} from '../assets/copy.svg'


function ChatShare() {
    const [showCopy, setShowCopy] = useState(0);
    let [searchParams, setSearchParams] = useSearchParams();
    const [chat,setChat] = useState([])
    const [admin,setAdmin] = useState({name:'',image:''})

    useEffect(() => {
        axios.post("/mygpt/load_chat",{sessId:searchParams.get("session")}).then((res)=>{
           setChat(res.data.msgs)
           setAdmin({name:res.data.adminName,image:res.data.adminImage})
          }).catch((err)=>{
            console.log(err)
        })
       
      }, [])
  return (
    <div class="chat-share-bg vh-100 vw-100 d-flex  align-items-center justify-content-center">
        <div className="chat-share-container d-flex flex-column align-items-center my-3 p-4 p-md-5">
            <small className="fw-bold mb-3">Check out {admin.name}'s conversation</small>
            <Stack gap={2} className="chat-scroll share-container">
                {chat.length>0 && chat.map((msg)=>
                <>                    <div onMouseOver={()=>setShowCopy(msg.id)} onClick={()=>setShowCopy(msg.id)} className={msg.isBot?"d-flex gpt-msg p-3 rounded text-start w-100 position-relative":"d-flex p-3 rounded text-start w-100 position-relative"}>
                        <Image className="profile-img rounded-circle me-3" src={msg.isBot?logoImage:admin.image}/>
                        <div className="w-100">
                            {(msg.steps&&msg.steps.length>0)&&msg.steps.map((step)=>
                                <div className="py-2 px-3 mb-3   steps-bg " gap={2}>
                                    <small><b>Tool Used:</b> {step}</small>
                                </div>)}
                            <div>
                                {msg.isBot?<ReactMarkdown children={msg.msg} remarkPlugins={[remarkGfm]} />:msg.msg}
                            </div>
                        </div>
                       
                        
                    </div>
                    <div className="d-flex justify-content-end align-item-center">
                            {showCopy == msg.id && (
                              <div className="share-btn justify-content-center">
                                <CopyIcon
                                  onClick={()=>navigator.clipboard.writeText(msg.msg)}
                                  className=" small-icon me-1"
                                />
                                Copy
                              </div>
                            )}
                          </div>
                          </>

                )}
                {/* {loading&&<Stack className="ms-3 loading" direction="horizontal" gap={2}>
                    <Spinner size="sm" animation="grow" />
                    <Spinner size="sm" animation="grow" />
                    <Spinner size="sm" animation="grow" />
                </Stack>}
                <div className="end-chat"></div> */}
                
            </Stack>
            <Button  href={'/mygpt?addChat='+searchParams.get("session")} className="mygpt-buttons mt-4">Continue this conversation</Button>
        </div>
    </div>

  )
}

export default ChatShare